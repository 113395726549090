import React, { useEffect } from "react";
import "../style/BuyersBoxStyle.scss";
import mixpanel from "mixpanel-browser";
import { withRouter } from "react-router-dom";
import BuyerBoxComponent from "./BuyerBoxComponent";

const BuyersBoxComponent = (props) => {
  const { buyers } = props;

  useEffect(() => {
    mixpanel.identify(
      `${JSON.parse(window.localStorage.getItem("userData")).id}`
    );
    mixpanel.track("Buyer List");
  }, []);

  return (
    <div className="boxBuyers">
      {buyers.map((item, index) => {
        return <BuyerBoxComponent buyer={item} />;
      })}
    </div>
  );
};

export default withRouter(BuyersBoxComponent);
