import React from "react";
import "../style/JumboTitleStyle.scss";

const JumboTitleComponent = (props) => {
  const { title, children, className } = props;

  return (
    <div className={`jumboTitle ${className}`}>
      <span className="title">{title}</span>
      {children}
    </div>
  );
};

export default JumboTitleComponent;
