import React, {Component} from 'react';
import PropTypes from "prop-types";


class NavBrandsComponent extends Component {
    constructor(props) {
        super(props);
        this.checkeds = []
    }

    getChecked(ev) {
        const filters = this.props.getFilters();
        this.checkeds = (typeof filters.sellers != 'undefined') ? filters.sellers : [];
        const index = this.checkeds.indexOf(ev.target.id);
        if(ev.target.checked && index === -1) {
            this.checkeds.push(ev.target.id);
        }
        else if(!ev.target.checked && index >= 0) {
            this.checkeds.splice(index, 1);
        }

        this.props.setFilters("sellers", this.checkeds);
        this.props.setCurrentPageOne();
    }

    render() {

        const {brands} = this.props;

        const filters = this.props.getFilters();

        return (
            <div className="contentFilter checkSeller">
                <p>BRANDS</p>

                <ul>

                    {brands.map((item, index) => {
                        const checked = (filters.sellers && filters.sellers.includes(item.slug));

                        return (
                            <li key={index}>
                                <div className="checkbox-group">
                                    <label className="filter-checkbox" htmlFor={item.slug}> {item.name}
                                      <input checked={checked ? checked : false}
                                           type="checkbox" id={item.slug}
                                           onChange={(ev) => this.getChecked(ev)} />
                                        <span className="checkmark" />
                                    </label>
                                </div>
                            </li>
                        )
                    })}

                </ul>
            </div>
        );
    }
}
NavBrandsComponent.propTypes = {
    getFilters: PropTypes.func.isRequired
}

export default NavBrandsComponent;
