import { save } from "./OrderActions";
import store from "../../store"
import OrderEntity from "../../../domain/pages/collection/CollectionEntity";
import axios from "axios";

export default class OrderService {

    headers = { Authorization: `Bearer ${window.localStorage.getItem("access_token")}` };
    HEADERS = { headers: this.headers };

    dispatch = store.dispatch;

    // return axios.post(`api/cart/${id}/finalize/`, { message });

    async addCart(id, message) {
        try {
            const res = await
            axios.post(`api/cart/${id}/finalize/`, { message })
            this.getData();
            return res;
        } catch (e) {
            return e.response.status;
        }
    }

    async getData(id) {

        let obj = [];
        try {
            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/order/`, this.HEADERS);
            let response = await result.json();
            obj.push(response);
            this.entityMount(obj)
            if(response.detail === "Authentication credentials were not provided.") {
                localStorage.clear();
            }

        } catch (err) {
            localStorage.clear();
            window.console.log(`[Erro] ${err}`);
            return Promise.reject(400);
        }
    }

    entityMount(response) {
        let entityOrder = [];

        response.forEach( item => {
            entityOrder.push(new OrderEntity(item));
        });

        this.dispatchSave(entityOrder)

    }

    dispatchSave(entityList) {
       this.dispatch(save(entityList));
    }
}
