import React, {Component} from 'react';
import "./style/MenuBrandsStyle.scss"
import {NavLink} from "react-router-dom";

class MenuBrandsComponent extends Component {

    
    render() {
        return (
            <div className="menuBrands">
                <ul>
                    <li className="brand" >
                        <NavLink
                              to={`/${this.props.brandSlug}`}
                              exact={true}
                              activeClassName="active"
                            > ABOUT THE BRAND
                        </NavLink>
                    </li>
                    <li className="collection">
                        <NavLink
                              to={`/${this.props.brandSlug}/collections`}
                              activeClassName="active"
                            >
                            {window.localStorage.getItem("user_type") === "buyer" ? (
                                <>
                                    MAKE A BUY
                                </>
                            ):(
                                <>
                                    COLLECTIONS
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li className="history">
                        <NavLink
                              to={`/${this.props.brandSlug}/history`}
                              activeClassName="active"
                            > BRAND HISTORY
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    }
};

export default MenuBrandsComponent;
