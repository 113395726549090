import React, {Component} from 'react';
import "../style/Grid1Style.scss"
import ShowcaseComponent from "../../../showcase/components/ShowcaseComponent";
import TitleShadowComponent from "../../../titleShadow/components/TitleShadowComponent";
import LightboxHalfScreenComponent from "../../../lightboxHalfScreen/components/LightboxHalfScreenComponent";
import ButtonComponent from "../../../button/components/ButtonComponent";
import { isMobile } from 'is-mobile';


class Grid1Component extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEnable: false,
            showSeeAll: false,
        }
    }

    toggleReadMore(isEnable) {
        this.setState({
            isEnable: !isEnable
        })
    }

    render() {

        const {info, title, images, listInfo, fullText, linkTo, label} = this.props;

        return (
            <div className="grid1">
                <div className="rightGrid">
                    {this.state.showSeeAll &&
                    <LightboxHalfScreenComponent
                    close={() => this.setState({showSeeAll: false})}>
              
                      <h1>INTERNATIONAL RETAILERS</h1>
                      <div style={{marginTop: '50px'}}>
                        <p><strong>{listInfo[2].text}</strong></p>
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{__html:listInfo[3].text}}></div>
                      </div>
                    </LightboxHalfScreenComponent>
                    }
                    {info &&
                    <div className="infoComponent">
                        <div className="containerInfoStatic">
                            <div className="blockInfo brand">
                                <h4 className="title"> BRAND FROM</h4>
                                <p className="text">{listInfo[0].text}</p>
                            </div>

                            <div className="blockInfo year">
                                <h4 className="title"> YEAR ESTABLISHED </h4>
                                <p className="text">{listInfo[1].text}</p>
                            </div>
                            {!isMobile() &&
                                <div className="blockInfo multibrand">
                                    <h4 className="title"> INTERNATIONAL RETAILERS</h4>
                                    <p className="text">{listInfo[2].text}</p>
                                    <div className="contentLinkButton" style={{width: 'auto'}}>
                                        <div className="containerLink" style={{margin: '10px 0'}}>
                                            <div className="linkShowCase" onClick={() => this.setState({showSeeAll: true})}>
                                                {typeof listInfo[3].text !== 'undefined' && listInfo[3].text !== null && listInfo[3].text !== '' &&
                                                    <span>See all {`>>`} </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        {isMobile() &&
                            <div className="blockInfo multibrand">
                                <h4 className="title"> INTERNATIONAL RETAILERS</h4>
                                <p className="text">{listInfo[2].text}</p>
                                <div className="contentLinkButton" style={{width: 'auto'}}>
                                    <div className="containerLink" style={{margin: '10px 0'}}>
                                        <div className="linkShowCase" onClick={() => this.setState({showSeeAll: true})}>
                                            {typeof listInfo[3].text !== 'undefined' && listInfo[3].text !== null && listInfo[3].text !== '' &&
                                                <span>See all {`>>`} </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    }
                </div>

                <div className="leftGrid">
                    { images !== undefined ? (
                        <ShowcaseComponent images={images} />
                    ) : (
                        <div className="image-placeholder">
                            <p>
                                Insert your "image" here
                            </p>
                        </div>
                    )}

                </div>

                <div className="rightGrid">
                    {!info &&
                    <div className="top29">&nbsp;</div>
                    }
                    <TitleShadowComponent 
                        position="left" 
                        type="h2" 
                        disableMargin={images && images.filter(item => item.video_url).length > 0}>
                        {title || "Insert your “title” here"}
                    </TitleShadowComponent>

                    <div className="containerButtonText">
                        <p className="textGrid1">
                            {/* {this.state.isEnable ? (
                                <span>{fullText || "Insert your “subtitle” here"}</span>
                            ) : (
                                <span>{text || "Insert your “text” here"}</span>
                            )} */}
                            {fullText}
                        </p>

                        <div className="contentLinkButton">
                            {/* <div className="containerLink">
                                <div className="linkShowCase" onClick={() => this.toggleReadMore(this.state.isEnable)}>

                                    {this.state.isEnable ?(
                                      "<<"
                                    ): (
                                        <span>Read more {`>>`} </span>
                                    )}
                                </div>
                            </div> */}

                            {linkTo && window.localStorage.getItem("user_type") === "buyer" &&
                                <ButtonComponent type="link" action={linkTo}>
                                    {label}
                                </ButtonComponent>
                              }

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Grid1Component;
