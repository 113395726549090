import React, {Component} from 'react';
import "../style/AddToCartStyle.scss"

class AddToCartComponents extends Component {

    render() {

        const {children} = this.props;

        return (
            <div className="addToCart">
                {children}
            </div>
        );
    }
}

export default AddToCartComponents;