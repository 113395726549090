import React from 'react';
import "../grid3/style/Grid3Style.scss"
import InfoComponent from "../../info/components/InfoComponent";
import SocialMediaComponent from "../../socialmedia/components/SocialMediaComponent";

const Grid3Component = function Grid3Component(props) {
    const {details} = props;

    return (
        <div id="grid3Component">
          {props.image  &&
            <div className="left">
                <figure>
                    <img src={props.image} alt={"History"}/>
                </figure>
            </div>
            }
            <div className="right">
                <InfoComponent info={details} />
                <SocialMediaComponent
                    instagram={props.instagram}
                    facebook={props.facebook}
                    site={props.site}
                />
            </div>
        </div>
    );
};

export default Grid3Component;
