import { save } from "./brandsActions";
import store from "../../store"
import BrandsEntit from "../../../domain/pages/brands/BrandsEntity";
import axios from "axios";
export default class BrandsService {

    headers = { Authorization: `Bearer ${window.localStorage.getItem("access_token")}` };

    dispatch = store.dispatch;

    async getData() {
        try {
            let result = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/sellers`);
            let response = result.data;
            this.entityMount(response);
            if(response.detail === "Authentication credentials were not provided.") {
                localStorage.clear();
            }
        } catch (err) {
            window.console.log(`[Erro] ${err}`);
            return Promise.reject(400);
        }
    }

    entityMount(response) {
        let entityBrands = [];

        response.forEach( item => {
            entityBrands.push(new BrandsEntit(item));
        });

        this.dispatchSave(entityBrands)

    }

    dispatchSave(entityList) {
        this.dispatch(save(entityList));
    }
}
