import React, {Component} from 'react';
import {Link} from "react-router-dom";

class NavBrandsComponentB extends Component {
    constructor(props) {
        super(props);
        this.checkeds = []
    }

    getChecked(ev) {
        // const filters = this.props.getFilters();
        // this.checkeds = (typeof filters.sellers != 'undefined') ? filters.sellers : [];
        // const index = this.checkeds.indexOf(ev.target.id);
        // if(ev.target.checked && index === -1) {
        //     this.checkeds.push(ev.target.id);
        // }
        // else if(!ev.target.checked && index >= 0) {
        //     this.checkeds.splice(index, 1);
        // }

        // this.props.setFilters("sellers", this.checkeds);
        // this.props.setCurrentPageOne();
    }

    render() {

        const {brands} = this.props;

        return (
            <div className="contentFilter checkSeller brandsList">
                <p>BRANDS</p>

                <ul>

                    {brands.map((item, index) => {

                        return (
                            <li key={index}>
                                <div className="checkbox-group">
                                    <Link className="brand-link" to={`/${item.slug}`} key={index}> 
                                        {item.name}
                                    </Link>
                                </div>
                            </li>
                        )
                    })}

                </ul>
            </div>
        );
    }
}

export default NavBrandsComponentB;
