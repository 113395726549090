import { Component } from "react";
import axios from "axios";

class BaseComponent extends Component {
  endPoint = "";
  filters = {};

  _getFilters() {
    let filters = "";

    for (let filter in this.filters) {
      filters += filter + "=" + this.filters[filter] + "&";
    }

    if (this.endPoint.match(/\?/)) {
      filters = "&" + filters;
    } else {
      filters = "/?" + filters;
    }

    return filters;
  }

  _make_url(newEndPoint) {
    if (newEndPoint) {
      this.endPoint = newEndPoint;
    }

    let filters = this._getFilters();

    return this.url + this.endPoint + filters;
  }

  getProducts(newEndPoint) {
    const defaultCallBackResponse = (response) => {
      this.setState({
        products: response.data,
        loading: false,
        loadingProduct: false,
      });
    };

    this.setState({
      loadingProduct: true,
    });

    const url = this._make_url(newEndPoint);

    axios.get(url, this.headers).then(defaultCallBackResponse);
  }

  getFilters() {
    return this.filters;
  }

  clearArrayFilters() {
    this.filters = {};
  }

  clearAllFilter() {
    let inputs = document.querySelectorAll("input");
    let select = document.querySelector(".selectFilter");

    if (select) {
      select.value = "";
    }

    this.clearArrayFilters();

    inputs.forEach((el) => {
      el.checked = false;
    });

    this.setState({
      currentPage: 1,
    });
  }

  setFilters(name, value) {
    this.filters[name] = value;
    this.getProducts();
  }
}

export default BaseComponent;
