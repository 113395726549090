import React, {Component} from 'react';
import '../style/LightboxHalfScreen.scss';

export default class LightboxHalfScreenComponent extends Component {
  render(){
    const {children, close, extraClass} = this.props;

    return (
      <div className={ "lightbox-half-screen " + ( extraClass ? extraClass : "") } >
        <div className="lh-modal-wrapper">
        <div className="lh-modal-mask" onClick={close}></div>
          <div className="lh-modal-container">
            <div className="close" onClick={close}>X</div>
            <div className="lh-modal-body">
                {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
};
