import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../style/ButtonStyle.scss";

class ButtonComponent extends Component {
  goHref(url) {
    window.open(`${url}`, "_blank");
  }

  render() {
    const { children, className, action, type, linkTo } = this.props;
    if (type === "link") {
      return (
        <button
          onClick={() => this.goHref(action)}
          className={`button ${className || ""}`}
        >
          {" "}
          {children}
        </button>
      );
    } else if (type === "navLink") {
      return (
        <Link className={`button ${className || ""}`} to={linkTo}>
          {" "}
          {children}
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => action()}
          className={`button ${className || ""}`}
          disabled={this.props.disabled}
        >
          {" "}
          {children}
        </button>
      );
    }
  }
}

export default ButtonComponent;
