import React, { Component } from "react";
import BuyersService from "../../../data/pages/buyers/buyersService";
import { connect } from "react-redux";
import BuyersBoxComponent from "../../shared/buyersbox/components/BuyersBoxComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import MenuComponent from "../../menu/components/MenuComponent";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import FilterBuyersComponent from "../../filtercollections/components/FilterBuyersComponent";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import FilterAlphabetBuyersComponent from "../../filtercollections/components/FilterAlphabetBuyersComponent";
import JumboTitleComponent from "../../shared/jumbotitle/components/JumboTitleComponent";
import '../styles/BuyersView.scss';

class BrandsViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOpened: false,
      loading: true,
      segmentData: "",
      activePage: 1,
    };
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const currentPage = urlSearchParams.get("page");
    const locations = urlSearchParams.get("locations")
    ? urlSearchParams.get("locations").split(",")
    : [];
    const letter = urlSearchParams.get("letter");
    const buyerService = new BuyersService();

    buyerService.getData(currentPage, locations, letter).then(() => {
      this.setState({
        loading: false,
      });

      if (!isNaN(currentPage)) {
        this.setState({
          activePage: currentPage,
        });
      }
    });

    buyerService.getLocations().then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  getTitle() {
    return "Find Retailers";
  }

  handlePageChange(pageNumber) {
    if (isNaN(pageNumber)) {
      pageNumber = 1;
    }

    this.setState({
      loading: true,
    });

    const historySearch = this.props.history.location.search;
    const urlSearchParams = new URLSearchParams(historySearch);
    const locations = urlSearchParams.get("locations")
    ? urlSearchParams.get("locations").split(",")
    : [];
    const letter = urlSearchParams.get("letter");

    const buyerService = new BuyersService();

    buyerService.getData(pageNumber, locations, letter).then(() => {
      urlSearchParams.set("page", pageNumber);

      this.props.history.replace({
        pathname: this.state.pathname,
        search: `?${urlSearchParams.toString()}`,
      });

      this.setState({
        loading: false,
      });

      this.setState({
        activePage: Number(pageNumber),
      });
    });
  }

  getBuyers() {
    return this.props.buyersData.data;
  }

  getLocations() {
    return this.props.locationsData.data;
  }

  render() {
    return (
      <div className="buyersViewComponent">
        <MenuComponent />
        <main>
          <section>
            <JumboTitleComponent title={this.getTitle()} />
          </section>
          <section className="centerComponent">
            {<FilterAlphabetBuyersComponent />}
          </section>
          <section id="mainCollection" className="centerComponent">
            <button
              onClick={() =>
                this.setState({ filterOpened: !this.state.filterOpened })
              }
              className={`btn-show-filter ${
                this.state.filterOpened ? "clicked" : ""
              }`}
            >
              Filters
            </button>
            {this.props.locationsData.data &&
              this.props.locationsData.data.length > 0 &&
              !this.state.loading && (
                <div
                  className={`leftCollection ${
                    this.state.filterOpened ? "opened" : ""
                  }`}
                >
                  <FilterBuyersComponent locations={this.getLocations()} />
                </div>
              )}
            {this.state &&
            this.props.buyersData.data &&
            this.props.buyersData.meta &&
            !this.state.loading ? (
              <BuyersBoxComponent buyers={this.getBuyers()} />
            ) : (
              <LoadingComponent />
            )}
            {this.props.buyersData &&
              this.props.buyersData.data &&
              this.props.buyersData.meta && (
                <div className="contentPagination">
                  <Pagination
                    hideDisabled
                    activePage={this.state.activePage}
                    itemsCountPerPage="27"
                    totalItemsCount={this.props.buyersData.meta.total}
                    onChange={this.handlePageChange.bind(this)}
                    hideNavigation
                  />
                </div>
              )}
          </section>
        </main>
        <FooterComponent />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    buyersData: state.buyersEntity,
    locationsData: state.locationsEntity,
  };
}

BrandsViewComponent = connect(mapStateToProps)(BrandsViewComponent);

export default withTranslation()(BrandsViewComponent);
