import React, { Component } from "react";

import "../styles/ForgotPasswordComponent.scss";
import axios from "axios";

export default class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      userNotFound: false,
      forgotPasswordSuccess: false,
    };
    this.sendRecoveryPasswordEmail = this.sendRecoveryPasswordEmail.bind(this);
  }

  onChangeEmail(ev) {
    this.setState({
      email: ev.target.value,
    });
  }

  sendRecoveryPasswordEmail() {
    this.setState({ userNotFound: false });
    if (this.state.email && this.state.email !== "") {
      axios
        .post("api/recovery/", { email: this.state.email })
        .then((response) => {
          this.setState({
            forgotPasswordSuccess: true,
          });
        })
        .catch((error) => {
          this.setState({ userNotFound: true });
        });
    }
  }

  render() {
    return (
      <div id="forgot-password-modal">
        <h2>FORGOT YOUR PASSWORD?</h2>
        {!this.state.forgotPasswordSuccess && (
          <p>
            Please inform your email so we can send you the instructions to
            create a new password
          </p>
        )}
        {this.state.userNotFound && (
          <span className="error">Email not found</span>
        )}
        {!this.state.forgotPasswordSuccess && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.sendRecoveryPasswordEmail();
            }}
          >
            <div className="form-item">
              <input
                placeholder="Email"
                type="text"
                id="email"
                value={this.state.email}
                onChange={(ev) => this.onChangeEmail(ev)}
                required
              />
            </div>

            <div className="form-item submit">
              <button type="submit" className="send-button">
                Submit
              </button>
            </div>
          </form>
        )}
        {this.state.forgotPasswordSuccess && (
          <div className="success-text">
            <p>Success! Check your email for more information.</p>
            <button
              type="submit"
              className="send-button"
              onClick={() => this.props.closeForgotPasswordModal()}
            >
              GO BACK
            </button>
          </div>
        )}
      </div>
    );
  }
}
