import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import "../style/infoProductStyle.scss";
import MenuComponent from "../../../menu/components/MenuComponent";
import { Link } from "react-router-dom";
import ThumbComponent from "../thumb/thumbComponent";
import AddToCartComponents from "../../addtocart/components/AddToCartComponents";
import VariationComponent from "../variation/component/VariationComponent";
import SizesComponent from "../sizes/components/SizesComponent";
import DetailsComponent from "../details/DetailsComponent";
import LightBoxFullScreenComponent from "../../lightboxFullScreen/components/LightBoxFullScreenComponent";
import ProductSizeChart from "../../productSizeChart/ProductSizeChart";
import PricesProductComponent from "../prices/components/PricesProductComponent";
import ReactImageMagnify from "react-image-magnify";

import SliderComponent from "../../Slider/components/SliderComponent";
import connect from "react-redux/es/connect/connect";
import CartInterector from "../../../../domain/pages/cart/CartInterector";
import CartService from "../../../../data/pages/cart/CartService";
import InfoProductInterector from "../../../../domain/pages/infoproduct/infoProductInterector";
import InfoProductService from "../../../../data/pages/inforproduct/InfoProductService";
import LoadingComponent from "../../loading/components/LoadingComponent";
import FeedBackMsgComponents from "../../feedbackmsg/components/FeedBackMsgComponents";
import FooterComponent from "../../footer/components/FooterComponent";
import { GetVariationColorStyle } from "../../../../utils/productHelper.js";
import TermsAndAboutRatingComponent from "../../../shared/termsandaboutrating/components/TermsAndAboutRatingComponent";
import mixpanel from "mixpanel-browser";
import { save } from "../../../../data/pages/products/showNotificationItemAddedActions";
import store from "../../../../data/store";
import { isMobile } from "is-mobile";
import Breadcomb from "../../../menu/components/Breadcomb";

const ContainerTextTermsAndContintion = () => {
  return (
    <div className="containerText">
      Before we get started, these are the general trading conditions accepted
      by the brand:
      <p>
        <strong>Terms of Payment</strong>
      </p>
      <p>
        - For orders under USD3,000.00 (three thousand Dollars):{" "}
        <strong>100% Payment in Advance</strong> by the fifth (5th) day after
        order confirmation; and
      </p>
      <p>
        - For orders over USD3,000.00 (three thousand Dollars):{" "}
        <strong>30% Payment in advance</strong> by the fifth (5 th) day after{" "}
        <strong>
          order confirmation and 70% when products are ready for shipment.
        </strong>
      </p>
      <p>
        The production and packing of products will only begin after the payment
        confirmation either of:{" "}
        <strong>
          the full amount (in the 1st Term); or the first payment (in the 2nd
          Term).
        </strong>
      </p>
      <p>
        In the case of <strong>split payments</strong>, products will be shipped
        only after the confirmation of the <strong>final payment.</strong>
      </p>
      <p>
        After placing an order, you will receive the full{" "}
        <strong>
          <i>Retailer Agreement</i>
        </strong>{" "}
        that notes the rules applied to all transactions processed through
        BLANC's website, including your respective order.
      </p>
      If you have any questions or further requests, please contact us at
      <span className="email"> info@blancfashion.com.</span>
    </div>
  );
};

class InfoProductComponent extends Component {
  API_TRACKS = `${process.env.REACT_APP_API_URL}/api/product/${this.props.match.params.id}`;

  headers = { Authorization: `Bearer ${window.localStorage.getItem("token")}` };
  HEADERS = { headers: this.headers };

  constructor(props) {
    super(props);
    this.state = {
      infoProduct: [],
      currentVariation: 0,
      currentItemVariation: 0,
      selectedProductName: "",
      selectedProductSellerId: "",
      showFeedback: false,
      showLightBox: false,
      showLightBoxSize: false,
      loading: true,
      msg: false,
      isProductInCart: false,
    };
    this.timer = null;
    this.closeFeedback = this.closeFeedback.bind(this);
    this.closeLightBox = this.closeLightBox.bind(this);
  }

  addToCart(id, selectedProductName, selectedProductSellerId) {
    new CartInterector(
      new CartService()
        .addCart(id)
        .then((res) => {
          if (res === 409) {
            this.setState({
              isProductInCart: true,
            });
            this.showFeedback();
          } else {
            this.setState({
              msg: true,
              selectedProductName: selectedProductName,
              selectedProductSellerId: selectedProductSellerId,
            });
            this.showFeedback();
          }
        })
        .catch((reason) => {
          console.log(reason, 123);
        })
    );
  }

  getProduct(id) {
    new InfoProductInterector(
      new InfoProductService().getData(id).then((res) => {
        if (this.props.infoProductData[0]) {
          mixpanel.identify(
            `${JSON.parse(window.localStorage.getItem("userData")).id}`
          );
          mixpanel.track("Product Page", {
            seller: this.props.infoProductData[0].collection.seller.name,
            seller_id: this.props.infoProductData[0].collection.seller.id,
            collection: this.props.infoProductData[0].collection.name,
            collection_id: this.props.infoProductData[0].collection.id,
            product_name: this.props.infoProductData[0].name,
            product_id: this.props.infoProductData[0].id,
            category_id: this.props.infoProductData[0].categories
              .map((item) => item.id)
              .join(","),
            category: this.props.infoProductData[0].categories
              .map((item) => item.name)
              .join(","),
            tags: this.props.infoProductData[0].tags
              .map((item) => item.name)
              .join(","),
            tags_id: this.props.infoProductData[0].tags
              .map((item) => item.id)
              .join(","),
          });
        }

        this.setState({
          loading: false,
        });
      })
    );
  }

  getInfoProduct(id) {
    this.setState({ loading: true });
    this.getProduct(id);
  }

  static whatIsTheVariationType(res, index) {
    const variationStyle = GetVariationColorStyle(res);

    return (
      <li
        key={index}
        data-tip={res.label}
        data-for={res.label}
        style={variationStyle.li}
      >
        <span
          data-tip={res.label}
          data-for={res.label}
          className="colorListNoTexture"
          style={variationStyle.span}
        ></span>
        <ReactTooltip
          className="variation-tooltip"
          id={res.label}
          place="top"
          type="light"
          effect="solid"
        />
      </li>
    );
  }

  showLightBox() {
    this.setState({
      showLightBox: true,
    });
  }

  closeLightBox() {
    this.setState({
      showLightBox: false,
    });
  }

  showLightBoxSize() {
    this.setState({
      showLightBoxSize: true,
    });
  }

  closeLightBoxSize() {
    this.setState({
      showLightBoxSize: false,
    });
  }

  selectVariation(key) {
    this.setState({
      currentVariation: key,
    });
  }

  selectItemVariation(key) {
    this.setState({
      currentItemVariation: key,
    });
  }

  componentDidMount() {
    this.getProduct(this.props.match.params.id);
  }

  showFeedback() {
    if (isMobile()) {
      this.setState({
        showFeedback: true,
      });
    } else if (this.state.isProductInCart) {
      store.dispatch(save("isProductInCart"));
    } else {
      store.dispatch(save(true));
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }

  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
      msg: false,
      isProductInCart: false,
    });
    store.dispatch(save(false));
  }

  renderYoutubeVideo(videoUrl) {
    return (
      <iframe
        width="560"
        height="315"
        src={videoUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="youtube-container"
      ></iframe>
    );
  }

  renderImagemProduto(produtoVariacao) {
    if (produtoVariacao.video_url) {
      return this.renderYoutubeVideo(produtoVariacao.video_url);
    }

    return (
      <ReactImageMagnify
        {...{
          smallImage: {
            alt: this.props.infoProductData[0].name,
            isFluidWidth: true,
            src: produtoVariacao.picture_600,
          },
          largeImage: {
            src: produtoVariacao.picture,

            width: 1200,
            height: 1422,
          },
          enlargedImageContainerClassName: "enlargedImageContainer",
          enlargedImageClassName: "enlargedImage",
        }}
      />
    );
  }

  render() {
    if (this.props.infoProductData[0] === undefined) {
      return <LoadingComponent />;
    }

    return (
      <div>
        <MenuComponent />
        <Breadcomb
          links={[
            {
              text: "Our Brands",
              link: true,
              to: "/brands",
            },
            {
              text: this.props.infoProductData[0].collection.seller.name,
              link: true,
              to: `/${this.props.infoProductData[0].collection.seller.slug}`,
            },
            {
              text: this.props.infoProductData[0].collection.name,
              link: true,
              to: `/${this.props.infoProductData[0].collection.seller.slug}/collections/${this.props.infoProductData[0].collection.id}`,
            },
            {
              text: this.props.infoProductData[0].name,
              link: false,
            },
          ]}
        />
        {this.state.showFeedback && this.state.msg && (
          <FeedBackMsgComponents close={this.closeFeedback}>
            GREAT! THE ITEM{" "}
            <span className="product-name">
              {" "}
              {this.state.selectedProductName}{" "}
            </span>{" "}
            HAS BEEN ADDED TO YOUR CART. CLICK{" "}
            <span className="here">
              <Link
                to={{
                  pathname: "/cart",
                  state: { sellerId: this.state.selectedProductSellerId },
                }}
              >
                HERE
              </Link>
            </span>{" "}
            TO CHECK IT OUT.
          </FeedBackMsgComponents>
        )}

        {this.state.showFeedback && this.state.isProductInCart && (
          <FeedBackMsgComponents
            msg="THIS PRODUCT IS ALREADY IN YOUR CART."
            close={this.closeFeedback}
          />
        )}

        {this.state.loading ? (
          <LoadingComponent />
        ) : (
          <main>
            <div className="centerInfoProduct">
              <div className="mainInfoProduct">
                <ThumbComponent
                  thumbs={
                    this.props.infoProductData[0].variations[
                      this.state.currentVariation
                    ].images
                  }
                  handleClick={this.selectItemVariation.bind(this)}
                />

                <div className="image">
                  {this.props.infoProductData[0].variations[
                    this.state.currentVariation
                  ].images[this.state.currentItemVariation] !== undefined ? (
                    this.renderImagemProduto(
                      this.props.infoProductData[0].variations[
                        this.state.currentVariation
                      ].images[this.state.currentItemVariation]
                    )
                  ) : (
                    <img
                      className="no-product-image"
                      src="https://s3.amazonaws.com/blancfashion-api-media/product/product/product_placeholder.jpg"
                      alt=""
                    />
                  )}
                </div>

                <div className="infoProduct">
                  <div className="nameAndRef">
                    <h4 className="titleProduct">
                      {" "}
                      {this.props.infoProductData[0].name}{" "}
                    </h4>
                    <p>REF: {this.props.infoProductData[0].reference_code}</p>
                  </div>

                  <div className="description">
                    {this.props.infoProductData[0].description_en}
                  </div>

                  <PricesProductComponent
                    prices={this.props.infoProductData[0]}
                  />
                  {window.localStorage.getItem("user_type") === "buyer" && (
                    <div
                      className="btnAddToCart"
                      onClick={() =>
                        this.addToCart(
                          this.props.infoProductData[0].id,
                          this.props.infoProductData[0].name,
                          this.props.infoProductData[0].collection.seller.id
                        )
                      }
                    >
                      <AddToCartComponents
                        handleClick={this.addToCart.bind(this)}
                        id={this.props.infoProductData[0].id}
                      >
                        ADD TO CART
                      </AddToCartComponents>
                    </div>
                  )}
                  <VariationComponent
                    handleClick={this.selectVariation.bind(this)}
                    colors={this.props.infoProductData[0].variations}
                  />

                  <SizesComponent
                    sizes={this.props.infoProductData[0].sizes}
                    sizechart={this.props.infoProductData[0].sizechart}
                    handleClick={this.showLightBox.bind(this)}
                  />

                  <DetailsComponent
                    details={this.props.infoProductData[0].details}
                  />

                  <div className="btnTransparent">
                    <TermsAndAboutRatingComponent
                      aboutRatingText={
                        this.props.infoProductData[0].collection.seller
                          .about_rating
                      }
                      termsAndConditionsText={
                        this.props.infoProductData[0].collection.seller
                          .terms_conditions
                      }
                    />
                  </div>

                  {this.props.infoProductData[0].sizechart && (
                    <ProductSizeChart
                      addToCart={() =>
                        this.addToCart(
                          this.props.infoProductData[0].id,
                          this.props.infoProductData[0].name,
                          this.props.infoProductData[0].collection.seller.id
                        )
                      }
                      productId={this.props.infoProductData[0].id}
                      sizeChart={this.props.infoProductData[0].sizechart}
                      show={this.state.showLightBox}
                      handleClick={this.closeLightBox.bind(this)}
                      productName={this.props.infoProductData[0].name}
                      productReferenceCode={
                        this.props.infoProductData[0].reference_code
                      }
                    />
                  )}
                  {this.state.showLightBoxSize && (
                    <LightBoxFullScreenComponent
                      show={this.state.showLightBoxSize}
                      extraClass="termsAndConditionsModal"
                      handleClick={this.closeLightBoxSize.bind(this)}
                      title="TERMS AND CONDITIONS"
                    >
                      <ContainerTextTermsAndContintion />
                    </LightBoxFullScreenComponent>
                  )}
                </div>
              </div>

              <div className="containerLineSheet">
                <h2> FROM THIS LINE SHEET </h2>
                <div className="contentSlider">
                  <SliderComponent
                    dots={true}
                    infinite={false}
                    speed="500"
                    slidetoshow={4}
                    slideScroll={4}
                    slidesPerRow={1}
                  >
                    {this.props.infoProductData[0].linesheet.map(
                      (product, index) => {
                        return (
                          <div
                            className="blocksProducts blocksProductsInfo"
                            key={index}
                          >
                            <div
                              className="topBlockProducts"
                              onClick={() => this.getInfoProduct(product.id)}
                            >
                              <Link to={`/product/${product.id}`}>
                                <>
                                  {product.images.length > 0 && (
                                    <div className="first-image-wrapper">
                                      <img
                                        className="first-image"
                                        src={`${product.images[0].picture_thumbnail}`}
                                        alt="Products"
                                      />
                                    </div>
                                  )}
                                  {product.images.length > 1 && (
                                    <div className="hover-image-wrapper">
                                      <img
                                        className="hover-image"
                                        src={`${product.images[1].picture_thumbnail}`}
                                        alt="Products"
                                      />
                                    </div>
                                  )}
                                </>
                              </Link>
                            </div>

                            <div className="mainProduct">
                              <div className="nameBrand">{product.name}</div>

                              <ul className="variations">
                                {product.variations.map((variation, i) => {
                                  return InfoProductComponent.whatIsTheVariationType(
                                    variation,
                                    i
                                  );
                                })}
                              </ul>

                              <div className="prices">
                                <p>
                                  {" "}
                                  W{" | "}
                                  {product.wholesale_price.currency}{" "}
                                  {parseFloat(
                                    product.wholesale_price.amount
                                  ).toFixed(2)}
                                </p>
                                <p>
                                  {" "}
                                  R{" | "}
                                  {product.retailer_price.currency}{" "}
                                  {parseFloat(
                                    product.retailer_price.amount
                                  ).toFixed(2)}
                                </p>
                              </div>
                              {window.localStorage.getItem("user_type") ===
                                "buyer" && (
                                <button
                                  className="buttonProduct add-to-cart-btn"
                                  onClick={() =>
                                    this.addToCart(
                                      product.id,
                                      product.name,
                                      this.props.infoProductData[0].collection
                                        .seller.id
                                    )
                                  }
                                >
                                  ADD TO CART
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </SliderComponent>
                </div>
              </div>
            </div>
          </main>
        )}

        <FooterComponent />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    infoProductData: state.infoProductEntity,
  };
}

export default connect(mapStateToProps)(InfoProductComponent);
