import React, { Component } from 'react';
import "../style/LoadingStyle.scss"

class LoadingComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    render() {

        return (
            <div className="loading">
                <div className="containerLoading">
                    <img src={`${process.env.PUBLIC_URL}/images/loading.png`} className="load" alt="" />
                    <div className="containerLogo">
                        LOADING MORE
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingComponent;