import React, { Component } from "react";

class OrderItem extends Component {
  render() {
    const { item, isActive, selectOrder } = this.props;
    const userTypeSeller = localStorage.getItem("user_stype") === "seller";

    return (
      <div
        onClick={() => selectOrder(item.id)}
        className={`brand ${isActive ? "active" : ""}`}
      >
        {item.status === "b" && (
          <span className="order-status status-gray">Pending Analysis</span>
        )}
        {item.status === "s" && (
          <span className="order-status status-green">Waiting Brand</span>
        )}
        {item.status === "r" && (
          <span className="order-status status-red">Retailer Pending</span>
        )}
        {item.status === "p" && (
          <span className="order-status status-green">Processing</span>
        )}
        {item.status === "i" && (
          <span className="order-status status-green">In transit</span>
        )}
        {item.status === "f" && (
          <span className="order-status status-gray">Finished</span>
        )}

        <span className={`brand-name ${userTypeSeller ? 'brand-name--buyer' : ''}`}>
          {userTypeSeller ? item.buyer.name : item.seller.name}
        </span>

        <div className="brand-order-info">
          <span className="brand-item-statistic">
            <strong>{item.total_items}</strong> unit
            {item.total_items > 1 ? "s" : ""} from{" "}
            <strong>{item.total_products}</strong> product
            {item.total_products > 1 ? "s" : ""}
          </span>
          <span className="brand-item-date">
            from {item.created.split("T")[0]}
          </span>
          <div className="idOrder">Order #{item.id}</div>
        </div>
      </div>
    );
  }
}

export default OrderItem;
