import React, { Component } from "react";
import MenuComponent from "../../../../presentation/menu/components/MenuComponent";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import axios from "axios";
import JumboTitleComponent from "../../../shared/jumbotitle/components/JumboTitleComponent";
import "../../styles/ConnectionsPage/ConnectionsPage.scss";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import ConnectionButtons from "./ConnectionButtons";
import ConnectionBox from "./ConnectionBox";
import ConnectionFilter from "./ConnectionFilter";

class ConnectionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connections: [],
      pending: 1,
    };
  }

  componentDidMount() {
    this.fetchConnections();
  }

  fetchConnections() {
    const userSType = localStorage.getItem("user_stype");
    this.setState({
      loading: true,
    });

    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/connections?pending=${this.state.pending}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            connections: response.data.data.filter(
              (connection) =>
                connection.status.status !== "declined" ||
                (connection.status.status !== "pending" &&
                  connection.user_stype === userSType)
            ),
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  renderConnections() {
    const userSType = localStorage.getItem("user_stype");

    return this.state.connections.map((connection) => (
      <div key={connection.id}>
        <ConnectionBox
          connection={connection}
          type={userSType === "seller" ? "buyer" : "seller"}
        />
        <ConnectionButtons
          connection={connection}
          onChangeStatus={() => this.fetchConnections()}
        />
      </div>
    ));
  }

  handleConnectionFilterChange(pending) {
    console.log(pending);
    this.setState(
      {
        pending: pending,
      },
      () => {
        this.fetchConnections();
      }
    );
  }

  render() {
    return (
      <div className="connections-page">
        <MenuComponent />
        <main>
          <section>
            <JumboTitleComponent title="My Connections">
              <ConnectionFilter
                onChange={(pending) =>
                  this.handleConnectionFilterChange(pending)
                }
                pending={this.state.pending}
              />
            </JumboTitleComponent>
          </section>
          <section id="mainCollection" className="centerComponent">
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              this.renderConnections()
            )}
          </section>
        </main>
        <FooterComponent />
      </div>
    );
  }
}

export default ConnectionsPage;
