import React from "react";
import "../style/callToActionStyle.scss";
import ButtonComponent from "../../button/components/ButtonComponent";

const CallToAction = (props) => {
  const { callToAction } = props;

  let backgroundImage = callToAction.image;

  if (window.screen.width < 768) {
    backgroundImage = callToAction.image_mobile;
  }

  const renderCallToAction = () => {
    return (
      <div
        id="callToAction"
        className={
          callToAction.title || callToAction.text
            ? " callToAction--overlay"
            : ""
        }
        style={{ backgroundImage: "url(" + backgroundImage + ")" }}
      >
        <div className="textCallToAction centerComponent">
          <div>
            <h2>{callToAction.title}</h2>
            <p>{callToAction.text}</p>
            {callToAction.button_label && callToAction.link && (
              <div className="button-container">
                <ButtonComponent
                  action={() => {
                    return null;
                  }}
                >
                  {callToAction.button_label}
                </ButtonComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {backgroundImage !== null && (
        <>
          {callToAction.link ? (
            <a
              href={callToAction.link || "#"}
              target={callToAction.link ? "_blank" : "_self"}
            >
              {renderCallToAction()}
            </a>
          ) : (
            renderCallToAction()
          )}
        </>
      )}
    </div>
  );
};

export default CallToAction;
