import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip'
import PropTypes from "prop-types";
import {GetVariationColorStyle} from "../../../../../utils/productHelper.js";

class Variation extends Component {

    makeActive = (ev) => {
        document.getElementsByClassName("activeVariation")[0].classList.remove("activeVariation");
        ev.target.parentNode.classList.add("activeVariation");
        this.props.handleClick(this.props.index);
    }

    componentDidMount() {
        let target = document.querySelectorAll(".listVariation ")[0];
        target.classList.add("activeVariation");
    }

    render() {

        const variationStyle = GetVariationColorStyle(this.props.variation);

        return (
            <li className={variationStyle.classList} onClick={this.makeActive}
                style={variationStyle.li}>
                <span data-tip={this.props.variation.label} data-for={this.props.variation.label} className="colorListNoTexture" style={variationStyle.span}></span>
                <ReactTooltip className="variation-tooltip" id={this.props.variation.label} place="top" type="light" effect="solid"/>
            </li>
        );
    }
}


Variation.propTypes = {
    variation: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired
};

const VariationComponent = props => {

    const {colors, handleClick} = props;

    return (
      <>
        <div className="variationsProduct">
            <p className="titleVariation">COLORS</p>

            <div className="contentVariation">
                <ul>
                    {colors.map((variation, index) => <Variation key={index} variation={variation}
                                                                 handleClick={handleClick}
                                                                 index={index}
                    />)}
                </ul>
            </div>
        </div>

      </>
    );
};

export default VariationComponent;
