import axios from "axios";

export default class CartService {
    addCart(id){
        return axios.post("api/cart/add/", { id });
    }

    getCart(pageNumber = 1){
        return axios.get(`api/cart/?page=${pageNumber}`).then( (res) => {
            this.totalItems(res.data.results.length);
            return res;
        });
    }

    removeProduct(cartId, productId){
        return axios.delete(`api/cart/item/?cart_id=${cartId}&product_id=${productId}`)
    }

    removeAllProducts(cartId){
        return axios.delete(`api/cart/${cartId}/`); 
    }

    addItem(id, quantity){
        return axios.patch(`api/cart/item/${id}/`, { id, quantity })
    }

    finalize(id, message){
        return axios.post(`api/cart/${id}/finalize/`, { message });
    }

    totalItems(total) {
        return total;
    }
}
