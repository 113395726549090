import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pt_BR from './languages/pt-BR.json';
import en_US from './languages/en-US.json';


i18n.use(initReactI18next).init({
  resources: {
    en_US: {
      translation: en_US              
    },
    pt_BR: {
      translation: pt_BR
    }
  },
  lng: "en_US",
  fallbackLng: "en_US",

  interpolation: {
    escapeValue: false
  }
})

export default i18n;