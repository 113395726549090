import React, {Component} from 'react';
import NavbarComponent from '../../landing/shared/navbar/components/NavbarComponent';
import FooterComponent from "../../shared/footer/components/FooterComponent";
import LightBoxComponent from "../../shared/lightbox/components/LightBoxComponent";
import RegisterModalComponent from "../../shared/registermodal/components/RegisterModalComponent";
import LoginComponent from "../../shared/Login/components/LoginComponent";
import axios from "axios";
import "../styles/Recovery.scss";


class LandingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            showRegister: false,
            registerCompanyType: '',
            password: "",
            confirm: "",
            success: false
        };

        this.closeRegister = this.closeRegister.bind(this);
        this.openRegister = this.openRegister.bind(this);
        this.openLogin = this.openLogin.bind(this);
        this.closeLogin = this.closeLogin.bind(this);
        this.sendNewPassword = this.sendNewPassword.bind(this);
    }

    componentDidMount () {
      const { token } = this.props.match.params
      this.setState({token: token})
    }
    onChangePassword(ev) {
        this.setState({
            password: ev.target.value
        })
    }

    onChangeConfirm(ev) {
        this.setState({
            confirm: ev.target.value
        })
    }
    openLogin() {
        this.setState({
            showLogin: true
        });
    }

    closeLogin() {
        this.setState({
            showLogin: false
        });
    }

    openRegister(companyType = '') {
        this.setState({
            registerCompanyType: companyType,
            showRegister: true
        });
    }

    closeRegister() {
        this.setState({
            showRegister: false
        })
    }

    sendNewPassword(){

      if(this.state.password && this.state.confirm){
        if(this.state.password === this.state.confirm){
          axios.post("api/recovery/confirm/", {
            password: this.state.password,
            confirm: this.state.confirm,
            token: this.state.token
          }).then((response)=>{
            this.setState({success: true})
          }).catch( (error) => {
            if (error.response){
              let errors = error.response.data;
              this.setState({errors: errors})
            }
          })
        }else{
          this.setState({errors:{confirm:'The password and confirmation don\'t match'}})
        }

      }
    }

    render() {


        return (
            <div id="landing-page">
                <NavbarComponent
                    showLogin={() => this.openLogin()} />
                {this.state.showRegister &&
                  <RegisterModalComponent companyType={this.state.registerCompanyType} close={this.closeRegister}/>
                }

                {this.state.showLogin &&
                <LightBoxComponent isOpen={this.state.showLogin} handleClick={() => this.closeLogin()}
                                   handleEventClose={() => this.closeLogin()}
                >
                    <LoginComponent
                    closeRegister={this.closeRegister}
                    OpenRegister={this.openRegister.bind(this)}/>
                </LightBoxComponent>
                }
                <div className="recovery">
                  <div className="recovery-form-wrapper">
                    {!this.state.success  ? (
                      <>
                        <h2>CHANGE PASSWORD</h2>
                        <form>
                            <div className="formItem">
                                {this.state.errors && this.state.errors.token &&
                                  <span className="error">{this.state.errors.token} </span>
                                }
                                <input placeholder="Enter a new password" type="password" id="password" value={this.state.password} onChange={(ev) => this.onChangePassword(ev)} required/>
                                {this.state.errors && this.state.errors.password &&
                                  <span className="error">{this.state.errors.password} </span>
                                }
                                <input placeholder="Confirm your new password" type="password" id="confirm" value={this.state.confirm} onChange={(ev) => this.onChangeConfirm(ev)} required/>
                                {this.state.errors && this.state.errors.confirm &&
                                  <span className="error">{this.state.errors.confirm} </span>
                                }
                            </div>

                            <div className="formItem btn-wrapper">
                                <div className="button" onClick={() => this.sendNewPassword()}>Confirm</div>
                            </div>
                        </form>
                      </>
                    ):(
                      <>
                        <h2>Password Changed!</h2>
                        <div className="formItem btn-wrapper">
                          <button className="link login" onClick={() => this.openLogin()}> Login</button>
                        </div>
                      </>
                    )}

                  </div>
                </div>
                <FooterComponent/>
            </div>
        );
    }
}

export default LandingComponent
