import React, { Component } from "react";

import BrandsInterector from "../../../domain/pages/brands/BrandsInterector";
import BrandsService from "../../../data/pages/brands/brandsService";
import CategoriesInterector from "../../../domain/pages/categories/CategoriesInterector";
import CategoriesService from "../../../data/pages/categories/categoriesService";
import { connect } from "react-redux";
import BrandsBoxComponent from "../../shared/brandsbox/components/BrandsBoxComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import MenuComponent from "../../menu/components/MenuComponent";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import queryString from "query-string";
import FilterBrandsComponent from "../../filtercollections/components/FilterBrandsComponent";
import { withTranslation } from "react-i18next";
import axios from "axios";
import JumboTitleComponent from "../../shared/jumbotitle/components/JumboTitleComponent";
import BrandsViewFilter from "./BrandsViewFilter";
import "../styles/BrandsViewComponent.scss";

class BrandsViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOpened: false,
      loading: true,
      segmentData: "",
    };
  }

  removeFiltersBrandPage() {
    window.localStorage.removeItem('brands_last_filter_name');
    window.localStorage.removeItem('brands_last_filter_pathname');
    window.localStorage.removeItem('brands_last_filter_category_name');
    window.localStorage.removeItem('brands_last_filter_category_url');
  }

  componentDidUpdate() {
    if (window.location.pathname === '/brands' || window.location.pathname === '/home') {
      this.removeFiltersBrandPage();
    }
  }

  componentDidMount() {
    new BrandsInterector(new BrandsService().getData());
    new CategoriesInterector(new CategoriesService().getData());
    this.getSegmentData();
  }

  getSegmentData() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/segments${this.props.location.pathname}/`,
        this.headers
      )
      .then((response) => {
        this.setState({
          segmentData: response.data,
          loading: false,
        });
      });
  }

  getBrands() {
    let brands = this.props.brandsData;
    const qs = queryString.parse(this.props.location.search);
    if ("categories" in qs) {
      brands = brands.filter(
        (b) =>
          b.categories.filter((c) =>
            qs.categories.split(",").includes(c.toString())
          ).length > 0
      );
    }

    let segmentData = this.state ? this.state.segmentData : null;

    if (segmentData) {
      brands = brands.filter((b) =>
        b.segments.includes(parseInt(segmentData.id))
      );
    }

    return brands;
  }

  getTitle() {
    let segment = this.state ? this.state.segmentData : null;

    if (segment) {
      return "Brands* " + segment.name;
    } else {
      if (
        this.props.location.pathname !== "/marcas" &&
        this.props.location.pathname !== "/home" &&
        this.props.location.pathname !== "/brands"
      ) {
        return "...";
      } else {
        return this.props.t("Brands* A-Z");
      }
    }
  }

  getCategories() {
    let segmentData = this.state ? this.state.segmentData : null;

    if (segmentData) {
      let brands = this.props.brandsData;
      brands = brands.filter((b) =>
        b.segments.includes(parseInt(segmentData.id))
      );

      let categories = [];

      brands.forEach((element) => {
        categories += element.categories;
      });

      return this.props.categoriesData.filter((category) =>
        categories.includes(parseInt(category.id))
      );
    }

    return this.props.categoriesData;
  }

  render() {
    return (
      <div>
        <MenuComponent />
        <main className="brands-view-component">
          {this.props.brandsData.length > 0 && !this.loading ? (
            <>
              <JumboTitleComponent title={this.getTitle()}>
                <BrandsViewFilter />
              </JumboTitleComponent>
            </>
          ) : (
            <LoadingComponent />
          )}
          <section id="mainCollection" className="centerComponent">
            {this.state && this.state.segmentData && !this.state.loading ? (
              <>
                <button
                  onClick={() =>
                    this.setState({ filterOpened: !this.state.filterOpened })
                  }
                  className={`btn-show-filter ${
                    this.state.filterOpened ? "clicked" : ""
                  }`}
                >
                  Filters
                </button>
                <div
                  className={`leftCollection ${
                    this.state.filterOpened ? "opened" : ""
                  }`}
                >
                  <FilterBrandsComponent
                    categories={this.getCategories()}
                    brands={this.getBrands()}
                  />
                </div>
                <div className="rightCollection">
                  {this.state &&
                  this.props.brandsData.length &&
                  !this.state.loading > 0 ? (
                    <BrandsBoxComponent
                      brands={this.getBrands()}
                      brandsSmall={this.state.segmentData ? true : false}
                    />
                  ) : (
                    <LoadingComponent />
                  )}
                </div>
              </>
            ) : (
              <>
                {this.state &&
                this.props.brandsData.length > 0 &&
                !this.state.loading ? (
                  <BrandsBoxComponent
                    brands={this.getBrands()}
                    brandsSmall={this.state.segmentData ? true : false}
                  />
                ) : (
                  <LoadingComponent />
                )}
              </>
            )}
          </section>
        </main>
        <FooterComponent />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    homeData: state.homeEntity,
    brandsData: state.brandsEntity,
    categoriesData: state.categoriesEntity,
  };
}

BrandsViewComponent = connect(mapStateToProps)(BrandsViewComponent);

export default withTranslation()(BrandsViewComponent);
