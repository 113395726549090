import React, {Component} from 'react';
const Style = require("../style/TitleShadowStyle.scss");

class TitleShadowComponent extends Component {

    typesTag(tag, children) {
        return `<${tag} dangerouslySetInnerHTML={{__html: ${children} }}><span>${children}</span></${tag}>`;
    }

    render() {
        const {children, position, type, disableMargin } = this.props;

        return (
            <div
                className={`${Style.titleShadow} ${position || ''} ${disableMargin ? 'disable-margin' : ''} `}
                dangerouslySetInnerHTML={{__html: this.typesTag(type, children)}}  />
        );
    }
}

export default TitleShadowComponent;