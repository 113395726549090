import React, { Component } from "react";

import "../styles/ChangePasswordComponent.scss";
import axios from "axios";
import { withTranslation } from "react-i18next";
import SpinnerComponent from "../../spinner";

class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      fieldError: "",
      isLoading: ""
    };
  }

  onChangePassword(ev) {
    this.setState({
      password: ev.target.value,
    });
  }

  onChangeConfirmPassword(ev) {
    this.setState({
      confirmPassword: ev.target.value,
    });
  }

  sendChangePassword() {
    if (this.state.isLoading) {
        return;
    }

    if (this.state.password.length <= 0) {
      return this.setState({
        fieldError: this.props.t('Password ir required'),
      });
    }

    if (this.state.confirmPassword.length <= 0) {
      return this.setState({
        fieldError: this.props.t("Password confirm is required"),
      });
    }

    if (this.state.password !== this.state.confirmPassword) {
      return this.setState({
        fieldError: "Password and Password confirm does not match",
      });
    }

    this.setState({
      fieldError: "",
      isLoading: true,
    });

    var self = this;

    axios
      .post(
        "api/change/",
        {
          password: this.state.password,
          confirm: this.state.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.loginData.access_token}`,
          },
        }
      )
      .then((response) => {
        self.props.closeForgotPasswordModal(self.state.password);
      })
      .catch((error) => {
        self.setState({
          fieldError:
            this.props.t("Error when trying to change the password, try again later."),
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div id="forgot-password-modal">
        <h2>{this.props.t("CHANGE YOUR DEFAULT PASSWORD")}</h2>
        <p>
          {this.props.t("Please inform a new password to change the default")}
        </p>
        <form>
          <div className="form-item">
            <input
              placeholder="Password"
              type="password"
              id="password"
              className="input"
              onChange={(ev) => this.onChangePassword(ev)}
              autoFocus
              required
            />
          </div>
          <div className="form-item">
            <input
              placeholder="Password confirm"
              type="password"
              id="password-confirm"
              className="input"
              onChange={(ev) => this.onChangeConfirmPassword(ev)}
              required
            />
          </div>

          <div className="form-item submit">
            <div
              className="send-button"
              onClick={() => this.sendChangePassword()}
            >
              { this.state.isLoading ? <SpinnerComponent /> : this.props.t("Submit")}
            </div>
          </div>
        </form>
        {this.state.fieldError && (
          <p className="fieldError">Error: {this.state.fieldError}</p>
        )}
      </div>
    );
  }
}

export default withTranslation()(ChangePasswordComponent);
