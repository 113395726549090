import React, { Component } from "react";
import axios from "axios";
import "../../styles/ConnectionsPage/ConnectionsButtons.scss";
import ButtonComponent from "../../../shared/button/components/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import SpinnerComponent from "../../../shared/spinner";
import { withRouter } from "react-router";

class ConnectionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  handleClickAccepted() {
    const { connection, history } = this.props;
    const userSType = localStorage.getItem("user_stype");
    history.push(
      `/messages/${
        userSType === "seller" ? connection.buyer.id : connection.seller.id
      }`
    );
  }

  handleAcceptConnection() {
    const { connection } = this.props;

    this.setState({
      loading: true,
    });

    axios
      .put(`${process.env.REACT_APP_NEW_API_URL}/v1/connections/accept`, {
        connection_id: connection.id,
      })
      .then((response) => {
        if (response.data.data) {
          this.setState({
            loading: false,
          });

          if (response.data.data.status.status === "accepted") {
            this.props.onChangeStatus();
          }
        }
      });
  }

  handleDenyConnection() {
    const { connection } = this.props;

    this.setState({
      loading: true,
    });

    axios
      .put(`${process.env.REACT_APP_NEW_API_URL}/v1/connections/decline`, {
        connection_id: connection.id,
      })
      .then((response) => {
        if (response.data.data) {
          this.setState({
            loading: false,
          });

          if (response.data.data.status.status === "declined") {
            this.props.onChangeStatus();
          }
        }
      });
  }

  render() {
    const { connection } = this.props;
    const userSType = localStorage.getItem("user_stype");
    return (
      <div className="connections-buttons">
        {connection.status.status === "accepted" && (
          <div className="accepted">
            <ButtonComponent
              action={() => this.handleClickAccepted()}
              className="accept"
            >
              CONNECTED
            </ButtonComponent>
            <FontAwesomeIcon
              icon={faEnvelope}
              className="envelop"
              onClick={() => this.handleClickAccepted()}
            />
          </div>
        )}
        {this.state.loading && <SpinnerComponent />}
        {!this.state.loading &&
          connection.status.status === "pending" &&
          connection.user_stype !== userSType && (
            <div className="pending">
              <ButtonComponent
                action={() => this.handleAcceptConnection()}
                className="accept"
              >
                ACCEPT
              </ButtonComponent>
              <ButtonComponent
                action={() => this.handleDenyConnection()}
                className="decline"
              >
                DECLINE
              </ButtonComponent>
            </div>
          )}
      </div>
    );
  }
}

export default withRouter(ConnectionButtons);
