import React, { Component } from "react";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import "../styles/OrderFilterComponent.scss";

class OrderFilterComponent extends Component {
  handleClickCurrentOrders() {
    this.props.onFilter("current");
  }

  handleClickPastOrders() {
    this.props.onFilter("past");
  }

  render() {
    return (
      <div id="order-filter-component">
        <div id="buttons">
          <span className="title">Your Orders</span>
          <ButtonComponent action={() => this.handleClickCurrentOrders()}>
            Current Orders
          </ButtonComponent>
          <ButtonComponent action={() => this.handleClickPastOrders()}>
            Past Orders
          </ButtonComponent>
        </div>
      </div>
    );
  }
}

export default OrderFilterComponent;
