import React, { Component } from "react";
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MenuComponent from "../../menu/components/MenuComponent";
import {Link} from "react-router-dom";

import AuthenticationService from "../../../services/AuthenticationService";
import SpinnerComponent from "../../shared/spinner";
import { withTranslation } from 'react-i18next';

import "../styles/RegisterView.scss";

class RegisterViewComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            companyType: "b",
            companyName: "",
            companyWebsite: "",
            yearEstablished: "",
            segments: "",
            location: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            locationFields: [{ location: null }],
            registerSuccess: false,
            emailAlreadyInUse: false,
            isLoading: false
        };
    }

    addLocationField(){
        let state = this.state;
        state.locationFields.push({ location: null });
        
        this.setState({
            locationFields: state.locationFields
        });
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    setCompanyLocation(place, index){
        let state = this.state;
        state.locationFields[index].data = place;

        this.setState({
            locationFields: state.locationFields
        });
    }

    removeLocation(index){
        let state = this.state;
        state.locationFields.splice(index, 1);
        this.setState({
            locationFields: state.locationFields
        });
    }

    async preRegister(event){
        event.preventDefault();
        this.setState({ isLoading: true });

        let state = this.state;
        let formData = {
            type: state.companyType,
            company_name: state.companyName,
            company_website: state.companyWebsite,
            year_established: state.yearEstablished,
            seller_segments: state.segments,
            first_name: state.firstName,
            last_name: state.lastName,
            email: state.email,
            phone: state.phone,
            locations: [],
            glocations: []
        };

        if(state.locationFields.length > 0 && state.companyType === "b"){
            state.locationFields.forEach(locationField => {
                formData.glocations.push(locationField.data.formatted_address);
                locationField.data.address_components.forEach(address => {
                    if(address.types[0] === "country"){
                        formData.locations.push(address.long_name);
                    }
                });
            })
        }

        try {
            let authenticationService = new AuthenticationService();
            await authenticationService.preRegister(formData);
            this.setState({
                registerSuccess: true,
                isLoading: false
            });
        } catch(ex){
            if(ex.response.data.email && ex.response.data.email[0] === "pré-cadastro with this email already exists."){
                this.setState({
                    emailAlreadyInUse: true,
                    isLoading: false
                });
            }
        }
    }

    render() {
        return (
            <div id="register-view">
                <MenuComponent />
                {
                    !this.state.registerSuccess && 
                    <div className="title-and-description">
                        <h2>{this.props.t('HELLO THERE!')}</h2>
                        <div className="description">
                            { this.state.companyType === "b" ? 
                                "WE WANT TO COLLABORATE WITH YOU AND OTHER RETAILERS AROUND THE GLOBE, CONNECTING YOU TO EXCITING LABELS AND TALENTS. CALL US YOUR MATCHMAKER!" : 
                                "WE BELIEVE THE WORLD NEEDS TO SEE MORE OF BRAZIL’S AUTHENTIC TALENTS. WE’RE ALWAYS LOOKING FOR THOSE WHO STAND OUT - AND ARE HERE TO PUT THEM UNDER THE SPOTLIGHT!" }
                        </div>
                    </div>
                }
                <div className="registration-title-box">
                    {
                        this.state.registerSuccess ? 
                            <p> {this.props.t('(THANKS FOR YOUR REGISTRATION')} </p> : 
                            <React.Fragment>
                                <p> {this.props.t("LET'S START YOUR REGISTRATION")} </p>
                                <span className="subtitle">{this.props.t('COMPANY INFO')}</span>
                            </React.Fragment>
                    }
                </div>
                <form onSubmit={this.preRegister.bind(this)}>
                    <div className="container">
                        {
                            !this.state.registerSuccess && 
                            <React.Fragment>
                                <div className="company-info">
                                    <select name="companyType" onChange={this.handleChange.bind(this)}>
                                        <option disabled>{this.props.t('Who are you?')}</option>
                                        <option value="b">{this.props.t('Retailer')}</option>
                                        <option value="s">{this.props.t('Brand')}</option>
                                    </select>
                                    <input name="companyName" required value={this.state.companyName} onChange={this.handleChange.bind(this)} type="text" placeholder="Company Name" />
                                    <input name="companyWebsite" value={this.state.companyWebsite} onChange={this.handleChange.bind(this)} type="text" placeholder="Company Website" />
                                    <input name="yearEstablished" value={this.state.yearEstablished} onChange={this.handleChange.bind(this)} type="text" placeholder="Year Established" />
                                    <input name="segments" value={this.state.segments} onChange={this.handleChange.bind(this)} type="text" placeholder="Segments" />
                                    { this.state.companyType === "b" &&
                                        this.state.locationFields.map((item, index) => { 
                                            return (
                                                <div key={index} className="auto-complete-group">
                                                    <Autocomplete key={index} style={{width: '93.6%'}} onPlaceSelected={(place) => { this.setCompanyLocation(place, index) }} types={['(regions)']}/>    
                                                    { index !== 0 && <FontAwesomeIcon onClick={() => this.removeLocation(index)} icon="times-circle" /> }
                                                </div>
                                            )
                                        })
                                    }
                                    { this.state.companyType === "b" &&
                                        <div onClick={() => this.addLocationField()} className="add-location">
                                            <FontAwesomeIcon icon="plus-circle" />
                                            <span>{this.props.t('add more locations')}</span>
                                        </div>
                                    }
                                </div>
                                <div className="contact-info">
                                    <span className="subtitle">{this.props.t('CONTACT INFO')}</span>
                                    <input name="firstName" value={this.state.firstName} onChange={this.handleChange.bind(this)} type="text" placeholder="First Name" />
                                    <input name="lastName" value={this.state.lastName} onChange={this.handleChange.bind(this)} type="text" placeholder="Last Name" />
                                    <div className="email-box">
                                        <input name="email" value={this.state.email} onChange={this.handleChange.bind(this)} type="text" placeholder="Email" />
                                        {
                                            this.state.emailAlreadyInUse &&                                         
                                            <span>
                                                THIS EMAIL ADDRESS IS ALREADY REGISTERED AT BLANCFASHION.COM.
                                                YOU CAN SIGN IN DIRECTLY WITH YOUR PASSWORD OR SEND AN EMAIL
                                                TO HELLO@BLANCFASHION.COM IN CASE YOU NEED ANY HELP.
                                            </span>
                                        }

                                    </div>
                                    <input name="phone" value={this.state.phone} onChange={this.handleChange.bind(this)} type="text" placeholder="Phone" />
                                </div>
                                <div className="checkbox-group">
                                    <input type="checkbox" name="addEmailToList" />
                                    <label>{this.props.t('RECEIVE NEWS AND UPDATES ABOUT US')}</label>
                                </div>
                                <button className="register-button" type="submit">
                                    { this.state.isLoading ? (<SpinnerComponent></SpinnerComponent>) : "SEND" }
                                </button>
                            </React.Fragment>
                        }
                        {
                            this.state.registerSuccess && 
                                <div className="success-text">
                                    <FontAwesomeIcon size="2x" icon="envelope-open"></FontAwesomeIcon>
                                    <span>{this.props.t('Check your email for more information')}</span>
                                    <Link to="/"><button className="register-button">{this.props.t('GO BACK')}</button></Link>
                                </div>
                        }
                    </div>
                </form>
            </div>
        )
    }
}
export default withTranslation()(RegisterViewComponent);