import React from 'react';
import "../style/readMoreStyle.scss"
import {Link} from "react-router-dom";

const ReadMoreComponent = (props) => {

    const { tags } = props;

    return (
        <div id="contentReadMore">
            <p>Tags: </p>

            <ul>
                {tags.map( (tags, index) => {
                    return (
                        <li key={index}>
                            <Link to={`/products/?tags=${tags.name}`}>
                                #{tags.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default ReadMoreComponent;