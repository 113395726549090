import { save } from "./categoriesActions";
import store from "../../store"
import CategoriesEntity from "../../../domain/pages/categories/CategoriesEntity";
import axios from "axios";


export default class CategoriesService {

    headers = { Authorization: `Bearer ${window.localStorage.getItem("new_access_token")}` };

    dispatch = store.dispatch;

    async getData() {
        const INIT = { headers: this.headers };
        try {
            let result = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/sellers/categories`, INIT);
            let response = result.data;
            this.entityMount(response);
            if(response.detail === "Authentication credentials were not provided.") {
                localStorage.clear();
            }
        } catch (err) {
            window.console.log(`[Erro] ${err}`);
            return Promise.reject(400);
        }
    }

    entityMount(response) {
        let entityCategories = [];

        response.forEach( item => {
            entityCategories.push(new CategoriesEntity(item));
        });

        this.dispatchSave(entityCategories)

    }

    dispatchSave(entityList) {
        this.dispatch(save(entityList));
    }
}
