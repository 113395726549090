import React, { Component, Fragment } from "react";
import "../style/WhoWeAre.scss";
import NavbarComponent from "../../../landing/shared/navbar/components/NavbarComponent";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import LightBoxComponent from "../../../shared/lightbox/components/LightBoxComponent";
import RegisterModalComponent from "../../../shared/registermodal/components/RegisterModalComponent";
import LoginComponent from "../../../shared/Login/components/LoginComponent";
import axios from "axios";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import { configureAnchors } from "react-scrollable-anchor";
import isMobile from "is-mobile";
import WhoWeAreHero from "./WhoWeAreHero";

class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whoWeAre: [],
      showLogin: false,
      showRegister: false,
      loading: true,
    };
    this.closeRegister = this.closeRegister.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.openLogin = this.openLogin.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
  }

  async getWhoWeAre() {
    let result = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/who-we-are`);
    let response = await result.data;

    this.setState({
      whoWeAre: response,
      loading: false,
    });
  }
  openLogin() {
    this.setState({
      showLogin: true,
    });
  }

  closeLogin() {
    this.setState({
      showLogin: false,
    });
  }

  openRegister(companyType = "") {
    this.setState({
      registerCompanyType: companyType,
      showRegister: true,
    });
  }

  closeRegister() {
    this.setState({
      showRegister: false,
    });
  }

  componentDidMount() {
    this.getWhoWeAre();
  }
  componentWillMount() {
    configureAnchors({
      offset: -120,
      scrollDuration: 1000,
    });
  }

  showCard(index) {
    if (isMobile()) {
      const card = document.getElementById(`card-${index}`);

      if (this.hasClass(card, "card-visible")) {
        card.classList.remove("card-visible");
        Array.prototype.forEach.call(
          document.querySelectorAll(".card"),
          function (el) {
            el.classList.remove("card-visible");
          }
        );
      } else {
        Array.prototype.forEach.call(
          document.querySelectorAll(".card"),
          function (el) {
            el.classList.remove("card-visible");
          }
        );
        card.classList.add("card-visible");
      }
    }
  }

  hasClass(element, className) {
    return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
  }

  render() {
    if (this.state.loading) {
      return <LoadingComponent />;
    }

    const banner = this.state.whoWeAre.whoweare.banner
      ? this.state.whoWeAre.whoweare.banner
      : `${process.env.PUBLIC_URL}/images/whoweare/team.png`;

    const ourValues = [
      {
        title: this.state.whoWeAre.whoweare.our_values_title_1,
        text: this.state.whoWeAre.whoweare.our_values_text_1,
      },
      {
        title: this.state.whoWeAre.whoweare.our_values_title_2,
        text: this.state.whoWeAre.whoweare.our_values_text_2,
      },
      {
        title: this.state.whoWeAre.whoweare.our_values_title_3,
        text: this.state.whoWeAre.whoweare.our_values_text_3,
      },
    ].filter((item) => item.text);

    return (
      <Fragment>
        <NavbarComponent showLogin={() => this.openLogin()} />
        {this.state.showRegister && (
          <RegisterModalComponent
            companyType={this.state.registerCompanyType}
            close={this.closeRegister}
          />
        )}

        {this.state.showLogin && (
          <LightBoxComponent
            isOpen={this.state.showLogin}
            handleClick={() => this.closeLogin()}
            handleEventClose={() => this.closeLogin()}
          >
            <LoginComponent
              closeRegister={this.closeRegister}
              OpenRegister={this.openRegister.bind(this)}
            />
          </LightBoxComponent>
        )}

        <WhoWeAreHero
          title={this.state.whoWeAre.whoweare.main_title}
          image={this.state.whoWeAre.whoweare.main_image}
          description={this.state.whoWeAre.whoweare.description}
        />

        <div className="whoWeAreContainer">
          <section id="our-values" className="section">
            <h3 className="title">OUR VALUES*</h3>
            <div className="values">
              {ourValues.map((item) => {
                return (
                  <div className="value">
                    <div className="title">{item.title}</div>
                    <div className="text">{item.text}</div>
                  </div>
                );
              })}
            </div>
          </section>
          <section id="our-partners" className="section">
            <h3 className="title">
              {this.state.whoWeAre.whoweare.our_partners_title}
            </h3>
            <div className="container">
              <div className="left">
                {this.state.whoWeAre.whoweare.our_partners_text}
              </div>
              <div className="right">
                <div className="partnersContainer">
                  {this.state.whoWeAre.partners.map((partner, index) => {
                    return (
                      <div className="partner" key={index}>
                        <img src={partner.image} alt={partner.name} />
                      </div>
                    );
                  })}
                  <div className="partner"></div>
                </div>
              </div>
            </div>
          </section>
          <section id="Wrapper">
            <div
              className="teamHighLight"
              style={{ backgroundImage: `url(${banner})` }}
            >
              <h1 className="wrapperText">
                {this.state.whoWeAre.whoweare.title}
              </h1>
            </div>
          </section>
          <section id="find-out-more" className="section">
            <h3 className="title">
              {this.state.whoWeAre.whoweare.find_out_more_title}
            </h3>
            <div className="container">
              <div className="left">
                <span>{this.state.whoWeAre.whoweare.find_out_more_text}</span>
                <div className="buttons">
                  <button onClick={() => this.openRegister("s")}>
                    I'm a brand
                  </button>
                  <button onClick={() => this.openRegister("b")}>
                    I'm a buyer
                  </button>
                </div>
              </div>
              <div className="right">
                <div className="imageContainer">
                  <img
                    src="/images/whoweare/note.png"
                    className="note"
                    alt="notebook"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterComponent />
      </Fragment>
    );
  }
}

export default WhoWeAre;
