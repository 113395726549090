import React from "react";
import { connect } from "react-redux";
import "../styles/global.scss";
import "../../profile/styles/ProfileViewComponent.scss";
import MenuComponent from "../../menu/components/MenuComponent";
import BaseComponent from "../../shared/BaseComponent";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import { withTranslation } from "react-i18next";
import ProfileService from "../../../services/ProfileService";
import AuthenticationService from "../../../services/AuthenticationService";
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import JumboTitleComponent from "../../shared/jumbotitle/components/JumboTitleComponent";
import Select from "react-select";
import BuyersService from "../../../data/pages/buyers/buyersService";

class ProfileViewComponents extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      products: [],
      render: false,
      loading: true,
      loadingProduct: true,
      param: "",
      currentPage: "",
      first_name: JSON.parse(localStorage.getItem("userData")).first_name,
      last_name: JSON.parse(localStorage.getItem("userData")).last_name,
      phone: JSON.parse(localStorage.getItem("userData")).phone,
      email: JSON.parse(localStorage.getItem("userData")).email,
      logo: JSON.parse(localStorage.getItem("buyerData")).logo,
      logoForm: null,
      company_name: JSON.parse(localStorage.getItem("buyerData")).name,
      company_website: JSON.parse(localStorage.getItem("buyerData")).website,
      year_established: JSON.parse(localStorage.getItem("buyerData"))
        .year_established,
      locationFields: this.loadLocationFields(),
      locations: [],
      locationsToDelete: [],
      buyerCountries: [],
      password: "",
      confirm_password: "",
      error: false,
      errorMsg: "",
      removeLogo: false,
    };
  }

  async componentDidMount() {
    if (
      !JSON.parse(localStorage.getItem("userData")).first_name ||
      JSON.parse(localStorage.getItem("userData")).first_name === "" ||
      !localStorage.getItem("user") ||
      localStorage.getItem("user") === "" ||
      !localStorage.getItem("email") ||
      localStorage.getItem("email") === ""
    ) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }
    this.loadUserData();
    const buyerService = new BuyersService();
    buyerService.getLocations().then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  setDefaultBuyerCountriesData() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const buyerLocations = userData.buyer_locations;
    this.setState({
      buyerCountries: buyerLocations.map((buyerLocation) => buyerLocation.name)
    });
  }

  async loadUserData() {
    await AuthenticationService.getInfoUser();
    this.setDefaultBuyerCountriesData();
    this.setState({
      first_name: JSON.parse(localStorage.getItem("userData")).first_name,
      last_name: JSON.parse(localStorage.getItem("userData")).last_name,
      phone: JSON.parse(localStorage.getItem("userData")).phone,
      email: JSON.parse(localStorage.getItem("userData")).email,
      logo: JSON.parse(localStorage.getItem("buyerData")).logo,
      company_name: JSON.parse(localStorage.getItem("buyerData")).name,
      company_website: JSON.parse(localStorage.getItem("buyerData")).website,
      instagram: JSON.parse(localStorage.getItem("buyerData")).instagram,
      facebook: JSON.parse(localStorage.getItem("buyerData")).facebook,
      text: JSON.parse(localStorage.getItem("buyerData")).text,
      year_established: JSON.parse(localStorage.getItem("buyerData"))
        .year_established,
      locationFields: this.loadLocationFields(),
    });
  }

  loadLocationFields() {
    let locationFields = [];

    if (JSON.parse(localStorage.getItem("buyerData")).full_locations) {
      locationFields = JSON.parse(
        localStorage.getItem("buyerData")
      ).full_locations.map((item) => ({
        glocations: item.address,
        id: item.id,
      }));
    }
    return locationFields;
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addLocationField() {
    let state = this.state;
    state.locationFields.push({ glocations: null });

    this.setState({
      locationFields: state.locationFields,
    });
  }

  changeAutoComplete(event, index) {
    let state = this.state;
    state.locationFields[index].glocations = event.target.value;

    this.setState({
      locationFields: state.locationFields,
    });
  }

  setCompanyLocation(place, index) {
    let state = this.state;
    if (place.name) {
      state.locationFields[index].glocations = place.name;
    } else {
      let size = place.address_components.length;
      state.locationFields[index].glocations = place.formatted_address;
      state.locations.push({
        locations: String(place.address_components[size - 1].long_name),
      });
    }

    this.setState({
      locationFields: state.locationFields,
    });
  }

  removeLocation(index, item) {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to remove the address: ${this.state.locationFields[index].glocations}?`,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#71CFC9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Remove",
      cancelButtonText: "Cancel",
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let state = this.state;
        state.locationFields.splice(index, 1);
        this.setState({
          locationFields: state.locationFields,
        });

        if (item.id) {
          this.setState((prevState) => ({
            locationsToDelete: [...prevState.locationsToDelete, item.id],
          }));
        }
      }
    });
  }

  async submitForm(e) {
    e.preventDefault();

    const data = new FormData();
    data.append("first_name", this.state.first_name);
    data.append("last_name", this.state.last_name);
    data.append("email", this.state.email);
    data.append("phone", this.state.phone);
    if (this.state.logoForm) {
      data.append("logo", this.state.logoForm);
    }
    if (this.state.removeLogo) {
      data.append("logo_remove", this.state.removeLogo);
    }
    data.append("company_name", this.state.company_name);
    data.append("company_website", this.state.company_website || "");
    if (this.state.year_established) {
      data.append("year_established", this.state.year_established || "");
    }
    data.append("instagram", this.state.instagram || "");
    data.append("facebook", this.state.facebook || "");
    data.append("text", this.state.text || "");
    data.append('locations', this.state.buyerCountries);

    let locationsToDel = this.state.locationsToDelete;

    let profileservice = new ProfileService();

    if (
      this.state.first_name === "" ||
      this.state.last_name === "" ||
      this.state.email === "" ||
      this.state.phone === "" ||
      this.state.company_name === ""
    ) {
      this.setState({ errorMsg: "Fill all the required fields!" });
      return;
    }

    if (this.state.password !== "") {
      if (this.state.confirm_password === "") {
        this.setState({
          error: true,
          errorMsg: "Password confirmation invalid!",
        });
        return;
      }

      if (this.state.password !== this.state.confirm_password) {
        this.setState({ error: true, errorMsg: "Password does not match" });
        return;
      }
    }

    let pass = {
      password: this.state.password,
      confirm: this.state.confirm_password,
    };

    let oldAddress = this.state.locationFields
      .filter((item) => item.id)
      .map((item) => ({
        address: item.glocations,
        id: item.id,
      }));
    let newAddress = this.state.locationFields
      .filter((item) => !item.id)
      .map((item) => ({
        address: item.glocations,
      }));

    let nullAddress = this.state.locationFields.find((item) => {
      return item.glocations === null;
    });

    if (nullAddress) {
      this.setState({ errorMsg: "Empty address invalid" });
      return;
    }

    try {
      this.setState({ loading: true });
      await profileservice.updateUser(data);
      if (this.state.password !== "" && this.state.confirm_password !== "") {
        await profileservice.updatePassword(pass);
      }
      if (locationsToDel.length > 0) {
        await profileservice.deleteLocations(locationsToDel);
      }
      if (oldAddress.length > 0) {
        await profileservice.updateAddress(oldAddress);
      }
      if (newAddress.length > 0) {
        await profileservice.createNewAddress(newAddress);
      }
    } catch (error) {
      this.setState({
        loading: false,
        errorMsg: "Ops, error when trying to update!",
      });
      return;
    }

    this.loadUserData();
    if (this.state.password !== "" && this.state.confirm_password !== "") {
      this.setState({
        password: "",
        confirm_password: "",
        error: false,
        errorMsg: "",
      });
      Swal.fire({
        text: "Success, password and data updated!",
        icon: "success",
        showCloseButton: true,
        confirmButtonColor: "#71CFC9",
        confirmButtonText: "OK",
        focusCancel: true,
      });
    } else {
      Swal.fire({
        text: "Success, data updated!",
        icon: "success",
        showCloseButton: true,
        confirmButtonColor: "#71CFC9",
        confirmButtonText: "OK",
        focusCancel: true,
      });
    }
    this.setState({ loading: false, removeLogo: false });
  }

  handleAutocomplete(e, index) {
    let state = this.state;
    state.locationFields[index].glocations = e.target.value;
    this.setState({ locationFields: state.locationFields });
  }

  setSelectedFile(e) {
    if (!e.target.files.length) {
      this.setState({
        logoForm: null,
      });
    }

    this.setState({
      logoForm: e.target.files[0],
    });
  }

  handleRemoveLogo() {
    this.setState({
      logo: null,
      logoForm: null,
      removeLogo: true,
    });
  }

  getDefaultLocationBuyer() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const buyerLocations = userData.buyer_locations;
    return buyerLocations.map((buyerLocation) => {
      return { label: buyerLocation.name, value: buyerLocation.id };
    });
  }

  getOptionsCountries() {
    const { locationsData } = this.props;

    return locationsData.data.map((loc, index) => {
      return { value: loc.id, label: loc.name };
    });
  }

  handleChangeCountry(e) {
    this.setState({
      buyerCountries: e.map((country) => country.label)
    });
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <MenuComponent />
            <LoadingComponent />
          </div>
        ) : (
          <div id="profile-page">
            <MenuComponent />
            <main>
              <section>
                <JumboTitleComponent title={"My Profile"} />
              </section>
              <section>
                <div className="container">
                  <form
                    id="form-profile"
                    onSubmit={(event) => this.submitForm(event).bind(this)}
                  >
                    <div className="form-group">
                      {this.state.logo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.state.logo}
                          style={{ marginBottom: "15px" }}
                        >
                          <img
                            src={this.state.logo}
                            style={{ maxWidth: "100%", height: "auto" }}
                            alt="Logo"
                          />
                          <br />
                        </a>
                      )}
                      <label htmlFor="first_name">
                        {this.props.t("Logo")}
                        <span className="helper">Optimal size: 346x219px</span>
                      </label>
                      <input
                        type="file"
                        name="logo"
                        className="text-field"
                        onChange={this.setSelectedFile.bind(this)}
                        placeholder="Insert your background"
                      />
                      {this.state.logo && (
                        <button
                          type="button"
                          onClick={this.handleRemoveLogo.bind(this)}
                          class="button button--remove-logo"
                        >
                          Remove logo
                        </button>
                      )}
                    </div>
                    <div className="form-group"></div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("First name")}
                        <span className="asterisco">*</span>
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.first_name}
                        placeholder={this.props.t("First name")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("Last name")}
                        <span className="asterisco">*</span>
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.last_name}
                        placeholder={this.props.t("Last name")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("Phone")}
                        <span className="asterisco">*</span>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.phone}
                        placeholder={this.props.t("Phone")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("E-mail")}
                        <span className="asterisco">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.email}
                        placeholder={this.props.t("E-mail")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("Company name")}
                        <span className="asterisco">*</span>
                      </label>
                      <input
                        type="text"
                        name="company_name"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.company_name}
                        placeholder={this.props.t("Company name")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("Year established")}
                      </label>
                      <input
                        type="number"
                        name="year_established"
                        maxLength="4"
                        max="9999"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.year_established}
                        placeholder={this.props.t("Year established")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("Company website")}
                      </label>
                      <input
                        type="text"
                        name="company_website"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.company_website}
                        placeholder={this.props.t("Company website")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("Facebook")}
                      </label>
                      <input
                        type="text"
                        name="facebook"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.facebook}
                        placeholder={this.props.t("Facebook")}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="first_name">
                        {this.props.t("Instagram")}
                      </label>
                      <input
                        type="text"
                        name="instagram"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.instagram}
                        placeholder={this.props.t("Instagram")}
                      />
                    </div>
                    <div className="form-group"></div>
                    <div className="form-group form-group--text">
                      <label htmlFor="text">
                        {this.props.t("Company description")}
                      </label>
                      <textarea
                        rows={5}
                        name="text"
                        onChange={this.onInputchange.bind(this)}
                        className="text-area"
                        value={this.state.text}
                      ></textarea>
                    </div>
                    <div className="form-group icon-absolute">
                      <FontAwesomeIcon
                        onClick={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                        icon={this.state.showPassword ? faEyeSlash : faEye}
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="first_name">
                        {this.props.t("Password")}
                      </label>
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        className="text-field"
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.password}
                        placeholder={this.props.t("Password")}
                      />
                    </div>
                    <div className="form-group icon-absolute">
                      <FontAwesomeIcon
                        onClick={() =>
                          this.setState({
                            showConfirmPassword:
                              !this.state.showConfirmPassword,
                          })
                        }
                        icon={
                          this.state.showConfirmPassword ? faEyeSlash : faEye
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="first_name">
                        {this.props.t("Confirm password")}
                      </label>
                      <input
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        name="confirm_password"
                        className={`text-field ${
                          this.state.error ? "error-input" : null
                        }`}
                        onChange={this.onInputchange.bind(this)}
                        value={this.state.confirm_password}
                        placeholder={this.props.t("Confirm password")}
                      />
                    </div>
                    <div className="form-group select-countries">
                      <label htmlFor="countries">
                        Select a location
                      </label>
                      <Select
                        key={JSON.stringify(this.getDefaultLocationBuyer())}
                        isMulti
                        defaultValue={this.getDefaultLocationBuyer()}
                        options={this.getOptionsCountries()}
                        onChange={this.handleChangeCountry.bind(this)}
                        placeholder="Select a location"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                            primary25: "#d5d5d5",
                            danger: "black",
                            dangerLight: "#666",
                          },
                        })}
                      />
                    </div>
                  </form>
                  {this.state.locationFields.map((item, index) => {
                    return (
                      <div key={index} className="auto-complete-group">
                        <Autocomplete
                          key={index}
                          style={{ width: "100%" }}
                          onPlaceSelected={(place) =>
                            this.setCompanyLocation(place, index)
                          }
                          onBlur={(e) => this.changeAutoComplete(e, index)}
                          types={["address"]}
                          placeholder={this.props.t("Address")}
                          onChange={(e) => this.handleAutocomplete(e, index)}
                          value={item.glocations || ""}
                        />
                        <FontAwesomeIcon
                          onClick={() => this.removeLocation(index, item)}
                          icon="times-circle"
                        />
                      </div>
                    );
                  })}
                  {this.state.locationFields.map((item, index) => {
                    return (
                      <div key={index} className="auto-complete-group">
                        <Autocomplete
                          key={index}
                          style={{ width: "100%" }}
                          onPlaceSelected={(place) =>
                            this.setCompanyLocation(place, index)
                          }
                          onBlur={(e) => this.changeAutoComplete(e, index)}
                          types={["address"]}
                          placeholder={this.props.t("Address")}
                          onChange={(e) => this.handleAutocomplete(e, index)}
                          value={item.glocations || ""}
                        />
                        <FontAwesomeIcon
                          onClick={() => this.removeLocation(index, item)}
                          icon="times-circle"
                        />
                      </div>
                    );
                  })}
                  <div
                    onClick={() => this.addLocationField()}
                    className="add-location"
                  >
                    <FontAwesomeIcon icon="plus-circle" />
                    <span>{this.props.t("add more locations")}</span>
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <button id="btn-send" onClick={this.submitForm.bind(this)}>
                      SAVE
                    </button>
                  </div>
                  <div>
                    <h1 id="errorMsg">{this.state.errorMsg}</h1>
                  </div>
                </div>
              </section>
            </main>
            <FooterComponent />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productData: state.productsEntity[0],
    locationsData: state.locationsEntity,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(ProfileViewComponents)
);
