import React from 'react';
import "../styles/BackToBrands.scss"
import {Link} from "react-router-dom";

const BackToBrands = props => {
    return (
        <div id="backBrands">
            <Link to="/">
                {`<<`}  <span>Back to Brands</span>
            </Link>
        </div>
    );
};

export default BackToBrands;