import React, {Component} from 'react';
import "../style/Grid4Style.scss"
import ShowcaseComponent from "../../../showcase/components/ShowcaseComponent";
import TitleShadowComponent from "../../../titleShadow/components/TitleShadowComponent";
import LightboxHalfScreenComponent from "../../../lightboxHalfScreen/components/LightboxHalfScreenComponent";
import ButtonComponent from "../../../button/components/ButtonComponent";
import isMobile from 'is-mobile';

class Grid4Component extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEnable: false,
            showSeeAll: false,
        }
    }

    toggleReadMore(isEnable) {
        this.setState({
            isEnable: !isEnable
        })
    }


    render() {

        const {info, title, label, images, imageOrientation, listInfo, fullText, linkTo} = this.props;


        if (!isMobile()) {
            return (
                <div className={`grid4 ${imageOrientation}`}>
                    <div className={`leftGrid ${imageOrientation}`}>
                        <ShowcaseComponent imageOrientation={imageOrientation}  images={images} type="portrait_image"/>
                    </div>

                    <div className={`rightGrid ${imageOrientation}`}>
                        {this.state.showSeeAll &&
                            <LightboxHalfScreenComponent
                            close={() => this.setState({showSeeAll: false})}>
                    
                            <h1>INTERNATIONAL RETAILERS</h1>
                            <div style={{marginTop: '50px'}}>
                                <p><strong>{listInfo[2].text}</strong></p>
                                <br />
                                <br />
                                <p style={{whiteSpace: 'pre-wrap'}}>{listInfo[3].text}</p>
                            </div>
                            </LightboxHalfScreenComponent>
                        }
                        {info &&
                        <div className="infoComponent">
                            <div className="containerInfoStatic">
                                <div className="blockInfo brand">
                                    <h4 className="title"> BRAND FROM </h4>
                                    <p className="text">{listInfo[0].text}</p>
                                </div>

                                <div className="blockInfo year">
                                    <h4 className="title"> YEAR ESTABLISHED </h4>
                                    <p className="text">{listInfo[1].text}</p>
                                </div>
                                {!isMobile() &&
                                    <div className="blockInfo multibrand">
                                        <h4 className="title"> INTERNATIONAL RETAILERS </h4>
                                        <p className="text">{listInfo[2].text}</p>
                                        <div className="contentLinkButton" style={{width: 'auto'}}>
                                            <div className="containerLink" style={{margin: '10px 0'}}>
                                                <div className="linkShowCase" onClick={() => this.setState({showSeeAll: true})}>
                                                    {listInfo[3].text !== null && listInfo[3].text === '' &&
                                                        <span>See all {`>>`} </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                            {isMobile() &&
                                <div className="blockInfo multibrand">
                                    <h4 className="title"> INTERNATIONAL RETAILERS </h4>
                                    <p className="text">{listInfo[2].text}</p>
                                    <div className="contentLinkButton" style={{width: 'auto'}}>
                                        <div className="containerLink" style={{margin: '10px 0'}}>
                                            <div className="linkShowCase" onClick={() => this.setState({showSeeAll: true})}>
                                                {listInfo[3].text !== null && listInfo[3].text === '' &&
                                                    <span>See all {`>>`} </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        }

                        {!info &&
                        <div className="top29">&nbsp;</div>
                        }

                        <TitleShadowComponent position="left"
                            type="h2"
                            disableMargin={images.filter(item => item.video_url).length > 0}>
                            {title}
                        </TitleShadowComponent>

                        <div className="containerButtonText">
                            <p className="textGrid1">
                                {/* {
                                    this.state.isEnable ? (
                                        <span>{ fullText }</span>
                                    ) : (
                                        <span>{ text }</span>
                                    )
                                } */}
                                {fullText}
                            </p>

                            <div className="contentLinkButton">
                                {/* <div className="containerLink">
                                    <div onClick={ () => this.toggleReadMore(this.state.isEnable)} className="linkShowCase">
                                      {this.state.isEnable ? (
                                          "<<"
                                      ) : (
                                          <span>Read more {`>>`}</span>
                                      )}
                                    </div>
                                </div> */}
                                {linkTo &&
                                  <ButtonComponent type="link" action={linkTo}>
                                      {label === "" ? "SEE PRODUCTS" : label}
                                  </ButtonComponent>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="grid4-mobile">
                    <div className={`main-image ${imageOrientation}`}>
                        <ShowcaseComponent imageOrientation={imageOrientation}  images={images}/>
                    </div>
                    <div className={`grid-title ${images.filter(item => item.video_url).length > 0 ? 'disable-margin' : ''}`}>
                        <TitleShadowComponent 
                            position="left" type="h2"
                            disableMargin={images.filter(item => item.video_url).length > 0}>
                            {title}
                        </TitleShadowComponent>
                    </div>
                    <div className="grid4-mobile-content">
                        <p className="description">
                            {/* {
                                this.state.isEnable ? (
                                    <span>{ fullText }</span>
                                ) : (
                                    <span>{ text }</span>
                                )
                            } */}
                            {fullText}
                        </p>
                        <div className="content-buttons">
                            {/* <div className="read-more-link">
                                <div className="link-show-case"
                                     onClick={ () => this.toggleReadMore(this.state.isEnable)}>
                                    <span>Read more</span> {`>>`}
                                </div>
                            </div> */}
                            <div className="see-products-button">
                            {linkTo &&
                                <ButtonComponent type="link" action={linkTo}>
                                {label === "" ? "SEE PRODUCTS" : label}
                                </ButtonComponent>
                              }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Grid4Component;
