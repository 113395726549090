import React, { Component } from "react";
import ReactIdSwiper from "react-id-swiper/lib/ReactIdSwiper.custom";
import {
  Swiper,
  Navigation,
  Pagination
} from "swiper/dist/js/swiper.esm";
import "react-id-swiper/lib/styles/scss/swiper.scss";
import Brand from "./Brand";
import '../style/brandSlider.scss';
import { isMobile } from 'is-mobile';

export default class BrandSlider extends Component {
  constructor(props){
      super(props);

      this.state = {
        isBeginning: true,
        isEnd: false,
      }

      this.settings = {
        Swiper,
        modules: [Navigation, Pagination],
        preloadImages: true,
        updateOnImagesReady: true,
        slidesPerGroup: isMobile() ? 2 : 1,
        slidesPerView: isMobile() ? 2 : 4,
        slidesPerColumn: 3,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        spaceBetween: 10,
        slideVisibleClass: 'active-slider',
      };
      this.next = this.next.bind(this)
      this.previous = this.previous.bind(this)
      this.isBeginning = this.isBeginning.bind(this)
      this.isEnd = this.isEnd.bind(this)

  }

  next() {
    this.state.swiper.slideNext()
    this.setState({ isBeginning: this.isBeginning() })
    this.setState({ isEnd: this.isEnd() })
  }

  previous() {
    this.state.swiper.slidePrev()
    this.setState({ isBeginning: this.isBeginning() })
    this.setState({ isEnd: this.isEnd() })
  }

  isBeginning() {
    return this.state.swiper.isBeginning
  }

  isEnd() {
    return this.state.swiper.isEnd
  }


  render(){
    const renderItem = ( brand ) => {
      const { eventSegment } = this.props;
      return <Brand key={brand.id} brand={brand} eventSegment={eventSegment} openModal={() =>this.props.openModal(brand)} />
    };
    return (
      <div className="brand-slider">
        <ReactIdSwiper
           {...this.settings}
           getSwiper={(swiperInstance)=>this.setState({swiper: swiperInstance})}
          >
          {this.props.ourBrands.map(renderItem)}
        </ReactIdSwiper>
        <div className="buttons buttons-list">
          <div className={`swiper-button-prev ${this.state.isBeginning && 'swiper-button-disabled'}`} onClick={this.previous}>
          </div>
          <div className={`swiper-button-next ${this.state.isEnd && 'swiper-button-disabled'}`} onClick={this.next}>
          </div>
        </div>
      </div>
    );
  }
}
