import React from 'react';

const DetailsComponent = props => {

    const { details } = props;

    return (
        <div className="containerDetails">
            <p className="titleDetails">
                PRODUCT DETAILS
            </p>

            <ul>
                {details.map( (detail, index) => <li key={index}>{ detail.text_en }</li> )}
            </ul>
        </div>
    );
};

export default DetailsComponent;