import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";
import {
  faPlusCircle,
  faTimesCircle,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import PatternViewComponent from "./PatternViewComponent";

import ProductsViewComponents from "./ProductsViewComponents";
import BrandPageViewComponent from "./BrandPage/BrandPageViewComponent";
import BuyerPageViewComponent from "./BuyerPage/BuyerPageViewComponent";
import InfoProduct from "../../shared/infoProduct/components/infoProductComponent";
import CartViewComponent from "./CartViewComponent";
import RegisterViewComponent from "./RegisterViewComponent";
import OrderViewComponent from "./OrderViewComponent";
import RecoveryViewComponent from "./RecoveryViewComponent";
import CreateMyOwnListComponent from "../../shared/createmyownlist/components/CreateMyOnListComponent";
import LandingComponent from "../../landing/views/components/LandingComponent";
import HomeSugestedViewComponent from "./HomeSugestedViewComponent";
import WhoWeAre from "../../whoweare/views/components/WhoWeAre";
import ToTheWorld from "../../totheworld/components/ToTheWorld";
import ConnectedCollaboration from "../../connectedcollaboration/components/ConnectedCollaboration";
import BrandsViewComponent from "./BrandsViewComponent";
import CategoriesViewComponent from "./CategoriesViewComponent";
import SegmentService from "../../../data/pages/segments/SegmentService";
import VincularEventSegmentViewComponent from "./VincularEventSegmentViewComponent";
import BuyersViewComponent from "./BuyersViewComponent";
import MessagesViewComponent from "./MessagesPage/MessagesViewComponent";
import ConnectionsPage from "./ConnectionsPage/ConnectionsPage";
import ProfileViewComponents from "./ProfileViewComponents";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import PrivateRoute from "./PrivateRouteComponent";

class RouterSwitchComponent extends Component {
  constructor() {
    super();
    library.add(faPlusCircle, faTimesCircle, faEnvelopeOpen);

    this.state = {
      segments: null,
      loading: true,
      xAccount: null
    };
  }

  async componentWillMount() {
    if (localStorage.getItem("X-Account-Host") !== window.location.host) {
      localStorage.clear();
      window.location.href = "/";
    }

    localStorage.setItem("X-Account-Host", window.location.host);

    const response = await axios.get(
      `${process.env.REACT_APP_NEW_API_URL}/v1/x-account`
    );

    if (
      response.status !== 200 ||
      !response.data.data ||
      !response.data.data.hash
    ) {
      Swal.fire({
        title: "Error!",
        html: "An error occurred. Please try again later.",
        confirmButtonColor: "#000000",
      });

      setTimeout(() => {
        window.location.reload();
      }, 30000);

      return;
    }

    const xAccountHash = response.data.data.hash;
    const xAccountPoweredByBlanc = response.data.data.poweredByBlanc;

    const xAccountTitle = response.data.data.title;
    const xAccountLogo = response.data.data.logo;
    const xAccountStyle = response.data.data.style;

    localStorage.setItem("xAccountHash", xAccountHash);
    localStorage.setItem("xAccountPoweredByBlanc", xAccountPoweredByBlanc ? 1 : 0);
    localStorage.setItem("xAccountTitle", xAccountTitle);
    localStorage.setItem("xAccountLogo", xAccountLogo);
    localStorage.setItem("xAccountStyle", xAccountStyle);
    
    this.setState({
      xAccount: response.data.data
    });

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.interceptors.request.use((request) => {
      request.headers["X-Account-Hash"] = xAccountHash;

      let accessToken = localStorage.getItem("access_token");
      let newAccessToken = localStorage.getItem("new_access_token");

      if (accessToken) {
        request.headers["Authorization"] = `Bearer ${accessToken}`;
        request.headers["Accept-Language"] = "en";
        request.headers["Content-Language"] = "en";
      }

      if (request.url.includes(process.env.REACT_APP_NEW_API_URL)) {
        request.headers["Authorization"] = `Bearer ${newAccessToken}`;
        request.headers["Accept-Language"] = null;
        request.headers["Content-Language"] = null;
      }

      return request;
    });

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) {
          if (error.response.data.error !== "invalid_grant") {
            if (401 === error.response.status) {
              window.localStorage.clear();

              const params = new URLSearchParams(window.location.search);
              let pathname = window.location.pathname;
              let params_string = "";
              let has_next = params.get("next", null);

              if (pathname && has_next === null) {
                for (var key of params.keys()) {
                  let value = params.get(key);
                  if (key !== "next") {
                    params_string += `&${key}=${value}`;
                  }
                }
              }

              window.location.href = `?next=${pathname}${params_string}`;
            }
          }
        }
        return Promise.reject(error);
      }
    );

    try {
      let segmentService = new SegmentService();
      let segments = await segmentService.get();

      this.setState({
        segments: segments.results,
        loading: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        html: "An error occurred. Please try again later.",
        confirmButtonColor: "#000000",
      });

      setTimeout(() => {
        window.location.reload();
      }, 30000);
    }
  }

  renderSegmentsRoutes() {
    const rotas = this.state.segments.map((segment) => {
      if (segment.event) {
        if (!segment.is_active_event) {
          return (
            <Redirect from={`/${segment.slug}`} key={segment.slug} to="/" />
          );
        }

        return (
          <Route
            key={segment.slug}
            path={`/${segment.slug}`}
            exact
            render={() => {
              if (window.localStorage.getItem("user")) {
                if (window.localStorage.getItem("user.event_segments")) {
                  const possuiEventoSegment =
                    JSON.parse(
                      window.localStorage.getItem("user.event_segments")
                    ).filter((item) => item === segment.slug).length > 0;

                  if (possuiEventoSegment) {
                    return (
                      <PrivateRoute
                        key={segment.slug}
                        path={"/" + segment.slug}
                        component={BrandsViewComponent}
                      />
                    );
                  }
                }

                return <Redirect to={`/vincular/${segment.slug}`} />;
              }

              return <LandingComponent evento={segment} />;
            }}
          />
        );
      } else {
        return (
          <PrivateRoute
            key={segment.slug}
            path={"/" + segment.slug}
            component={BrandsViewComponent}
          />
        );
      }
    });

    return rotas;
  }

  isBuyer() {
    return window.localStorage.getItem("user_stype") === "buyer";
  }

  render() {
    const accountTitle = localStorage.getItem("xAccountTitle");
    const accountStyle = localStorage.getItem("xAccountStyle");

    return (
      <div>
        <React.Suspense fallback={null}>
          <Helmet>
            <title>{accountTitle || 'BLANC'}</title>
            <style>
              {accountStyle}
            </style>
          </Helmet>
          {!this.state.loading && (
            <Switch>
              <Route
                path="/"
                exact={true}
                render={() =>
                  window.localStorage.getItem("user") ? (
                    window.localStorage.getItem("user_type") === "buyer" ? (
                      <Redirect to="/home" />
                    ) : (
                      <Redirect
                        to={`/${window.localStorage.getItem("seller_slug")}`}
                      />
                    )
                  ) : (
                    <LandingComponent />
                  )
                }
              />

              <PrivateRoute
                path="/newhome"
                exact={true}
                component={BrandsViewComponent}
              />
              <Route path="/whoweare" exact={true} component={WhoWeAre} />
              <Route
                path="/connectedcollaboration"
                exact={true}
                component={ConnectedCollaboration}
              />
              <Route path="/totheworld" exact={true} component={ToTheWorld} />
              <PrivateRoute
                path="/categories"
                exact={true}
                component={CategoriesViewComponent}
              />
              {this.renderSegmentsRoutes()}
              <PrivateRoute
                path="/connections"
                exact={true}
                component={ConnectionsPage}
              />
              {!this.isBuyer() && (
                <PrivateRoute
                  path="/buyers"
                  exact={true}
                  component={BuyersViewComponent}
                />
              )}
              {!this.isBuyer() && (
                <PrivateRoute
                  path="/buyers/:id"
                  exact={true}
                  component={BuyerPageViewComponent}
                />
              )}
              <PrivateRoute
                path="/brands/:segment_slug?"
                exact={true}
                component={BrandsViewComponent}
              />
              <PrivateRoute
                path="/vincular/:segment_slug"
                exact={true}
                component={VincularEventSegmentViewComponent}
              />
              <PrivateRoute
                path="/home"
                exact={true}
                component={
                  window.localStorage.getItem("user_stype") === "seller"
                    ? HomeSugestedViewComponent
                    : BrandsViewComponent
                }
              />
              <PrivateRoute
                path="/homes"
                exact={true}
                component={HomeSugestedViewComponent}
              />
              {this.isBuyer() && (
                <PrivateRoute
                  path="/profile"
                  exact={true}
                  component={ProfileViewComponents}
                />
              )}
              <PrivateRoute
                path="/pattern"
                exact={true}
                component={PatternViewComponent}
              />
              <PrivateRoute
                path="/products"
                exact={true}
                component={ProductsViewComponents}
              />
              <PrivateRoute
                path="/product/:id"
                exact={true}
                component={InfoProduct}
              />
              <PrivateRoute
                path="/cart"
                exact={true}
                component={CartViewComponent}
              />
              <PrivateRoute
                path="/messages/:subjectId?"
                exact={true}
                component={MessagesViewComponent}
              />
              <Route
                path="/register"
                exact={true}
                component={RegisterViewComponent}
              />
              <Route
                path="/recovery/:token"
                exact={true}
                component={RecoveryViewComponent}
              />
              <PrivateRoute
                path="/order"
                exact={true}
                component={OrderViewComponent}
              />
              <PrivateRoute
                path="/order/:id"
                exact={true}
                component={OrderViewComponent}
              />
              <PrivateRoute
                path="/createmyownlist/:id"
                exact={true}
                component={CreateMyOwnListComponent}
              />
              <PrivateRoute
                path="/:slug/collections/"
                component={BrandPageViewComponent}
              />
              <PrivateRoute path="/:slug/" component={BrandPageViewComponent} />
            </Switch>
          )}
          {this.state.loading && <LoadingComponent />}
        </React.Suspense>
      </div>
    );
  }
}

export default RouterSwitchComponent;
