import React from "react";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {
  let next = "";

  if (arguments[0].location) {
    const params = new URLSearchParams(arguments[0].location.search);
    let pathname = arguments[0].location.pathname;
    let params_string = "";

    for (var key of params.keys()) {
      let value = params.get(key);
      if (key !== "next") {
        params_string += `&${key}=${value}`;
      }
    }
    if (pathname && !window.localStorage.getItem("user")) {
      next = `?next=${pathname}${params_string}`;
    }
    const has_next = params.get("next", null);
    if (has_next) {
      next = `?next=${has_next}${params_string}`;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        window.localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              search: next,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
