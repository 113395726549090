import React, { Component } from "react";

import { withRouter } from "react-router";
import SortByFilter from "../../../shared/sortbyfilter/components/SortByFilter";
import FilterCollectionsComponent from "../../../filtercollections/components/FilterCollectionsComponent";
import MenuCollectionComponent from "../../../menucollection/components/MenuCollectionComponent";
import ProductComponent from "../../../shared/products/components/ProductComponent";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import "../style/CollectionStyle.scss";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import mixpanel from "mixpanel-browser";
import { isMobile } from "is-mobile";

class CollectionComponent extends Component {
  constructor(props) {
    super(props);

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const page = params.get("page");
    let pageNumber = page ? parseInt(page) : 1;

    this.state = {
      collections: [],
      loading: true,
      filterOpened: false,
      stateParam: "",
      loadingCollection: true,
      collectionId: "",
      collection: "",
      activePage: pageNumber,
      lookBookUrl: "",
      pressRelease: "",
    };
  }

  componentDidMount() {
    if (this.props.results.meta.collections[0] && this.props.brandData) {
      mixpanel.identify(
        `${JSON.parse(window.localStorage.getItem("userData")).id}`
      );
      mixpanel.track("Collection Page", {
        seller: this.props.brandData.name,
        seller_id: this.props.brandData.id,
        collection: this.props.results.meta.collections[0].name,
        collection_id: this.props.results.meta.collections[0].id,
      });
    }

    if (this.state.activePage !== 1) {
      this.handlePageChange(this.state.activePage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const page = params.get("page");

    let pageNumber = page ? parseInt(page) : 1;

    if (this.state.activePage !== pageNumber) {
      this.setState({ activePage: pageNumber });
      this.props.setFilters("page", pageNumber);
    }
  }

  handlePageChange(pageNumber) {
    if (isNaN(pageNumber)) {
      pageNumber = 1;
    }
    this.setState({ activePage: parseInt(pageNumber) });

    this.props.history.replace({
      pathname: this.state.pathname,
      search: `?page=${pageNumber}`,
    });
    this.props.setFilters("page", pageNumber);
  }

  setCurrentPageOne() {
    this.setState({ activePage: 1 });
    this.props.history.push({
      pathname: this.state.pathname,
      search: `?page=1`,
    });
    this.props.setFilters("page", "1");
  }

  setCollection(id, lookbookurl, pressRelease) {
    this.setState({
      collectionId: id,
      lookBookUrl: lookbookurl,
      pressRelease: pressRelease,
    });
    this.setCurrentPageOne();
  }

  render() {
    const { meta } = this.props.results;

    if (this.props.loadingProduct) {
      return <LoadingComponent />;
    }

    return (
      <div>
        <main>
          {!isMobile() && (
            <section id="sotBy" className="centerComponent">
              <SortByFilter setFilters={this.props.setFilters.bind(this)} />
            </section>
          )}

          {isMobile() && (
            <div className="info">
              <section id="sotBy" className="centerComponent">
                <SortByFilter setFilters={this.props.setFilters.bind(this)} />
              </section>
              {meta.selected_segment.name &&
                !meta.selected_collection.seller && (
                  <div className="nameAndBrands">
                    Products | {meta.selected_segment.name}
                  </div>
                )}
              {meta.selected_seller.name &&
                !meta.selected_collection.seller && (
                  <div className="nameAndBrands">
                    {meta.selected_seller.name}
                  </div>
                )}
              {meta.selected_collection.seller && (
                <>
                  <div className="nameAndBrands">
                    {`${meta.selected_collection.seller.name}
                                    | ${meta.selected_collection.name}`}
                  </div>
                  <div className="order styleBorder">
                    {meta.selected_collection.order_window_end && (
                      <div>
                        <span className="emph">Order window ending on:</span>{" "}
                        {meta.selected_collection.order_window_end}
                      </div>
                    )}
                    {meta.selected_collection.production_window_start &&
                      meta.selected_collection.production_window_end && (
                        <div>
                          <span className="emph">Expected to ship:</span>{" "}
                          {meta.selected_collection.production_window_start} to{" "}
                          {meta.selected_collection.production_window_end}
                        </div>
                      )}
                    <div>{meta.selected_collection.delivery_msg}</div>
                  </div>
                </>
              )}
            </div>
          )}

          <section id="mainCollection" className="centerComponent">
            <button
              onClick={() =>
                this.setState({ filterOpened: !this.state.filterOpened })
              }
              class={`btn-show-filter ${
                this.state.filterOpened ? "clicked" : ""
              }`}
            >
              Filters
            </button>
            <div
              className={`leftCollection ${
                this.state.filterOpened ? "opened" : ""
              }`}
            >
              <FilterCollectionsComponent
                meta={this.props.results.meta}
                collections={this.props.results.meta.collections}
                isCollection={this.props.isCollection}
                isBrand={this.props.isBrand}
                isBrandPage={this.props.isBrandPage}
                isSegment={this.props.isSegment}
                setFilters={this.props.setFilters.bind(this)}
                getFilters={this.props.getFilters.bind(this)}
                getProducts={this.props.getProducts.bind(this)}
                handleClick={this.props.handleClick.bind(this)}
                clearAllFilter={this.props.clearAllFilter.bind(this)}
                setCurrentPageOne={this.setCurrentPageOne.bind(this)}
                endPoint={this.props.endPoint}
                setCollection={this.setCollection.bind(this)}
                collectionId={this.state.collectionId}
              />
            </div>

            <div className="rightCollection">
              {this.props.results.meta.selected_collection &&
                "lookbook_url" in
                  this.props.results.meta.selected_collection && (
                  <MenuCollectionComponent
                    collections={this.props.results.meta}
                    collectionId={this.state.collectionId}
                    lookBookUrl={this.state.lookBookUrl}
                    pressRelease={this.state.pressRelease}
                    fullCollection={this.props.results.meta.selected_collection}
                    getFilters={this.props.getFilters.bind(this)}
                    selectedSegment={
                      Object.keys(this.props.results.meta.selected_segment)
                        .length > 0
                        ? this.props.results.meta.segments.find(
                            (item) =>
                              item.name ===
                              this.props.results.meta.selected_segment.name
                          ).id || null
                        : null
                    }
                  />
                )}

              <ProductComponent
                loading={this.props.loading}
                pagination={this.props.results.pagination}
                products={this.props.results.results}
                setFilters={this.props.setFilters.bind(this)}
                currentPage={this.props.currentPage}
                isCheckBox={false}
              />

              {this.props.results.pagination.num_pages > 1 && (
                <p className="results">
                  {this.props.results.pagination.count} results
                </p>
              )}

              {this.props.results.pagination.num_pages > 1 && (
                <div className="contentPagination">
                  <Pagination
                    hideDisabled
                    activePage={this.state.activePage}
                    itemsCountPerPage="12"
                    totalItemsCount={this.props.results.pagination.count}
                    onChange={this.handlePageChange.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                  />
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    );
  }
}

CollectionComponent.propType = {
  collection: PropTypes.array.isRequired,
  getFilters: PropTypes.func.isRequired,
};

export default withRouter(CollectionComponent);
