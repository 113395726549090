import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchComponent from "../../shared/search/components/SearchComponent";
import SegmentService from "../../../data/pages/segments/SegmentService";
import LightBoxComponent from "../../shared/lightbox/components/LightBoxComponent";
import LoginComponent from "../../shared/Login/components/LoginComponent";
import isMobile from "is-mobile";

import "../style/MenuStyle.scss";
import AuthenticationService from "../../../services/AuthenticationService";
import { withTranslation } from "react-i18next";
import OrderCartAuthentication from "../../ordercartauthentication/components/OrderCartAuthentication";
import ButtonComponent from "../../shared/button/components/ButtonComponent";

const RenderLightBox = (props) => {
  const { show, handleClick } = props;

  return (
    <LightBoxComponent
      isOpen={show}
      handleClick={handleClick}
      handleEventClose={handleClick}
    >
      <LoginComponent />
    </LightBoxComponent>
  );
};

class MenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      events: [],
      showLightBox: false,
      loading: true,
      offsetDropdown: 60,
      segmentsOpened: false,
      projectsOpened: false,
    };
  }

  showLightBox() {
    this.setState({
      showLightBox: true,
    });
  }

  closeLightBox() {
    this.setState({
      showLightBox: false,
    });
  }

  handleClickSegments() {
    this.setState({ segmentsOpened: !this.state.segmentsOpened });
  }

  handleClickProjects() {
    this.setState({ projectsOpened: !this.state.projectsOpened });
  }

  async componentDidMount() {
    let segmentService = new SegmentService();
    let segments = await segmentService.get();
    let events = await segmentService.getEvents();

    events.results = events.results.filter((item) => item.is_active);

    this.setState({
      segments: segments.results,
      events: events.results,
      loading: false,
    });
  }

  active(ev) {
    if (document.getElementsByClassName("active")[0] !== undefined) {
      document.getElementsByClassName("active")[0].classList.remove("active");
    }
    ev.target.closest("li").classList.add("active");
  }

  handleClick(endPoint, ev) {
    this.active(ev);
    this.props.handleClick();
    this.props.getProducts(endPoint);
  }

  closeMenuMobile() {
    let menu = document.getElementById("navItems");
    menu.style.display = "none";
  }

  static isNotLogged() {
    return window.localStorage.getItem("user") === null;
  }

  getMarginDropDownMenu() {
    return `-${this.state.offsetDropdown / 2}.px`;
  }

  getWidthDropDownMenu(menu) {
    const menuEl = document.getElementById(`dropdown-wrapper-${menu}`);

    if (menuEl) {
      return `${menuEl.offsetWidth + this.state.offsetDropdown}.px`;
    }
  }

  openMenuMobile() {
    let menu = document.getElementById("navItems");
    menu.style.display = "block";
  }

  render() {
    const xAccountLogo = localStorage.getItem("xAccountLogo");

    return (
      <div className="menu-component">
        <div
          className="MenuResponsive"
          id="MenuResponsive"
          onClick={() => this.openMenuMobile()}
        >
          <span />
          <span />
          <span />
        </div>
        {isMobile() && (
          <div className="logo-wrapper-mobile">
            <img src={xAccountLogo} alt="" />
          </div>
        )}
        <nav id="navItems">
          <div id="closeMenu" onClick={() => this.closeMenuMobile()}>
            x
          </div>
          <div
            className={`centerNav ${
              this.state.segmentsOpened ? "segments-open" : ""
            } ${this.state.projectsOpened ? "projects-open" : ""}`}
          >
            {isMobile() && <SearchComponent />}
            <div className="left">
              <div className="logo-wrapper">
                <img src={xAccountLogo} alt="" />
              </div>
              {this.state.loading ? (
                <ul>
                  <li>
                    {window.localStorage.getItem("user_stype") !== "buyer"
                      ? "My Brand"
                      : "Our Brands"}
                  </li>
                </ul>
              ) : window.localStorage.getItem("user_stype") !== "buyer" ? (
                <ul>
                  <li>
                    <Link to={`/${window.localStorage.getItem("seller_slug")}`}>
                      My Brand
                    </Link>
                  </li>
                  <li>
                    <Link to="/buyers">{this.props.t("Find retailers")}</Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link to="/brands">{this.props.t("Our Brands")}</Link>
                  </li>
                </ul>
              )}
            </div>
            {MenuComponent.isNotLogged() ? (
              <div id="LoginMobile">
                <p onClick={() => this.showLightBox()}>sign in</p>
                {this.state.showLightBox && (
                  <RenderLightBox
                    show={this.state.showLightBox}
                    handleClick={() => this.closeLightBox()}
                  />
                )}
              </div>
            ) : (
              <div id="LoginMobile">
                {window.localStorage.getItem("user_stype") !== "buyer" ? (
                  <ul>
                    <li>
                      <Link
                        to={`/${window.localStorage.getItem("seller_slug")}`}
                      >
                        My Brand
                      </Link>
                    </li>
                    <li>
                      <Link to="/buyers">{this.props.t("Find retailers")}</Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                    <li>
                      <Link to="/order">Orders</Link>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <Link to="/brands">Our Brands</Link>
                    </li>
                    <li>
                      {
                        // eslint-disable-next-line
                        <a href="#" onClick={() => this.handleClickSegments()}>
                          Segments
                        </a>
                      }
                    </li>
                    {this.state.segments
                      .filter((item) => !item.event)
                      .map((item, index) => {
                        return (
                          <li className="mobile-segment" key={index}>
                            <Link to={`/${item.slug}`}>{item.name}</Link>
                          </li>
                        );
                      })}
                    {this.state.events.length > 0 &&
                      this.state.events
                        .sort((item, itemB) => item.order - itemB.order)
                        .map((item, index) => {
                          return (
                            this.state.segments.filter((itemSegment) => {
                              if (
                                itemSegment.event &&
                                itemSegment.is_active_event
                              ) {
                                return item.id === itemSegment.event;
                              }
                              return false;
                            }).length > 0 && (
                              <>
                                <li className="projects-button">
                                  {
                                    //eslint-disable-next-line
                                    <a
                                      href="#"
                                      onClick={() => this.handleClickProjects()}
                                    >
                                      {item.name.charAt(0).toUpperCase() +
                                        item.name.slice(1).toLowerCase()}
                                    </a>
                                  }
                                </li>
                                {this.state.segments
                                  .filter((itemSegment) => {
                                    if (
                                      itemSegment.event &&
                                      itemSegment.is_active_event
                                    ) {
                                      return item.id === itemSegment.event;
                                    }
                                    return false;
                                  })
                                  .map((item, index) => {
                                    return this.props.handleClick ===
                                      undefined ? (
                                      <li
                                        key={index}
                                        className="mobile-projects"
                                      >
                                        <Link to={`/${item.slug}`}>
                                          {item.name}{" "}
                                        </Link>
                                      </li>
                                    ) : (
                                      <li
                                        onClick={(ev) =>
                                          this.handleClick(`/${item.slug}`, ev)
                                        }
                                        className="mobile-projects"
                                        key={index}
                                      >
                                        <Link to={`/${item.slug}`}>
                                          {item.name}{" "}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </>
                            )
                          );
                        })}
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                    <li>
                      <Link to="/order">Orders</Link>
                    </li>
                    {window.localStorage.getItem("user_stype") === "buyer" && (
                      <li>
                        <Link to="/profile">My Profile</Link>
                      </li>
                    )}
                  </ul>
                )}
                <ButtonComponent action={() => AuthenticationService.logout()}>
                  Logout
                </ButtonComponent>
              </div>
            )}
            {!isMobile() && <SearchComponent />}
            <div className="right">
              {!isMobile() && (
                <div className="menu-orders">
                  <Link to="/order">ORDERS</Link>
                </div>
              )}
              {!isMobile() && (
                <OrderCartAuthentication
                  cartCount={this.state.cartCount}
                  orderCount={this.state.orderCount}
                  handleClick={() => this.showLightBox()}
                />
              )}
            </div>
          </div>
        </nav>
        <div id="shadowNav"></div>
      </div>
    );
  }
}

export default withTranslation()(MenuComponent);
