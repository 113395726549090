import React from "react";
import "../style/NavFooterStyle.scss";
import { Link } from "react-router-dom";

const getLink = (item) => {
  if (item.isAnchor) {
    return <a href={item.link}>{item.title}</a>;
  } else if (item.isOpen) {
    return (
      <a
        href={item.link}
        onClick={item.onClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        {item.title}
      </a>
    );
  } else if (item.blank) {
    return (
      <a href={item.link} rel="noopener noreferrer" target="_blank">
        {item.title}
      </a>
    );
  } else if (item.isEmail) {
    return <a href={`mailto:${item.email}`}>{item.title}</a>;
  } else if (item.isAction) {
    return <a href='#'>{item.title}</a>; // eslint-disable-line
  } else {
    return <Link to={item.link}>{item.title}</Link>;
  }
};

const MenuFooterComponent = (props) => {
  const { header, navItens } = props;
  return (
    <div id="MenuFooterComponent">
      <ul>
        <li>{header}</li>
        {navItens.map((item, i) => {
          return <li key={i}>{getLink(item)}</li>;
        })}
      </ul>
    </div>
  );
};

export default MenuFooterComponent;
