import React, {Component} from 'react';
import "../style/MenuCollection.scss";
import {Link} from "react-router-dom";
import LightboxHalfScreenComponent from "../../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";


class MenuCollectionComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        }
    }

    goHref(url) {
        window.open(
            `${url}`,
            '_blank'
        );
    }

    closeModal(){
        this.setState({
            showModal: false
        })
    }

    openModal() {
        this.setState(state => ({
            showModal: !state.showModal
        }));
    }


    generateFiltersCreateMyOwnList() {
        const filters = this.props.getFilters();
        const urlSearchParams = new URLSearchParams();
        if (filters.categories) {
            const categories = filters.categories.join(',');
            urlSearchParams.set('categories', categories);
        }

        if (this.props.selectedSegment) {
            urlSearchParams.set('segments', this.props.selectedSegment);
        }

        return urlSearchParams.toString();
    }

    render() {
        const { fullCollection } = this.props;

        return (
            <div>
                {fullCollection &&
                <ul id="menuCollection" className={fullCollection.press_release ? 'justify-center' : ''}>
                {fullCollection.lookbook_url &&
                    <li onClick={() => this.goHref(fullCollection.lookbook_url)}>
                        Download linesheet
                    </li>
                  }

                    <li onClick={
                        () => this.goHref(`${process.env.REACT_APP_API_URL}/api/product_linesheet/?collection=${fullCollection.id}&ordering=`)
                    }>Download product list
                    </li>

                    <li><Link to={`/createmyownlist/${fullCollection.id}?${this.generateFiltersCreateMyOwnList()}`}>Create my own list</Link></li>

                    {fullCollection.press_release &&
                      <li onClick={() => this.openModal()}>Press release</li>
                    }
                </ul>
                }
                { this.state.showModal &&
                  <LightboxHalfScreenComponent
                  close={this.closeModal.bind(this)}>
                    <h1>Press release</h1>
                    <div dangerouslySetInnerHTML={{ __html: fullCollection.press_release }}  />
                  </LightboxHalfScreenComponent>
                }
            </div>

        );
    }
}

export default MenuCollectionComponent;
