import React from "react";
import Slider from "react-slick";
import "./style/SlickStyle.scss";


class SliderComponent extends React.Component {

  getSlidesPerRow(){
    if(!this.props.isMobile){
      if (this.props.templateType === 3 || this.props.templateType === 2) {
        return 1
      }
    }
    return this.props.slidesPerRow
  }

  getSlidesToShow(){
    if(!this.props.isMobile){
      if (this.props.templateType === 3 || this.props.templateType === 2) {
        return 2
      }
    }
    return this.props.slidetoshow
  }

  getSlidesToScroll(){
    if(!this.props.isMobile){
      if (this.props.templateType === 3 || this.props.templateType === 2) {
        return 2
      }
    }
    return this.props.slideScroll
  }
  render() {
    const { children,  dots, infinite, speed, isMobile, activeSlide, initialSlide, templateType, arrow } = this.props;
      let templateTypeClass = templateType ? 'template-' + templateType : '';
      let settings = {
          dots: dots,
          infinite: infinite,
          speed: speed,
          slidesToShow: this.getSlidesToShow(),
          slidesToScroll: this.getSlidesToScroll(),
          slidesPerRow: this.getSlidesPerRow(),
          initialSlide: initialSlide !== undefined ? initialSlide : 0,
          arrows: arrow || false
      };

        if(!isMobile || activeSlide) {
            return (
                <Slider className={'slide ' + templateTypeClass} {...settings}>
                    {children}
                </Slider>
            );
        }

        return (
            <div className="slide">
                {children}
            </div>
        )
    }
}

export default SliderComponent;
