import axios from "axios";

export default class ProfileService {
    
    async updateUser(data) {
        var config = {
            method: 'post',
            url: `api/change-user/`,
            headers: { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            data: data
        };
        try{
            await axios(config);
            return{
                message: "OK"
            };
        }catch(error){ 
            throw error;
        }
    }
    
    async updatePassword(data) {
        var config = {
            method: 'post',
            url: `api/change/`,
            headers: { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            data: data
        };
        try{
            await axios(config);
            return{
                message: "OK"
            };
        }catch(error){ 
            throw error;
        }
    }

    async updateAddress(data){
        var config = {
            method: 'put',
            url: `api/change-user-address/`,
            headers: { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        };

        return new Promise(async (resolve, reject) => {
            for (let index = 0; index < data.length; index++) {
                const item = data[index];

                config.data = {
                    address: item.address,
                    id: item.id
                }

                try {
                    await axios(config);
                } catch (error) {
                    reject(error)
                }
            }
            
            resolve({
                message: "OK"
            });
        })
        
    }

    async createNewAddress(data){
        var config = {
            method: 'post',
            url: `api/change-user-address/`,
            headers: { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        };

        return new Promise(async (resolve, reject) => {
            for (let index = 0; index < data.length; index++) {
                const item = data[index];

                config.data = {
                    address: item.address,
                }

                try {
                    await axios(config);
                } catch (error) {
                    reject(error)
                }
            }
            
            resolve({
                message: "OK"
            });
        })
    }

    async deleteLocations(data){
        var config = {
            method: 'delete',
            url: `api/change-user-address/`,
            headers: { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        };

        return new Promise(async (resolve, reject) => {
            for (let index = 0; index < data.length; index++) {
                const item = data[index];

                config.data = {
                    id: item
                }

                try {
                    await axios(config);
                } catch (error) {
                    reject(error)
                }
            }
            
            resolve({
                message: "OK"
            });
        })
    }
    

}
