import React, { Component } from "react";
import "./style/HighlightE.scss";
import { withTranslation } from "react-i18next";
import { isMobile } from "is-mobile";

class HighlightE extends Component {
  renderSection() {
    return (
      <section id="SecondHeader" className="highlightE">
        <div
          className="texture2"
          style={{
            backgroundImage: `url(${
              isMobile() && this.props.data.image_mobile
                ? this.props.data.image_mobile
                : this.props.data.image
            })`,
          }}
        />
        <div className="logoAndText">
          <div>
            <img src={this.props.data.logo} alt="" />
            <p>{this.props.data.text}</p>
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div>
        {this.props.data && this.props.data.link ? (
          <a
            href={
              this.props.data && this.props.data.link
                ? this.props.data.link
                : ""
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.renderSection()}
          </a>
        ) : (
          this.renderSection()
        )}
      </div>
    );
  }
}

export default withTranslation()(HighlightE);
