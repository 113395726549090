import React from 'react';
import {connect} from "react-redux";
import "../styles/global.scss"
import MenuComponent from "../../menu/components/MenuComponent";
import CollectionComponent from "../../thebrand/collection/components/CollectionComponent";
import BaseComponent from "../../shared/BaseComponent";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import mixpanel from 'mixpanel-browser';

class ProductsViewComponents extends BaseComponent {

    headers = {
        headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('access_token')}`,
            'Accept-Language': 'en',
            'Content-Language': 'en'
        },        
    };

    url = `${process.env.REACT_APP_API_URL}/api/products/`;

    endPoint = this.props.location.search;


    constructor(props) {
        super(props);
        this.state = {
            products: [],
            render: false,
            loading: true,
            loadingProduct: true,
            param: "",
            currentPage: ""
        };

    }

    setCurrentBrand(key) {
        this.setState({
            stateBrand: key
        });
    }

    componentDidMount() {
        this.getProducts();
        mixpanel.identify(`${JSON.parse(window.localStorage.getItem('userData')).id}`)
        mixpanel.track("Product Search");
    }

    render() {

        return (
            <div>
                {this.state.loading ?
                    (
                        <div>
                            <MenuComponent />
                                <LoadingComponent/>
                        </div>)
                    : (
                        <div>
                            <MenuComponent
                                getProducts={this.getProducts.bind(this)}
                                handleClick={this.clearAllFilter.bind(this)} />

                            <CollectionComponent
                                loading={this.state.loadingProduct}
                                results={this.state.products}
                                isCollection={false}
                                isBrand={true}
                                isBrandPage={false}
                                isSegment={true}
                                isMenuCollection={false}
                                setFilters={this.setFilters.bind(this)}
                                getFilters={this.getFilters.bind(this)}
                                currentPage={this.currentPage}
                                getProducts={this.getProducts.bind(this)}
                                handleClick={this.clearAllFilter.bind(this)}
                                clearAllFilter={this.clearAllFilter.bind(this)}
                                clearArrayFilters={this.clearArrayFilters.bind(this)}
                                brandData={this.props.brandData}
                            />
                            <FooterComponent/>
                        </div>
                    )
                }



            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        productData: state.productsEntity[0]
    }
}

export default connect(mapStateToProps)(ProductsViewComponents);
