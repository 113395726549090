import React from 'react';
import PropTypes from "prop-types";


function NavSegmentComponent(props) {

    const { segments } = props;

    return (
        <div className="contentFilter">
            <p>SEGMENT</p>

            <ul>
                {segments.map((item, index) => {
                    return (
                        <li
                            onClick={(ev) => props.clearFilters(props.endPoint ? `${props.endPoint}&segments=${item.id}` : `?segments=${item.id}`)}
                            key={index}>
                            {item.name}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

NavSegmentComponent.propTypes = {
    clearFilters: PropTypes.func.isRequired
};
export default NavSegmentComponent;