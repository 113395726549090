import axios from "axios";
import querystring from "querystring"
import mixpanel from "mixpanel-browser";

const API_CLIENT_ID = "V64QLUP0V9H9E1rdPTGcP6wvettYiM6cLjzTcFzj";
const API_CLIENT_SECRET =
  "0x0cwqdcrP9qNsRi9VRErCw1uzd1mrK8LOV3YL7rXWEvcKL5JO6EZsPGcVu8AEH8ni5WpXTnfkYVchST9d9Qn6owwTkxfgMJfyI2mgk5nWaxvFe3XIWMjdMxEY8L2cEU";

class AuthenticationService {
  login(userData) {
    const data = querystring.stringify({
      grant_type: "password",
      username: userData.email,
      password: userData.password,
    });

    const auth = { username: API_CLIENT_ID, password: API_CLIENT_SECRET };
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const config = {
      auth,
      headers,
    };

    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.post("o/token/", data, config);

        if (response.status === 200) {
          if (!response.data.password_changed) {
            response.data.error = "password_not_changed";
            return reject(response.data);
          }

          let responseNewApi = await axios.post(
            `${process.env.REACT_APP_NEW_API_URL}/v1/auth/login`,
            data
          );

          await this.setTokens(response.data, responseNewApi.data);

          const infoUser = await this.getInfoUser();

          mixpanel.identify(`${infoUser.id}`);
          mixpanel.track("Login");

          mixpanel.people.set({
            $email: infoUser.email,
            $created: infoUser.created_at,
            $distinct_id: `${infoUser.id}`,
            $first_name: infoUser.first_name,
            $last_name: infoUser.last_name,
            $name: `${infoUser.first_name} ${infoUser.last_name}`,
            buyer:
              infoUser.entity_stype === "buyer" ? infoUser.buyer.name : null,
            buyer_id:
              infoUser.entity_stype === "buyer" ? infoUser.buyer.id : null,
            seller:
              infoUser.entity_stype === "seller" ? infoUser.seller.name : null,
            seller_id:
              infoUser.entity_stype === "seller" ? infoUser.seller.id : null,
            segments: infoUser.event_segments.map((item) => item.segment),
          });
          this.redirectAfterLogin();
        }
        resolve(response);
      } catch (ex) {
        this.reportLoginError({
          grant_type: "password",
          username: userData.email,
          password: userData.password,
        });

        if (ex.response && ex.response.data) {
          reject(ex.response.data);
        }
      }
    });
  }

  register() {}

  reportLoginError(data) {
    return axios.post("api/login-error/", data);
  }

  preRegister(userData) {
    return axios.post("api/preregistration/", querystring.stringify(userData), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  }

  teste(userData) {
    axios
      .post("api/preregistration/", querystring.stringify(userData), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        // console.log(response, 123)
      });
  }

  async forgotPassword(userEmail) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.post("api/recovery/", { email: userEmail });
        resolve(response);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  async setTokens(response, responseNew = null) {
    localStorage.setItem("access_token", response.access_token);

    if (responseNew && responseNew.access_token) {
      localStorage.setItem("new_access_token", responseNew.access_token);
    }

    localStorage.setItem("refresh_token", response.refresh_token);
  }

  logout() {
    window.localStorage.clear();
    window.location.href = "/";
  }
  redirectAfterLogin() {
    const params = new URLSearchParams(window.location.search);
    let next = params.get("next", "");
    let params_string = "";
    for (var key of params.keys()) {
      let value = params.get(key);
      if (key !== "next") {
        params_string += `&${key}=${value}`;
      }
    }

    if (localStorage.getItem("vincular_evento")) {
      next = localStorage.getItem("vincular_evento");
    }

    if (localStorage.getItem("redirectBrand")) {
      if (!localStorage.getItem("vincular_evento")) {
        next = localStorage.getItem("redirectBrand");
        localStorage.removeItem("redirectBrand");
      }
    }

    if (params_string) {
      window.location.href = `${next}?${params_string}`;
    } else if (next) {
      window.location.href = `${next}`;
    } else {
      window.location.href = "/";
    }

    localStorage.removeItem("vincular_evento");
  }

  async getInfoUser() {
    const headers = {
      Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
    };
    const configUser = { headers };

    let response = await axios.get(
      `${process.env.REACT_APP_NEW_API_URL}/v1/auth/user-data`,
      configUser
    );
    let r = response.data;

    if (r.first_name === "" || !r.first_name) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }

    let user = {};
    user.first_name = r.first_name;
    user.last_name = r.last_name;
    user.id = r.id;
    user.entity_type = r.entity_type;
    user.entity_stype = r.entity_stype;
    user.entity_id = r.entity_id;
    user.email = r.email;
    user.phone = r.phone;
    user.welcome_msg = r.welcome_msg;
    user.buyer_locations = r.buyer_locations;
    user.user_hash = r.user_hash;
    user.is_staff = r.is_staff;
    user.allow_auto_add_one_unit_cart = r.allow_auto_add_one_unit_cart;

    let buyer = r.buyer;

    localStorage.setItem("userData", JSON.stringify(user));
    localStorage.setItem("buyerData", JSON.stringify(buyer));
    localStorage.setItem("id", response.data.id);
    localStorage.setItem("user", response.data.first_name);
    localStorage.setItem("user_type", response.data.entity_type);
    localStorage.setItem("user_stype", response.data.entity_stype);
    localStorage.setItem("seller_slug", response.data.seller_slug || "");
    localStorage.setItem("email", response.data.email);
    localStorage.setItem("show_welcome_msg", response.data.welcome_msg);

    let userEventSegments = [];

    if (r.event_segments.length > 0) {
      r.event_segments.map((item) => {
        return userEventSegments.push(item.segment);
      });
    }

    localStorage.setItem(
      "user.event_segments",
      JSON.stringify(userEventSegments)
    );

    return r;
  }
}

export default new AuthenticationService();
