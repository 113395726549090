import React, { Component } from "react";
import Grid2Component from "../../../shared/grids/grid2/components/Grid2Component";
import Grid1Component from "../../../shared/grids/grid1/components/Grid1Component";
import BrandStyleComponent from "../../../brandstyle/components/BrandStyleComponent";
import SliderComponent from "../../../shared/Slider/components/SliderComponent";
import BoxComponent from "../../../shared/box/components/BoxComponent";
import TermsAndAboutRatingComponent from "../../../shared/termsandaboutrating/components/TermsAndAboutRatingComponent";
import BackToBrands from "../../../shared/backtobrands/components/BackToBrands";
import ReadMoreComponent from "../../../shared/readmore/components/ReadMoreComponent";
import FiltersComponent from "../../../shared/filters/components/FiltersComponent";
import Grid4Component from "../../../shared/grids/grid4/component/Grid4Component";
import LookBookLightBoxComponent from "../../../shared/lookbooklightbox/components/LookBookLightBoxComponent";
import isMobile from "is-mobile";
import "../../style/TheBrand.scss";
import mixpanel from "mixpanel-browser";
import ConnectionComponent from "../../../views/components/ConnectionComponent";
import { withRouter } from "react-router-dom";
class TheBrandComponent extends Component {
  info = [
    { title: "BRAND FROM", text: this.props.theBrand.brand_from },
    { title: "YEAR ESTABLISHED", text: this.props.theBrand.year_established },
    {
      title: "MULTIBRAND RETAILERS",
      text: this.props.theBrand.multibrand_retailers,
    },
    {
      title: "MULTIBRAND RETAILERS DESCRIPTION",
      text: this.props.theBrand.multibrand_retailers_full,
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      idFilter: "",
    };
  }

  closedLightBox() {
    this.setState({
      open: false,
    });
  }
  openLightBox(key) {
    this.setState({
      open: true,
      key: key,
    });
  }

  getIdFilter(id) {
    if (id === this.state.idFilter) {
      this.setState({
        idFilter: "",
      });
    } else {
      this.setState({
        idFilter: id,
      });
    }
  }
  getItemsSplitedByFive(items) {
    let items_by_5 = [];
    let i, j, temparray;
    for (i = 0, j = items.length; i < j; i += 5) {
      temparray = items.slice(i, i + 5);
      items_by_5.push(temparray);
    }
    return items_by_5;
  }

  componentDidMount() {
    mixpanel.identify(
      `${JSON.parse(window.localStorage.getItem("userData")).id}`
    );
    mixpanel.track("Seller Page", {
      seller: this.props.brandData.name,
      seller_id: this.props.brandData.id,
    });
  }

  render() {
    const { theBrand, seller, brandStyleImages, tags, readMore } = this.props;
    const media = Math.round(brandStyleImages.length / 2);
    const userData = JSON.parse(localStorage.getItem("userData"));

    return (
      <div>
        {!(
          this.props.brandData.id ===
            Number(userData.entity_id) &&
            userData.entity_stype === "seller"
        ) && (
          <section className="centerComponent">
            <ConnectionComponent
              type="seller"
              subjectId={this.props.brandData.id}
            />
          </section>
        )}
        <section className="centerComponent">
          <Grid1Component
            info={true}
            listInfo={this.info}
            title={theBrand.featured_info_block.title}
            link="/"
            images={theBrand.featured_info_block.images}
            text={theBrand.featured_info_block.summary}
            fullText={theBrand.featured_info_block.text}
            linkTo={theBrand.featured_info_block.link}
            label={theBrand.featured_info_block.button_label}
            isReadMore={this.props.isReadMore.bind(this)}
            readMore={readMore}
          />
        </section>
        <section id="brandStyleFilters" className="centerComponent">
          <div className="containerBrandFilter">
            <div className="left">
              <BrandStyleComponent>
                <h2>Brand Style</h2>
                <p>{theBrand.brand_style}</p>
              </BrandStyleComponent>
            </div>
            <div className="right">
              <div className="contentRight">
                <FiltersComponent
                  seller={seller}
                  link={true}
                  makeFilter={this.getIdFilter.bind(this)}
                  filters={theBrand.categories}
                />
              </div>
            </div>
          </div>
        </section>
        {!isMobile() && (
          <section id="sliderBrands" className="centerComponent">
            <div className="centerComponent">
              <SliderComponent
                dots={false}
                infinite={false}
                speed="500"
                isMobile={isMobile()}
                activeSlide={false}
                templateType={theBrand.brand_style_template}
                slidetoshow={4}
                slideScroll={4}
                slidesPerRow={2}
              >
                {!isMobile() &&
                  theBrand.brand_style_template === 2 &&
                  this.getItemsSplitedByFive(brandStyleImages).map(
                    (itens, i) => {
                      let boxes = [];
                      let firstItem = itens.shift();
                      boxes.push(
                        <div className="box-big">
                          <BoxComponent
                            title={firstItem.title}
                            id={firstItem.id}
                            index={i}
                            image={firstItem.image_580_600}
                            buttonLabel={firstItem.button_label}
                            link={firstItem.link}
                            key={i}
                            newClass={" "}
                            openLightbox={this.openLightBox.bind(this)}
                            makeEffectFilter={this.state.idFilter}
                            collection={firstItem.collection}
                          />
                        </div>
                      );

                      let boxes_2 = itens.map((image, i) => {
                        return (
                          <BoxComponent
                            title={image.title}
                            id={image.id}
                            image={image.image_285}
                            buttonLabel={image.button_label}
                            link={image.link}
                            key={i}
                            index={i + 1}
                            newClass={"box-small "}
                            openLightbox={this.openLightBox.bind(this)}
                            makeEffectFilter={this.state.idFilter}
                            collection={image.collection}
                          />
                        );
                      });
                      boxes.push(<div className="boxes-small">{boxes_2}</div>);
                      return boxes;
                    }
                  )}
              </SliderComponent>
            </div>
          </section>
        )}
        {isMobile() && (
          <section id="sliderBrands" className="centerComponent">
            <div className="centerComponent">
              <SliderComponent
                dots={false}
                infinite={false}
                speed="500"
                activeSlide={false}
                templateType={theBrand.brand_style_template}
                slidetoshow={2}
                slideScroll={1}
                slidesPerRow={2}
                arrow={true}
              >
                {brandStyleImages.map((image, i) => {
                  return (
                    <BoxComponent
                      title={image.title}
                      id={image.id}
                      image={image.image_285}
                      buttonLabel={image.button_label}
                      link={image.link}
                      key={i}
                      openLightbox={() => image.link && window.open(image.link)}
                      newClass={media === i ? "breakLine" : ""}
                      makeEffectFilter={this.state.idFilter}
                      collection={image.collection}
                    />
                  );
                })}
              </SliderComponent>
            </div>
          </section>
        )}
        {theBrand.info_block.map((block, index) => {
          return block.image_position === "right" ? (
            <section className="centerComponent margin shadowMob" key={index}>
              <Grid2Component
                info={false}
                title={block.title}
                link="/"
                images={block.images}
                text={block.summary}
                label={block.button_label}
                linkTo={block.link}
                fullText={block.text}
                imageOrientation={block.image_orientation}
              />
            </section>
          ) : (
            <section
              className="centerComponent mgBottom zero lastGrid"
              key={index}
            >
              <Grid4Component
                info={false}
                title={block.title}
                link="/"
                images={block.images}
                text={block.summary}
                label={block.button_label}
                linkTo={block.link}
                fullText={block.text}
                imageOrientation={block.image_orientation}
              />
            </section>
          );
        })}
        <section className="centerComponent">
          <div className="contentTerms">
            <TermsAndAboutRatingComponent
              aboutRatingText={theBrand.about_rating}
              termsAndConditionsText={theBrand.terms_conditions}
            />
          </div>
        </section>
        {this.state.open && (
          <LookBookLightBoxComponent
            handleClick={this.closedLightBox.bind(this)}
          >
            <SliderComponent
              dots={false}
              arrow={true}
              infinite={false}
              speed="500"
              slidetoshow={1}
              slideScroll={1}
              slidesPerRow={1}
              slidesToShow={1}
              initialSlide={this.state.key}
            >
              {brandStyleImages.map((image, index) => {
                return (
                  <div className="contentBox" key={index}>
                    <div className="boxLookBook">
                      <img src={image.image_580_600} alt={image.name} />
                    </div>
                  </div>
                );
              })}
            </SliderComponent>
          </LookBookLightBoxComponent>
        )}
        <section id="readMore">
          <div className="centerComponent">
            <BackToBrands />
            <ReadMoreComponent tags={tags} />
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(TheBrandComponent);
