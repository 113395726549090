import React, { Component } from "react";
import Select from "react-select";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import BuyersService from "../../../../data/pages/buyers/buyersService";

class NavBrandLocationsComponent extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.checkeds = [];
  }

  getChecked(id) {
    const qs = queryString.parse(window.location.search);
    if ("locations" in qs) {
      return (
        "locations" in qs && qs.categories.split(",").includes(id.toString())
      );
    }
    return false;
  }

  getBaseLink() {
    let url = "?";
    const qs = queryString.parse(window.location.search);
    if ("title" in qs) {
      url += "title=" + qs.title + "&";
    }
    if ("segments" in qs) {
      url += "segments=" + qs.segments + "&";
    }
    return url;
  }

  getLink(id) {
    const qs = queryString.parse(window.location.search);
    let url = this.getBaseLink();
    if ("locations" in qs) {
      let locations = qs.locations.split(",");
      if (locations.includes(id.toString())) {
        locations = locations.filter((e) => e !== id.toString());
      } else {
        locations.push(id.toString());
      }
      if (locations.length > 0) {
        url += "locations=" + locations.join(",");
      }
    } else {
      url += "locations=" + id;
    }
    return url;
  }

  getOptions() {
    const { locations } = this.props;

    return locations.map((loc, index) => {
      return { value: loc.name, label: loc.name };
    });
  }

  handleChange(results) {
    const locationsIds = results.map((loc) => loc.value);
    const search = this.props.history.location.search;
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.set("locations", locationsIds.join(","));
    urlSearchParams.set("page", 1);

    new BuyersService().getData(
      urlSearchParams.get("page"),
      locationsIds,
      urlSearchParams.get("letter")
    );

    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: `?${urlSearchParams.toString()}`,
    });
  }

  render() {
    return (
      <>
        <div className="contentFilter checkSeller">
          <p>LOCATION</p>
          <div className="line"></div>
          <Select
            isMulti
            options={this.getOptions()}
            onChange={this.handleChange.bind(this)}
            placeholder="Select a location"
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'black',
                primary25: '#d5d5d5',
                danger: 'black',
                dangerLight: '#666'
              },
            })}
          />
        </div>
      </>
    );
  }
}

export default withRouter(NavBrandLocationsComponent);
