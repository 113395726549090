import React from 'react';

const ThumbComponent = props => {

    const {thumbs, handleClick} = props;

    return (
        <div className="thumbs">
            {thumbs.map((image, index) => {
                return (
                    <div className="thumb" key={index}>
                        <img src={image.picture_thumbnail} alt="" onClick={() => handleClick(index)}/>
                    </div>
                )
            })}
        </div>
    );
};

export default ThumbComponent;