import React from 'react';
import LightboxHalfScreenComponent from "../../lightboxHalfScreen/components/LightboxHalfScreenComponent";
import { withTranslation } from 'react-i18next';

function PrivacyPolicy(props) {

    return (
        <div className="privacyPolicy">
            <LightboxHalfScreenComponent
                close={props.close}>
                <h1>{props.t('PRIVACY POLICY')}</h1>

                <div dangerouslySetInnerHTML={{__html: props.text}} />

            </LightboxHalfScreenComponent>
        </div>
    )
};

export default withTranslation()(PrivacyPolicy);