import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuComponent from "../../menu/components/MenuComponent";
import SpinnerComponent from "../../shared/spinner";
import "../../cart/styles/CartViewComponent.scss";
import { connect } from "react-redux";
import CartInterector from "../../../domain/pages/cart/CartInterector";
import CartService from "../../../services/CartService";
import CartServiceRedux from "../../../data/pages/cart/CartService";
import FeedBackMsgComponents from "../../shared/feedbackmsg/components/FeedBackMsgComponents";
import ReactTooltip from "react-tooltip";
import ProductSizeChart from "../../shared/productSizeChart/ProductSizeChart";
import { GetVariationColorStyle } from "../../../utils/productHelper.js";
import mixpanel from "mixpanel-browser";
import isMobile from "is-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";

const CartEmpity = () => {
  return (
    <div className="cartEmpity">
      <FontAwesomeIcon icon={faShoppingCart} />
      <h1>
        YOU CART IS EMPTY. <br />
        ADD SOME PRODUCTS TO BEGIN.
      </h1>
      <Link to="/brands">
        <div className="linkBrands">find new brands</div>
      </Link>
    </div>
  );
};

class CartViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeedback: false,
      showErrorFeedback: false,
      valueError: false,
      quantityError: false,
      cartList: false,
      cart: null,
      isLoading: true,
      sellerSelected: {
        sellerSelected: {
          name: "",
        },
      },
      productsOnTheOrder: [],
      productVariations: [],
      cartService: new CartService(),
      cartItems: [],
      message: "",
      lastAddedId: null,
      sendOrderLoading: false,
      cartEmpty: false,
      showLightBoxSize: false,
      infoSizeChart: {},
      errors: [],
      nextPage: false,
      isLoadingMore: false,
      loadingAutofilll: false,
    };

    this.timer = null;
    this.closeFeedback = this.closeFeedback.bind(this);
  }

  componentDidMount() {
    this.getCart(true);
  }

  getCart(replaceAll = false) {
    this.state.cartService.getCart().then((res) => {
      if (res.data.results.length === 0) {
        this.setState({
          cartEmpty: true,
          isLoading: false,
        });
      } else {
        this.cartData(res, replaceAll);
        this.setState({
          isloading: false,
          nextPage: res.data.pagination.next,
        });

        if (this.props.location.state) {
          const { sellerId } = this.props.location.state;
          if (sellerId) {
            this.sellerSelect(sellerId);
            this.setState({ lastAddedId: sellerId });
          }
        }
      }
    });
  }

  getMoreCarts() {
    if (!this.state.nextPage) {
      return;
    }

    this.setState({ isLoadingMore: true });
    this.state.cartService.getCart(this.state.nextPage).then((res) => {
      this.cartData(res);
      this.setState({
        isloading: false,
        nextPage: res.pagination.next,
      });
    });
  }

  removeAllProducts() {
    var confirmRemove = window.confirm(
      "Do you want to remove all products of this brand?"
    );
    if (confirmRemove) {
      this.setState({ isLoading: true });
      new CartInterector(
        new CartServiceRedux()
          .removeProductsAll(this.state.sellerSelected.cartId)
          .then((res) => {
            this.setState(
              {
                cart: null,
                cartItems: [],
              },
              () => {
                this.getCart(true);
              }
            );
          })
      );
    }
  }

  removeProductFromCart(productId, cartId) {
    let confirmRemove = window.confirm(
      "Are you sure you want to remove this product from cart?"
    );

    if (confirmRemove) {
      this.setState({
        isLoading: true,
      });

      new CartInterector(
        new CartServiceRedux().removeCart(cartId, productId).then((res) => {
          setTimeout(() => {
            this.setState({
              isLoading: false,
            });
          }, 700);

          this.getCart(true);
        })
      );
    }
  }

  cartData(data, replaceAll = false) {
    let response = data;
    let cartItems = [];

    data.data.results.forEach((cartItem, index) => {
      cartItems.push({
        cartId: cartItem.id,
        status: cartItem.status,
        buyer: cartItem.buyer,
        orderDeliveryDays: cartItem.order_delivery_days,
        seller: {
          id: cartItem.seller.id,
          name: cartItem.seller.name,
          linesheetsCount: cartItem.linesheets.count,
        },
        products: [],
      });

      cartItem.products.forEach((product, productIndex) => {
        let variations_list = [];

        Object.keys(product.variations).forEach(function (key) {
          variations_list[key] = product.variations[key];
        });

        cartItems[index].products.push({
          id: product.product.id,
          cartId: cartItem.id,
          name: product.product.name,
          image: product.product.image.picture_thumbnail,
          deliveryDays: product.product.order_delivery_days,
          referenceCode: product.product.reference_code,
          wholesalePrice: {
            currency: product.product.wholesale_price.currency,
            amount: product.product.wholesale_price.amount,
            tip: product.product.wholesale_price_tip,
          },
          retailerPrice: {
            currency: product.product.retailer_price.currency,
            amount: product.product.retailer_price.amount,
            tip: product.product.retailer_price_tip,
          },
          validationsRules: {
            orderMaximumQuantity: product.product.order_maximum_quantity,
            orderMinimumQuantity: product.product.order_minimum_quantity,
          },
          variations: variations_list,
          errors: [],
          errorsSizes: [],
          sizeChart: product.product.sizechart,
          ship_msg: product.product.ship_msg,
        });
      });
    });

    let newCartItems = [];

    if (replaceAll) {
      newCartItems = [...cartItems];
    } else {
      newCartItems = [...this.state.cartItems, ...cartItems];
    }

    this.setState({
      cartItems: newCartItems,
      cart: response.data,
      isLoading: false,
      sellerSelected: cartItems[0],
    });
  }

  isOrderValid() {
    let validFlag = true,
      sellerSelected = this.state.sellerSelected;
    let errors = [];
    sellerSelected.products.forEach((product, index) => {
      // product.errors = [];
      if (
        this.totalizersCalc(index).totalUnits >
        product.validationsRules.orderMaximumQuantity
      ) {
        validFlag = false;
        errors.push({
          index: index,
          error: (
            <React.Fragment>
              <h1>MAXIMUM QUANTITY</h1>
              THE MAXIMUM QUANTITY IS{" "}
              <b>{product.validationsRules.orderMaximumQuantity}</b> FOR THIS
              ORDER TO PROCEED
            </React.Fragment>
          ),
        });
      }

      if (
        this.totalizersCalc(index).totalUnits <
        product.validationsRules.orderMinimumQuantity
      ) {
        validFlag = false;
        errors.push({
          index: index,
          error: (
            <React.Fragment>
              <h1>MINIMUM QUANTITY</h1>
              THE MINIMUM QUANTITY IS{" "}
              <b>{product.validationsRules.orderMinimumQuantity}</b> FOR THIS
              ORDER TO PROCEED
            </React.Fragment>
          ),
        });
      }
    });
    if (!validFlag) {
      setTimeout(() => {
        var element = document.getElementsByClassName("box-error")[0];

        if (element) {
          element.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          // const offset = 45;
          // const bodyRect = document.body.getBoundingClientRect().top;
          // const elementRect = element.getBoundingClientRect().top;
          // const elementPosition = elementRect - bodyRect;
          // const offsetPosition = elementPosition - offset;

          // window.scrollTo({
          //   top: offsetPosition,
          //   behavior: "smooth",
          // });
        }
      }, 300);
    }

    this.setState({ sellerSelected });
    this.setState({ errors });
    this.setState({ showErrorFeedback: !validFlag });

    return validFlag;
  }

  finalizeOrderCart() {
    this.setState({ quantityError: false });
    this.setState({ valueError: false });

    if (this.isOrderValid()) {
      this.setState({
        sendOrderLoading: true,
      });
      new CartInterector(
        new CartServiceRedux()
          .finalizeOrder(this.state.sellerSelected.cartId, this.state.message)
          .then((res) => {
            this.setState({ sendOrderLoading: false });
            if (res.status === 400) {
              if (res.data.message === "Your cart is empty.") {
                let error = (
                  <React.Fragment>
                    <b>YOUR CART IS EMPTY</b>
                  </React.Fragment>
                );

                this.setState({ valueError: error });
              }

              if (res.data.quantity) {
                let error = (
                  <React.Fragment>
                    <h1>{res.data.quantity.side} QUANTITY</h1>
                    THE {res.data.quantity.side} QUANTITY IS{" "}
                    <b>{res.data.quantity.limit}</b> FOR THIS ORDER TO PROCEED
                  </React.Fragment>
                );

                this.setState({ quantityError: error });
              }
              if (res.data.value) {
                let error = (
                  <React.Fragment>
                    <h1>{res.data.value.side} VALUE</h1>
                    THE {res.data.value.side} VALUE IS{" "}
                    <b>$ {res.data.value.limit}</b> FOR THIS ORDER TO PROCEED
                  </React.Fragment>
                );

                this.setState({ valueError: error });
              }

              if (Object.keys(res.data).length > 0) {
                const errorsSizes = [];
                const errors = [];

                Object.keys(res.data).forEach((key) => {
                    if (res.data[key].size_id) {
                      errorsSizes.push({
                        index: key,
                        variation_id: Number(res.data[key].variation_id),
                        size_id: Number(res.data[key].size_id),
                        error: res.data[key].side === 'minimum' ? (
                          <React.Fragment>
                            THE MINIMUM QUANTITY IS{" "}
                            <b>{res.data[key].limit}</b> FOR THIS
                            ORDER TO PROCEED
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            THE MAXIMUM QUANTITY IS{" "}
                            <b>{res.data[key].limit}</b> FOR THIS
                            ORDER TO PROCEED
                          </React.Fragment>
                        ) 
                      })
                    } else {
                      errors.push({
                        index: this.state.cartItems[0].products.findIndex(product => Number(product.id) === Number(key)),
                        error: res.data[key].side === 'minimum' ? (
                          <React.Fragment>
                            <h1>MINIMUM QUANTITY</h1>
                            THE MINIMUM QUANTITY IS{" "}
                            <b>{res.data[key].limit}</b> FOR THIS
                            ORDER TO PROCEED
                          </React.Fragment>
                        ) :  (
                          <React.Fragment>
                            <h1>MAXIMUM QUANTITY</h1>
                            THE MAXIMUM QUANTITY IS{" "}
                            <b>{res.data[key].limit}</b> FOR THIS
                            ORDER TO PROCEED
                          </React.Fragment>
                        ),
                      });
                    }
                })

                this.setState({ errorsSizes })
                this.setState({ errors });
              }

              this.setState({ showErrorFeedback: true });
              let el = document.getElementsByClassName("box-error")[0];
              el.scrollIntoView({ behavior: "smooth" });
            } else if (res.status === 201) {
              let sellerSelected = this.state.sellerSelected;
              sellerSelected.orderId = res.data.order;
              sellerSelected.status = "c";

              this.setState({
                sellerSelected: sellerSelected,
              });

              mixpanel.identify(
                `${JSON.parse(window.localStorage.getItem("userData")).id}`
              );
              mixpanel.track("New Order", {
                seller: this.state.sellerSelected.seller.name,
                seller_id: this.state.sellerSelected.seller.id,
                buyer: this.state.sellerSelected.buyer.name,
                buyer_id: this.state.sellerSelected.buyer.id,
                order_products: this.state.cart.results[0].products
                  .map((item) => item.product.name)
                  .join(","),
                order_products_id: this.state.cart.results[0].products
                  .map((item) => item.product.id)
                  .join(","),
                order_total: this.totalizersCalc(0).totalValue,
              });

              this.showFeedback();
            }
          })
          .catch((reason) => {
            this.setState({ sendOrderLoading: false });
          })
      );
    }
  }

  sellerSelect(id) {
    let selectedSeller = this.state.cartItems.find(
      (item) => item.seller.id === id
    );
    this.setState({ message: "" });

    if (selectedSeller) {
      this.setState({ sellerSelected: selectedSeller });
    } else if (this.state.cartItems[0]) {
      this.setState({
        sellerSelected: this.state.cartItems.find(
          (item) => item.seller.id === this.state.cartItems[0].seller.id
        ),
      });
    } else {
      this.setState({
        sellerSelected: {
          sellerSelected: {
            name: "",
          },
        },
      });
    }
    this.setState({
      cartList: false,
    });

    if (isMobile()) window.scrollTo(0, 0);
  }

  totalizersCalc(index) {
    let totalUnits = 0,
      totalValue = 0.0;

    if (this.state.sellerSelected) {
      this.state.sellerSelected.products[index].variations.forEach(
        (variation) => {
          variation.items.forEach((item) => {
            totalUnits += item.quantity;
          });
        }
      );
    }

    totalValue =
      totalUnits *
      parseFloat(
        this.state.sellerSelected.products[index].wholesalePrice.amount
      );

    let currency =
      this.state.sellerSelected.products[index].wholesalePrice.currency;

    return {
      totalUnits,
      totalValue: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: currency,
      })
        .format(totalValue)
        .replace("US$", "USD"),
    };
  }

  countVariationTotalUnit(variationItems) {
    let variationTotal = 0;
    variationItems.forEach((variation) => {
      variationTotal += variation.quantity;
    });

    return variationTotal;
  }

  generalTotalizersCalc() {
    let totalAllUnits = 0,
      totalAllValue = 0,
      allValuesToReduce = [];

    this.state.sellerSelected.products.forEach((product) => {
      let totalVariationUnits = 0;
      product.variations.forEach((variation) =>
        variation.items.forEach((item) => {
          totalAllUnits += item.quantity;
          totalVariationUnits += item.quantity;
        })
      );
      allValuesToReduce.push(
        product.wholesalePrice.amount * totalVariationUnits
      );
    });
    totalAllValue = allValuesToReduce.reduce((prev, current) => {
      return prev + current;
    }, 0);

    let currency =
      this.state.sellerSelected.products.length > 0
        ? this.state.sellerSelected.products[0].wholesalePrice.currency
        : "";

    return {
      totalAllUnits,
      allValues: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: currency,
      }).format(totalAllValue),
    };
  }

  toggleButtonSend(quantity, min_quantity, max_quantity) {
    let buttonSend = document.getElementsByClassName("send-order-btn")[0];
    let buttonSend1 = document.getElementsByClassName("send-order-btn")[1];

    if (quantity < min_quantity || quantity > max_quantity) {
      buttonSend.classList.add("disabled");
      buttonSend1.classList.add("disabled");
    } else {
      buttonSend.classList.remove("disabled");
      buttonSend1.classList.remove("disabled");
    }
  }

  incrementQuantity(event, size, quantity, totalProductsItens, stock) {
    let bgSize = event.target.closest(".size");

    bgSize.classList.add("blink");

    if (this.time) {
      clearTimeout(this.time);
    }

    this.time = setTimeout(() => {
      bgSize.classList.remove("blink");
    }, 200);

    if (stock && size.quantity >= stock.qtty_stock) {
      let limit_alert = Array.from(bgSize.children).find((k) =>
        k.classList.contains("limit-alert")
      );
      // let limit_alert =  event.target.closest('.limit-alert');
      limit_alert.classList.remove("hidden");
      setTimeout(() => {
        limit_alert.classList.add("hidden");
      }, 5000);
      return;
    }

    let state = this.state;
    state.sellerSelected.products.forEach((product) => {
      product.variations.forEach((variation) =>
        variation.items.forEach((item) => {
          if (item.id === size.id) item.quantity += 1;
        })
      );
    });
    this.state.cartService.addItem(size.id, size.quantity);
    this.setState({ selectedSeller: state.sellerSelected });

    this.toggleButtonSend(
      totalProductsItens + 1,
      quantity.orderMinimumQuantity,
      quantity.orderMaximumQuantity
    );
  }

  changeQuantity(event, size, quantity, totalUnits, stock) {
    let new_quantity = isNaN(parseInt(event.target.value))
      ? 0
      : event.target.value;

    let bgSize = null;
    if (event.target.closest) {
      bgSize = event.target.closest(".size");
    }

    if (bgSize) {
      bgSize.classList.add("blink");
    }

    if (this.time) {
      clearTimeout(this.time);
    }

    this.time = setTimeout(() => {
      if (bgSize) {
        bgSize.classList.remove("blink");
      }
    }, 200);

    if (
      typeof stock !== "undefined" &&
      stock !== null &&
      parseInt(new_quantity) > stock.qtty_stock
    ) {
      if (bgSize) {
        let limit_alert = Array.from(bgSize.children).find((k) =>
          k.classList.contains("limit-alert")
        );
        limit_alert.classList.remove("hidden");
        setTimeout(() => {
          limit_alert.classList.add("hidden");
        }, 5000);
      }
      return;
    }

    let state = this.state;
    state.sellerSelected.products.forEach((product) => {
      product.variations.forEach((variation) =>
        variation.items.forEach((item) => {
          if (item.id === size.id) item.quantity = parseInt(new_quantity);
        })
      );
    });
    this.state.cartService.addItem(size.id, new_quantity);
    this.setState({ selectedSeller: state.sellerSelected });

    this.toggleButtonSend(
      parseInt(new_quantity),
      quantity.orderMinimumQuantity,
      quantity.orderMaximumQuantity
    );
  }

  decrementQuantity(event, size, quantity, totalProductsItens, stock) {
    let bgSize = event.target.closest(".size");

    bgSize.classList.add("blink");
    if (this.time) {
      clearTimeout(this.time);
    }

    this.time = setTimeout(() => {
      bgSize.classList.remove("blink");
    }, 200);

    let parent = event.target.parentElement.parentElement;
    parent.classList.add("action-success");
    setTimeout(function () {
      parent.classList.remove("action-success");
    }, 2500);
    let state = this.state;
    state.sellerSelected.products.forEach((product) => {
      product.variations.forEach((variation) =>
        variation.items.forEach((item) => {
          if (item.quantity > 0) {
            if (item.id === size.id) {
              item.quantity -= 1;
            }
          }
        })
      );
    });

    this.state.cartService.addItem(size.id, size.quantity);
    this.setState({ selectedSeller: state.sellerSelected });
    this.toggleButtonSend(
      totalProductsItens - 1,
      quantity.orderMinimumQuantity,
      quantity.orderMaximumQuantity
    );
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  showFeedback() {
    this.setState({
      showFeedback: true,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }

  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
    });
  }

  showSizeChart(product) {
    this.setState({
      sizeChartProduct: product,
      showLightBox: true,
    });
  }

  showLightBox() {
    this.setState({
      showLightBox: true,
    });
  }

  closeLightBox() {
    this.setState({
      showLightBox: false,
    });
  }

  handleAutofillClick() {
    this.setState({
      loadingAutofill: true,
    });

    const e = {
      target: {
        value: 1,
      },
    };

    for (const [productIndex, product] of Object.entries(
      this.state.sellerSelected.products
    )) {
      // eslint-disable-next-line
      for (const [variationIndex, variation] of Object.entries(
        product.variations
      )) {
        // eslint-disable-next-line
        for (const [sizeIndex, size] of Object.entries(variation.items)) {
          let stock = variation.info.stock.find(
            (k) => k.size.id === size.size.id
          );

          this.changeQuantity(
            e,
            size,
            product.validationsRules,
            this.totalizersCalc(productIndex).totalUnits,
            stock
          );
        }
      }
    }

    this.setState({
      loadingAutofill: false,
    });
  }

  render() {
    const userData = JSON.parse(localStorage.getItem('userData'));

    return (
      <React.Fragment>
        <MenuComponent />
        {!this.state.isLoading && this.state.cartEmpty && <CartEmpity />}

        {this.state.showFeedback && (
          <FeedBackMsgComponents>
            GREAT! NOW TRACK YOUR PRODUCTS IN{" "}
            <span className="here">
              <Link to="/order/">ORDERS</Link>
            </span>
            .
          </FeedBackMsgComponents>
        )}
        {this.state.showErrorFeedback && (
          <FeedBackMsgComponents
            msg="OOPS! SOMETHING WENT WRONG WITH YOUR REQUEST. CORRECT THE ERRORS BELOW AND TRY AGAIN."
            close={() => this.setState({ showErrorFeedback: false })}
          />
        )}
        <div id="cart">
          <span className="title">Cart</span>
          {!this.state.cartEmpty && (
            <React.Fragment>
              {!isMobile() && (
                <div className="product-brands">
                  {!this.state.isLoading ? (
                    this.state.cartItems.map((item, i) => {
                      return (
                        <div key={i}>
                          {item.products.length > 0 && (
                            <div
                              onClick={() => this.sellerSelect(item.seller.id)}
                              className={`brand ${
                                this.state.sellerSelected.seller.id ===
                                item.seller.id
                                  ? "active"
                                  : null
                              }`}
                            >
                              {item.status === "o" && (
                                <span className="cart-status cart-status-open">
                                  CART OPEN
                                </span>
                              )}
                              {item.status === "c" && (
                                <span className="cart-status cart-status-closed">
                                  CART CLOSED
                                </span>
                              )}
                              <span className="brand-name">
                                {item.seller.name}
                              </span>
                              <span className="brand-item-statistic">
                                <strong>{item.products.length}</strong>{" "}
                                {item.products.length > 1
                                  ? "products"
                                  : "product"}{" "}
                                from{" "}
                                <strong>{item.seller.linesheetsCount}</strong>{" "}
                                {item.seller.linesheetsCount > 1
                                  ? "linesheets"
                                  : "linesheet"}
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SpinnerComponent />
                  )}
                  {this.state.nextPage && (
                    <div className="load-more-container">
                      <ButtonComponent
                        className="load-more"
                        action={() => this.getMoreCarts()}
                      >
                        LOAD MORE
                        {this.state.isLoadingMore && <SpinnerComponent />}
                      </ButtonComponent>
                    </div>
                  )}
                </div>
              )}
              <div className="product-items">
                {!this.state.isLoading && (
                  <div className="header">
                    <span className="title-container">
                      <h2>{this.state.sellerSelected.seller.name}</h2>
                    </span>
                    {this.state.sellerSelected.status !== "c" && (
                      <ButtonComponent
                        className={`send-order-btn ${
                          this.state.sellerSelected.seller.id ===
                          this.state.lastAddedId
                            ? "add-success"
                            : ""
                        }`}
                        disabled={this.state.isLoading}
                        action={() => this.finalizeOrderCart()}
                      >
                        {!this.state.sendOrderLoading ? (
                          "SEND ORDER"
                        ) : (
                          <SpinnerComponent />
                        )}
                      </ButtonComponent>
                    )}
                  </div>
                )}
                {!this.state.isLoading && userData.allow_auto_add_one_unit_cart ? (
                  <div className="autofill-one-unit">
                    <ButtonComponent action={() => this.handleAutofillClick()}>
                      {this.state.loadingAutofill ? (
                        <SpinnerComponent />
                      ) : (
                        "AUTOFILL ONE UNIT"
                      )}
                    </ButtonComponent>
                  </div>
                ) : null}
                {!this.state.isLoading &&
                this.state.sellerSelected.status !== "c"
                  ? this.state.sellerSelected.products.map(
                      (product, productIndex) => {
                        return (
                          <React.Fragment key={product.id}>
                            <div
                              className="product-details-box"
                              id={`prod-${productIndex}`}
                            >
                              <div className="order-details">
                                <div className="product-image">
                                  <img alt="" src={product.image} />
                                  {isMobile() && (
                                    <div
                                      className="remove-cart"
                                      onClick={() =>
                                        this.removeProductFromCart(
                                          product.id,
                                          product.cartId
                                        )
                                      }
                                    >
                                      remove from cart
                                    </div>
                                  )}
                                </div>
                                <div className="product-info">
                                  <div className="product-description">
                                    <Link to={`/product/${product.id}`}>
                                      <div className="product-name">
                                        {product.name}
                                      </div>
                                    </Link>
                                    <Link to={`/product/${product.id}`}>
                                      <div className="product-reference">
                                        REF: {product.referenceCode}
                                      </div>
                                    </Link>
                                    {!isMobile() && (
                                      <div
                                        className="remove-cart"
                                        onClick={() =>
                                          this.removeProductFromCart(
                                            product.id,
                                            product.cartId
                                          )
                                        }
                                      >
                                        remove from cart
                                      </div>
                                    )}
                                  </div>
                                  <div className="product-prices">
                                    <div className="wholesale">
                                      <b>
                                        WHOLESALE {product.wholesalePrice.tip}
                                      </b>{" "}
                                      | {product.wholesalePrice.currency}{" "}
                                      {parseFloat(
                                        product.wholesalePrice.amount
                                      ).toFixed(2)}
                                      <ReactTooltip
                                        className="variation-tooltip"
                                        id={`wholesale_price_${product.id}`}
                                        place="top"
                                        type="light"
                                        effect="solid"
                                      />
                                    </div>
                                    <div className="retail-price">
                                      <b
                                        data-tip={product.retailerPrice.tip}
                                        data-for={`retailer_price_${product.id}`}
                                      >
                                        RETAIL
                                      </b>{" "}
                                      | {product.retailerPrice.currency}{" "}
                                      {parseFloat(
                                        product.retailerPrice.amount
                                      ).toFixed(2)}
                                      <ReactTooltip
                                        className="variation-tooltip"
                                        id={`retailer_price_${product.id}`}
                                        place="top"
                                        type="light"
                                        effect="solid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {product.variations.map(
                                (variation, variationIndex) => {
                                  const variationStyle = GetVariationColorStyle(
                                    variation.info
                                  );
                                  return (
                                    <div
                                      key={variationIndex}
                                      className="product-details"
                                    >
                                      <div className="product-color">
                                        <div
                                          style={variationStyle.li}
                                          className="color-box"
                                        >
                                          <span
                                            className="colorListNoTexture"
                                            style={variationStyle.span}
                                          ></span>
                                        </div>
                                        <div className="color-name">
                                          {variation.info.label}
                                        </div>
                                        <div className="total-units">
                                          units:{" "}
                                          <b>
                                            {this.countVariationTotalUnit(
                                              variation.items
                                            )}
                                          </b>
                                        </div>
                                      </div>
                                      <div className="product-size">
                                        {variation.items.map(
                                          (size, sizeIndex) => {
                                            let stock =
                                              variation.info.stock.find(
                                                (k) =>
                                                  k.size.id === size.size.id
                                              );
                                            return variation.info.stock.length >
                                              0 &&
                                              typeof stock === "undefined" ? (
                                              <></>
                                            ) : (
                                              <div
                                                key={size.size.id}
                                                className="size"
                                              >
                                                {this.state.errorsSizes && this.state.errorsSizes.find(errorSizes => errorSizes.variation_id === variationIndex && errorSizes.size_id === size.size.id) ? 
                                                  <div
                                                    className="limit-alert"
                                                    key={`error-sizes-${variationIndex}-${size.size.id}`}
                                                  >
                                                    {this.state.errorsSizes.find(errorSizes => errorSizes.variation_id === variationIndex && errorSizes.size_id === size.size.id).error}
                                                  </div>
                                                : null}
                                                {stock && stock.qtty_stock < 10 && (
                                                  <div className="_stock_info">
                                                    {stock.qtty_stock} left
                                                    <div className="_cicle">
                                                      !
                                                    </div>
                                                  </div>
                                                )}
                                                <div className="bgSize" />
                                                <div className="size-name">
                                                  {size.size.name}
                                                </div>
                                                <div className="size-quantity">
                                                  <div
                                                    onClick={(e) =>
                                                      this.decrementQuantity(
                                                        e,
                                                        size,
                                                        product.validationsRules,
                                                        this.totalizersCalc(
                                                          productIndex
                                                        ).totalUnits,
                                                        stock
                                                      )
                                                    }
                                                  >
                                                    -
                                                  </div>
                                                  <input
                                                    min="0"
                                                    onChange={(event) =>
                                                      this.changeQuantity(
                                                        event,
                                                        size,
                                                        product.validationsRules,
                                                        this.totalizersCalc(
                                                          productIndex
                                                        ).totalUnits,
                                                        stock
                                                      )
                                                    }
                                                    value={size.quantity}
                                                    type="text"
                                                  />
                                                  <div
                                                    onClick={(e) =>
                                                      this.incrementQuantity(
                                                        e,
                                                        size,
                                                        product.validationsRules,
                                                        this.totalizersCalc(
                                                          productIndex
                                                        ).totalUnits,
                                                        stock
                                                      )
                                                    }
                                                  >
                                                    +
                                                  </div>
                                                </div>
                                                <div className="limit-alert hidden">
                                                  {stock && stock.qtty_stock}{" "}
                                                  units are available for
                                                  immediate delivery
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>

                            <div className="order-tools">
                              {product.sizeChart ? (
                                <div
                                  className="view-size-chart"
                                  onClick={() => this.showSizeChart(product)}
                                >
                                  VIEW SIZE CHART
                                </div>
                              ) : (
                                <div className="no-sizechart"></div>
                              )}
                              <div className="total-value-product">
                                <b>
                                  {this.totalizersCalc(productIndex).totalValue}
                                </b>
                              </div>
                              <div className="unit-quantity">
                                {!isMobile() && (
                                  <>
                                    {this.state.errors
                                      .filter(
                                        (item) => item.index === productIndex
                                      )
                                      .map((item, errIndex) => (
                                        <div
                                          className="box-error"
                                          key={`error-${productIndex}-${errIndex}`}
                                        >
                                          {item.error}
                                        </div>
                                      ))}
                                  </>
                                )}
                                UNITS{" "}
                                <b>
                                  {this.totalizersCalc(productIndex).totalUnits}
                                </b>
                              </div>
                            </div>
                            {isMobile() && (
                              <>
                                {this.state.errors
                                  .filter((item) => item.index === productIndex)
                                  .map((item, errIndex) => (
                                    <div
                                      className="box-error"
                                      key={`error-${productIndex}-${errIndex}`}
                                    >
                                      {item.error}
                                    </div>
                                  ))}
                              </>
                            )}
                          </React.Fragment>
                        );
                      }
                    )
                  : (this.state.sellerSelected.status !== "c" ?
                      <SpinnerComponent />
                    : null)}
                {!this.state.isLoading && (
                  <React.Fragment>
                    <div className="order-resume">
                      <div className="left">
                        {this.state.sellerSelected.status === "c" && (
                          <div>
                            Thank you!
                            <br />
                            Your order has been received and is currently being
                            analysed.
                            <br />
                            You can check its progress in{" "}
                            <span className="highlight-order">
                              <Link
                                to={`/order/${this.state.sellerSelected.orderId}`}
                              >
                                ORDERS
                              </Link>
                            </span>
                            .
                          </div>
                        )}
                        {this.state.sellerSelected.status !== "c" && (
                          <div className="order-note">
                            <div>
                              Add an order <b>note</b>
                            </div>
                            <textarea
                              name="message"
                              value={this.state.message}
                              onChange={this.handleChange.bind(this)}
                              placeholder="Write a message to the brand about your order"
                            />
                          </div>
                        )}
                      </div>
                      <div className="right">
                        <div>
                          <b>Expected to ship</b>
                        </div>
                        <div>
                          {this.state.sellerSelected.products[0].ship_msg}
                        </div>
                        <div className="order-note">
                          <div className="total">
                            {this.state.valueError && (
                              <div className="box-error">
                                {this.state.valueError}
                              </div>
                            )}
                            <b className="uppercase">
                              TOTAL{" "}
                              {this.state.sellerSelected.products.length > 0
                                ? this.state.sellerSelected.products[0]
                                    .wholesalePrice.tip
                                : ""}
                            </b>{" "}
                            |{" "}
                            {this.generalTotalizersCalc().allValues.replace(
                              "US$",
                              "USD"
                            )}
                          </div>
                          <div className="quantity">
                            {this.state.quantityError && (
                              <div className="box-error">
                                {this.state.quantityError}
                              </div>
                            )}
                            <b>UNITS</b> |{" "}
                            <span>
                              {this.generalTotalizersCalc().totalAllUnits}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.sellerSelected.status !== "c" && (
                      <div className="send-box">
                        <ButtonComponent
                          action={() => this.finalizeOrderCart()}
                          disabled={this.state.isLoading}
                          className={`send-order-btn ${
                            this.state.sellerSelected.seller.id ===
                            this.state.lastAddedId
                              ? "add-success"
                              : ""
                          }`}
                        >
                          {!this.state.sendOrderLoading ? (
                            "SEND ORDER"
                          ) : (
                            <SpinnerComponent />
                          )}
                        </ButtonComponent>
                        <span onClick={() => this.removeAllProducts()}>
                          remove all brand's products from cart
                        </span>
                      </div>
                    )}
                    {this.state.sellerSelected.status === "c" && (
                      <div className="send-box">
                        <Link
                          to={`/order/${this.state.sellerSelected.orderId}`}
                        >
                          <button>VIEW ORDER</button>
                        </Link>
                      </div>
                    )}
                    {isMobile() && (
                      <>
                        {this.state.sellerSelected.status !== "c" ? (
                          <div
                            onClick={() => this.setState({ cartList: true })}
                            className="see-all"
                          >
                            See All Carts
                          </div>
                        ) : (
                          <Link to="/order/">
                            <div className="see-all">See All Orders</div>
                          </Link>
                        )}
                        <div
                          className={`cart-list-mobile ${
                            this.state.cartList ? "cart-list-opened" : ""
                          }`}
                        >
                          <div
                            onClick={() => this.setState({ cartList: false })}
                            className="btn-close-cartlist"
                          >
                            X
                          </div>
                          <div className="product-brands">
                            {!this.state.isLoading ? (
                              this.state.cartItems.map((item, i) => {
                                return (
                                  <div key={i}>
                                    {item.products.length > 0 && (
                                      <div
                                        onClick={() =>
                                          this.sellerSelect(item.seller.id)
                                        }
                                        className={`brand ${
                                          this.state.sellerSelected.seller
                                            .id === item.seller.id
                                            ? "active"
                                            : null
                                        }`}
                                      >
                                        {item.status === "o" && (
                                          <span className="cart-status cart-status-open">
                                            CART OPEN
                                          </span>
                                        )}
                                        {item.status === "c" && (
                                          <span className="cart-status cart-status-closed">
                                            CART CLOSED
                                          </span>
                                        )}
                                        <span className="brand-name">
                                          {item.seller.name}
                                        </span>
                                        <span className="brand-item-statistic">
                                          <strong>
                                            {item.products.length}
                                          </strong>{" "}
                                          {item.products.length > 1
                                            ? "products"
                                            : "product"}{" "}
                                          from{" "}
                                          <strong>
                                            {item.seller.linesheetsCount}
                                          </strong>{" "}
                                          {item.seller.linesheetsCount > 1
                                            ? "linesheets"
                                            : "linesheet"}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <SpinnerComponent />
                            )}
                            {this.state.nextPage && (
                              <div className="load-more-container">
                                <ButtonComponent
                                  className="load-more"
                                  action={() => this.getMoreCarts()}
                                >
                                  LOAD MORE
                                  {this.state.isLoadingMore && (
                                    <SpinnerComponent />
                                  )}
                                </ButtonComponent>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>

        {this.state.showLightBox && (
          <ProductSizeChart
            productId={this.state.sizeChartProduct.id}
            sizeChart={this.state.sizeChartProduct.sizeChart}
            show={this.state.showLightBox}
            handleClick={this.closeLightBox.bind(this)}
            productName={this.state.sizeChartProduct.name}
            productReferenceCode={this.state.sizeChartProduct.reference_code}
          />
        )}
        <FooterComponent />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartData: state.cartEntity,
  };
}

export default connect(mapStateToProps)(CartViewComponent);
