import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

class NavBrandCategoriesComponent extends Component {
  constructor(props) {
    super(props);
    this.checkeds = [];
  }

  getChecked(id) {
    const qs = queryString.parse(window.location.search);
    if ("categories" in qs) {
      return (
        "categories" in qs && qs.categories.split(",").includes(id.toString())
      );
    }
    return false;
  }

  getBaseLink() {
    let url = "?";
    const qs = queryString.parse(window.location.search);
    if ("title" in qs) {
      url += "title=" + qs.title + "&";
    }
    if ("segments" in qs) {
      url += "segments=" + qs.segments + "&";
    }
    return url;
  }

  getLink(id) {
    const qs = queryString.parse(window.location.search);
    let url = this.getBaseLink();
    if ("categories" in qs) {
      let categories = qs.categories.split(",");
      if (categories.includes(id.toString())) {
        categories = categories.filter((e) => e !== id.toString());
      } else {
        categories.push(id.toString());
      }
      if (categories.length > 0) {
        url += "categories=" + categories.join(",");
      }
    } else {
      url += "categories=" + id;
    }
    return url;
  }

  handleClick(category) {
      console.log(this.getLink(category.id))
    if (this.getLink(category.id) !== '?') {
      window.localStorage.setItem(
        "brands_last_filter_category_name",
        category.name
      );
      window.localStorage.setItem(
        "brands_last_filter_category_url",
        this.getLink(category.id)
      );
    } else {
      window.localStorage.removeItem("brands_last_filter_category_name");
      window.localStorage.removeItem("brands_last_filter_category_url");
    }
  }

  render() {
    const { categories } = this.props;
    return (
      <>
        <div className="contentFilter checkSeller">
          <p>CATEGORIES</p>

          <ul>
            {categories.map((category, index) => {
              return (
                <li key={index}>
                  <div className="checkbox-group">
                    <Link
                      className="brand-link"
                      to={this.getLink(category.id)}
                      onClick={() => this.handleClick(category)}
                    >
                      <label className="filter-checkbox" htmlFor={category.id}>
                        {" "}
                        {category.name}
                        <input
                          checked={this.getChecked(category.id)}
                          type="checkbox"
                          id={category.id}
                        />
                        <span className="checkmark" />
                      </label>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="clearAllFilter">
          <Link className="clearAllFilter" to={this.getBaseLink()}>
            Clear all filters
          </Link>
        </div>
        <br />
      </>
    );
  }
}

export default NavBrandCategoriesComponent;
