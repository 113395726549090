import React, {Component} from 'react';
import "../style/FilterCollectionsStyle.scss"

import NavBrandsComponentB from "../../shared/navBrands/components/NavBrandsComponentB";
import NavBrandCategoriesComponent from "../../shared/navBrands/components/NavBrandCategoriesComponent";


class FilterBrandsComponent extends Component {

    clearFilter(endpoint) {
        // this.props.clearAllFilter();
        // this.props.getProducts(endpoint);
    }

    render() {

        return (
            <div className="filterCollection">
                <NavBrandCategoriesComponent
                    categories={this.props.categories}
                />

                
                  <NavBrandsComponentB
                      brands={this.props.brands}
                  />
                
            </div>
        );
    }
}

export default FilterBrandsComponent;
