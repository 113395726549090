import React, { Component } from "react";
import ButtonComponent from "../../../shared/button/components/ButtonComponent";
import "../style/SelectCollection.scss";
import "react-id-swiper/lib/styles/scss/swiper.scss";
import axios from "axios";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import { withRouter } from "react-router-dom";

class SelectCollectionBox extends Component {
  state = {
    collections: [],
    loading: true,
    noCollections: false,
  };

  getCollections() {
    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/sellers/${this.props.seller}/short-collections`,
        this.headers
      )
      .then((response) => {
        this.setState({
          collections: response.data,
          loading: false,
        });
        if (response.data.length === 0) {
          this.setState({ noCollections: true });
        }
      });
  }

  componentDidMount() {
    this.getCollections();
  }

  navigateToCollection(collectionId) {
    this.props.history.push(
      `/${this.props.seller}/collections/${collectionId}`
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingComponent />;
    }

    return (
      <div className="select-collection">
        {this.state.collections.map((collection, index) => {
          return (
            <div
              className="collection-box"
              key={index}
              onClick={() => this.navigateToCollection(collection.id)}
            >
              <div className="slider-wrapper">
                <div key={index} className="image-wrapper">
                  <img src={collection.list_image} alt={collection.name} />
                </div>
              </div>
              <div className="collection-name">
                <div className="name-container">
                  <p>{collection.name}</p>
                </div>
                <div className="button-container">
                  <ButtonComponent
                    type="navLink"
                    linkTo={`/${this.props.seller}/collections/${collection.id}`}
                  >
                    {window.localStorage.getItem("user_type") === "buyer" ? (
                      <>{collection.button}</>
                    ) : (
                      <>view collection</>
                    )}
                  </ButtonComponent>
                </div>
              </div>
            </div>
          );
        })}
        {this.state.noCollections && (
          <div className="no-collection">
            THIS BRAND HAS NO
            <br />
            COLLECTION AVAILABLE
            <br />
            AT THIS TIME.
            <p>The new collection is coming soon!</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SelectCollectionBox);
