import React, { Component } from "react";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import "../styles/ConnectionComponent.scss";
import axios from "axios";
import SpinnerComponent from "../../shared/spinner";
import { withRouter } from "react-router";

class ConnectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connection: null,
    };
  }

  componentDidMount() {
    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/connections/${this.props.type}/${this.props.subjectId}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            connection: response.data.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  handleRequestConnection() {
    const userSType = localStorage.getItem("user_stype");

    this.setState({
      loading: true,
    });

    const self = this;

    axios
      .post(
        `${process.env.REACT_APP_NEW_API_URL}/v1/connections/${this.props.type}/${this.props.subjectId}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            connection: response.data.data,
            loading: false,
          });

          if (userSType === "buyer") {
            self.props.history.push(`/messages/${self.props.subjectId}`);
          }
        }
      });
  }

  render() {
    const userSType = localStorage.getItem("user_stype");
    const { history } = this.props;

    return (
      <div className="connection-component">
        {this.state.loading ? (
          <ButtonComponent className="loading-button">
            <SpinnerComponent />
          </ButtonComponent>
        ) : (
          <div>
            {!this.state.connection && (
              <ButtonComponent action={() => this.handleRequestConnection()}>
                {userSType === "buyer" ? "SEND MESSAGE" : "REQUEST CONNECTION"}
              </ButtonComponent>
            )}
            {this.state.connection &&
              this.state.connection.status.status === "accepted" && (
                <ButtonComponent
                  action={() =>
                    history.push(`/messages/${this.props.subjectId}`)
                  }
                >
                  SEND MESSAGE
                </ButtonComponent>
              )}
            {this.state.connection &&
              this.state.connection.status.status === "pending" &&
              this.state.connection.user_stype === userSType && (
                <ButtonComponent
                  action={() => null}
                  className="button--pending"
                >
                  REQUEST PENDING
                </ButtonComponent>
              )}
            {this.state.connection &&
              this.state.connection.status.status === "declined" &&
              userSType === "seller" && (
                <ButtonComponent
                  action={() => null}
                  className="button--pending"
                >
                  REQUEST PENDING
                </ButtonComponent>
              )}
            {this.state.connection &&
              this.state.connection.status.status === "pending" &&
              this.state.connection.user_stype !== userSType && (
                <ButtonComponent action={() => this.handleRequestConnection()}>
                  {userSType === "buyer" ? "SEND MESSAGE" : "ACCEPT CONNECTION"}
                </ButtonComponent>
              )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ConnectionComponent);
