import React from 'react';

const SizesComponent = props => {

    const { sizes, sizechart, handleClick } = props;

    return (
        <div className="containerSizes">

            <p className="titleContainerSizes">
                SIZES
            </p>

            <ul>
                {sizes.map( (size, index) => {
                    return (
                        <li key={index}> {size.name} </li>
                    )
                })}
            </ul>

            { sizechart &&
                <ul>
                    <li onClick={() => handleClick()} className="viewSize">
                        VIEW SIZE CHART
                    </li>
                </ul>
            }
        </div>
    );
};

export default SizesComponent;