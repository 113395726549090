export const GetVariationColorStyle = (variation) => {
    let liStyle   = {};
    let spanStyle = {};
    let classList = "listVariation";

    if(variation.texture !== null) {
        liStyle = {backgroundImage: `url(${variation.texture.picture_texture})`}
        classList += ' texture'
        if (variation.color !== null) {                                                        
            spanStyle={borderBottomColor: variation.color.color, display: 'inline-block'}
        }
    } else {
        classList += ' color'
        liStyle = {backgroundColor: variation.color.color}
        if(variation.color.extra_color) {
            spanStyle={borderBottomColor: variation.color.extra_color, display: 'inline-block'}
        }        
    }
    return {
        'li': liStyle,
        'span': spanStyle,
        'classList': classList
    };
}
