import { isMobile } from "is-mobile";
import React, { Component } from "react";
import OrderService from "../../../services/OrdersService";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import SpinnerComponent from "../../shared/spinner";

class OrderSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      sendingMessage: false,
      orderService: new OrderService(),
    };
  }
  onChangeMessage(event) {
    this.setState({ message: event.target.value });
  }

  sendMessage() {
    if (this.state.message) {
      this.setState({ sendingMessage: true });
      let order = this.props.order;
      this.state.orderService
        .sendMessage(order.id, this.state.message)
        .then((resp) => {
          order.messages.push({
            message: this.state.message,
            created: this.formatDate(new Date()),
          });
          this.setState({ order, message: "", sendingMessage: false });
        });
    }
  }
  formatDate(date) {
    let new_date = new Date(date);
    let day = new_date.getDate();
    let month = new_date.getMonth() + 1;
    let year = new_date.getFullYear();
    return month + "/" + day + "/" + year;
  }
  render() {
    const { order } = this.props;

    return (
      <div className="order-details" id="order-details">
        <React.Fragment>
          <div className="header">
            <h1>{order.seller.name}</h1>
          </div>
          <div className="order-resume">
            <div className="header-resume" id="header-resume">
              <div>
                <div>
                  <h4>Hello <span className="buyer-name">{order.buyer.name}</span>!</h4>
                  <h4>
                    this is your <b>PURCHASE ORDER #{order.id}</b>
                  </h4>
                  <div className="border" />
                </div>
              </div>
              <div className="order-values">
                <div>
                  {order.tracking_code && (
                    <div>
                      <b>Tracking code</b> | {order.tracking_code}
                    </div>
                  )}
                  {order.shipping && (
                    <div>
                      <b>Shipping</b> | {order.shipping}
                      {order.showShipping && (
                        <span ng-show="order.showShipping">
                          <b>
                            Rate: {order.currency} {order.shipping.rate} -{" "}
                            {order.shipping.time} days
                          </b>
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <h2>PURCHASE ORDER</h2>
                <div className="total">
                  <div>
                    <b>TOTAL</b> |{" "}
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: order.currency,
                    })
                      .format(order.total_price)
                      .replace("US$", "USD")}
                  </div>
                  <div>
                    <b>UNITS</b> | {order.total_items}
                  </div>
                </div>
              </div>
              <hr />
              {order.status !== "b" && order.status !== "s" && (
                <div className="order-status">
                  <div className="status-container">
                    <div className="circle">
                      <div className="inner">
                        <span>01</span>
                      </div>
                    </div>
                    <div className="description">Accepted</div>
                  </div>
                  <div className="order-separator"></div>
                  <div className="status-container">
                    <div
                      className={`circle ${
                        ["b", "r", "s"].indexOf(order.status) !== -1
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <div
                        className={`inner ${
                          ["b", "r", "s"].indexOf(order.status) !== -1
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <span
                          className={`${
                            ["b", "r", "s"].indexOf(order.status) !== -1
                              ? "disabled"
                              : ""
                          }`}
                        >
                          02
                        </span>
                      </div>
                    </div>
                    <div className="description">Processing</div>
                  </div>
                  <div
                    className={`order-separator ${
                      ["b", "s", "r", "p"].indexOf(order.status) !== -1
                        ? "disabled"
                        : ""
                    }`}
                  ></div>
                  <div className="status-container">
                    <div
                      className={`circle ${
                        ["b", "s", "r", "p"].indexOf(order.status) !== -1
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <div
                        className={`inner ${
                          ["b", "s", "r", "p"].indexOf(order.status) !== -1
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <span
                          className={`${
                            ["b", "s", "r", "p"].indexOf(order.status) !== -1
                              ? "disabled"
                              : ""
                          }`}
                        >
                          03
                        </span>
                      </div>
                    </div>
                    <div className="description">In transit</div>
                  </div>
                  <div
                    className={`order-separator ${
                      ["b", "s", "r", "p", "i"].indexOf(order.status) !== -1
                        ? "disabled"
                        : ""
                    }`}
                  ></div>
                  <div className="status-container">
                    <div
                      className={`circle ${
                        ["b", "s", "r", "p", "i"].indexOf(order.status) !== -1
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <div
                        className={`inner ${
                          ["b", "s", "r", "p", "i"].indexOf(order.status) !== -1
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <span
                          className={`${
                            ["b", "s", "r", "p", "i"].indexOf(order.status) !==
                            -1
                              ? "disabled"
                              : ""
                          }`}
                        >
                          04
                        </span>
                      </div>
                    </div>
                    <div className="description">Finished</div>
                  </div>
                </div>
              )}
              <div className="order-note-wrapper">
                <div className="order-note">ORDER NOTE</div>
                <p>{order.note}</p>
              </div>
              <div className="days-to-ship">
                {
                  <a
                    href={`${process.env.REACT_APP_API_URL}/api/order/${order.id}/xlsx/`}
                    className="button order-button"
                  >
                    DOWNLOAD PO (.XLS)
                  </a>
                }
                {
                  <a
                    href={`${process.env.REACT_APP_API_URL}/api/order/${order.id}/pdf/`}
                    className="button order-button"
                  >
                    DOWNLOAD PO (.PDF)
                  </a>
                }
              </div>
            </div>
            {!isMobile() && (
              <>
                <div className="messages-box">
                  <h3 className="title">MESSAGES WITH {order.seller.name}</h3>

                  {order.messages.map((message, index) => (
                    <div key={index} className="message">
                      <h3 className="buyer-name">{order.buyer.name}</h3>
                      <h4>{this.formatDate(message.created)}</h4>
                      <p>{message.message}</p>
                    </div>
                  ))}
                </div>
                <div className="message-control">
                  <input
                    onChange={(event) => this.onChangeMessage(event)}
                    value={this.state.message}
                    type="text"
                  />
                  {!this.state.sendingMessage ? (
                    <ButtonComponent action={() => this.sendMessage()}>
                      SEND
                    </ButtonComponent>
                  ) : (
                    <ButtonComponent className="sending">
                      <SpinnerComponent />
                    </ButtonComponent>
                  )}
                </div>
              </>
            )}
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default OrderSummary;
