import React, { Component } from "react";
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinnerComponent from "../../spinner";
import "../style/RegisterModal.scss";
import LightboxHalfScreenComponent from "../../lightboxHalfScreen/components/LightboxHalfScreenComponent";
import axios from "axios";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import mixpanel from "mixpanel-browser";

class RegisterModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyType: props.companyType ? props.companyType : "",
      companyName: "",
      companyWebsite: "",
      yearEstablished: "",
      location: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      defaultOption: "",
      locationFields: [{ glocations: null }],
      locations: [],
      registerSuccess: false,
      emailAlreadyInUser: false,
      emailAlreadyInPreOrder: false,
      isLoading: false,
      companyWebSiteUrl: false,
      msg: "",
      fieldError: false,
      eventSegments: false,
      eventSegmentsSelected: [],
      available_for_mailing: false,
    };
  }

  componentDidMount() {
    this.fetchEventSegments();
  }

  addLocationField() {
    let state = this.state;
    state.locationFields.push({ glocations: null });

    this.setState({
      locationFields: state.locationFields,
    });
  }

  fetchEventSegments() {
    this.setState({
      isLoading: true,
    });

    axios
      .get(`${process.env.REACT_APP_NEW_API_URL}/v1/event-segment`)
      .then((response) => {
        let items = response.data.results;

        items = items.filter((item) => item.is_active_event);

        let path = this.props.location.pathname;
        path = path.split("/");

        let eventSegment = "";

        if (localStorage.getItem("defaultEvent")) {
          eventSegment = localStorage.getItem("defaultEvent");
          eventSegment = eventSegment.split("/");
        }

        if (path.length > 0) {
          let eventosFiltrados = items.filter(
            (item) => item.slug === path[1] || item.slug === eventSegment[1]
          );

          if (eventosFiltrados.length >= 1) {
            items = eventosFiltrados;
            return this.setState({
              defaultOption: items.map((item) => {
                return { value: item.id, label: item.name };
              })[0],
              eventSegmentsSelected: items.map((item) => item.id),
              eventSegments: items,
              isLoading: false,
            });
          }
        }

        this.setState({
          eventSegments: items,
          isLoading: false,
        });
      });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  setCompanyLocation(place, index) {
    let state = this.state;
    let size = place.address_components.length;
    state.locationFields[index].glocations = place.formatted_address;
    state.locations.push({
      locations: String(place.address_components[size - 1].long_name),
    });

    this.setState({
      locationFields: state.locationFields,
    });
  }

  removeLocation(index) {
    let state = this.state;
    state.locationFields.splice(index, 1);
    this.setState({
      locationFields: state.locationFields,
    });
  }

  setFieldError(message) {
    this.setState(
      {
        fieldError: this.props.t(message),
      },
      () => {
        document.querySelector(".lh-modal-body").scroll({
          left: 0,
          top: document.querySelector(".lh-modal-body").scrollHeight,
          behavior: "smooth",
        });
      }
    );
  }

  handleChangeSelectEvent(value) {
    this.setState({
      eventSegmentsSelected: value.map((item) => item.value),
    });
  }

  selectOptions(items) {
    let options = [];

    options = items.map((item) => {
      return {
        value: item.id,
        label: item.name,
        isOptionSelected: item.id === this.state.defaultOption,
      };
    });

    return options;
  }

  pre(event) {
    event.preventDefault();

    let state = this.state;

    if (!state.companyType) {
      this.setFieldError("Company type is invalid");
      return;
    }

    if (!state.companyName) {
      this.setFieldError("Company name is invalid");
      return;
    }

    if (!state.companyWebsite) {
      this.setFieldError("Company website is invalid");
      return;
    }

    if (!state.yearEstablished) {
      this.setFieldError("Year Established is invalid");
      return;
    }

    if (isNaN(state.yearEstablished)) {
      this.setFieldError("Year Established must be numeric");
      return;
    }

    if (!state.firstName) {
      this.setFieldError("First Name is invalid");
      return;
    }

    if (!state.lastName) {
      this.setFieldError("Last Name is invalid");
      return;
    }

    if (!state.phone) {
      this.setFieldError("Phone is invalid");
      return;
    }

    if (!state.email) {
      this.setFieldError("Email is invalid");
      return;
    }

    if (state.locations.length < 1) {
      this.setFieldError("Error: Location of Business Operation is required");
      return;
    }

    const formData = new FormData();
    formData.append('type', state.companyType);
    formData.append('company_name', state.companyName);
    formData.append('company_website', state.companyWebsite);
    formData.append('year_established', state.yearEstablished);
    formData.append('first_name', state.firstName);
    formData.append('last_name', state.lastName);
    formData.append('email', state.email);
    formData.append('phone', state.phone);
    formData.append('available_for_mailing', state.available_for_mailing ? 1 : 0);

    if (state.eventSegmentsSelected) {
        state.eventSegmentsSelected.forEach(element => {
            formData.append('event_segments[]', element)
        });
    }
    
    if (state.locations) {
        state.locations.forEach(element => {
            formData.append('locations[]', element.locations)
        });
    }
    
    if (state.locationFields) {
        state.locationFields.forEach(element => {
            if (element.glocations) {
                formData.append('glocations[]', element.glocations)
            }
        });
    }

    this.setState({
      fieldError: false,
      isLoading: true,
    });

    axios
      .post(
        `${process.env.REACT_APP_NEW_API_URL}/v1/preregistration`,
        formData
      )
      .then((response) => {
        mixpanel.identify(`${response.data.id}`);
        mixpanel.track("Sign up");

        this.setState({
          registerSuccess: true,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (
          error.response.data.email &&
          error.response.data.email[0] ===
          "user with this email already exists."
        ) {
          this.setState({
            emailAlreadyInUser: true,
            emailAlreadyInPreOrder: false,
            isLoading: false,
          });

          return;
        } else if (
          error.response.data.email &&
          error.response.data.email[0] ===
          "pré-cadastro com este email já existe."
        ) {
          this.setState({
            emailAlreadyInPreOrder: true,
            isLoading: false,
          });

          return;
        } else {
          this.setState({
            emailAlreadyInUser: false,
            emailAlreadyInPreOrder: false,
            isLoading: false,
          });
        }

        if (
          error.response.data.company_website &&
          error.response.data.company_website[0]
        ) {
          this.setState({
            companyWebSiteUrl: true,
            isLoading: false,
          });

          return;
        } else {
          this.setState({
            companyWebSiteUrl: false,
            isLoading: false,
          });
        }

        this.setState({
          fieldError: error.response.data[Object.keys(error.response.data)[0]],
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <LightboxHalfScreenComponent
        extraClass="register-lh-modal"
        close={this.props.close}
      >
        <div id="register-modal">
          {!this.state.registerSuccess && (
            <div className="title-and-description">
              <h2>{this.props.t("LET'S START YOUR REGISTRATION")}</h2>
              <div className="description">
                {this.state.companyType === "b" &&
                  this.props.t("register_b_subtitle")}
                {this.state.companyType === "s" &&
                  this.props.t("register_s_subtitle")}
              </div>
            </div>
          )}
          <div className="registration-title-box">
            {this.state.registerSuccess ? (
              <p> {this.props.t("THANKS FOR YOUR REGISTRATION")} </p>
            ) : (
              <span className="subtitle">{this.props.t("COMPANY INFO")}</span>
            )}
          </div>
          <form onSubmit={this.pre.bind(this)}>
            <div className="container">
              {!this.state.registerSuccess && (
                <React.Fragment>
                  <div className="company-info">
                    <select
                      value={this.state.companyType}
                      name="companyType"
                      onChange={(event) => this.handleChange(event)}
                    >
                      <option value="">{this.props.t("Who are you?")}</option>
                      <option value="b">{this.props.t("Retailer")}</option>
                      <option value="s">{this.props.t("Brand")}</option>
                    </select>
                    <input
                      disabled={!this.state.companyType}
                      name="companyName"
                      value={this.state.companyName}
                      onChange={this.handleChange.bind(this)}
                      type="text"
                      placeholder={this.props.t("Company Name")}
                    />
                    <input
                      disabled={!this.state.companyType}
                      name="companyWebsite"
                      value={this.state.companyWebsite}
                      onChange={this.handleChange.bind(this)}
                      type="text"
                      placeholder={this.props.t("Company Website")}
                    />
                    <input
                      disabled={!this.state.companyType}
                      name="yearEstablished"
                      value={this.state.yearEstablished}
                      onChange={this.handleChange.bind(this)}
                      type="text"
                      placeholder={this.props.t("Year Established")}
                    />
                    {this.state.locationFields.map((item, index) => {
                      return (
                        <div key={index} className="auto-complete-group">
                          <Autocomplete
                            key={index}
                            style={{ width: "100%" }}
                            onPlaceSelected={(place) => {
                              this.setCompanyLocation(place, index);
                            }}
                            types={["(regions)"]}
                            placeholder={this.props.t(
                              "Location of Business Operations"
                            )}
                          />
                          {index !== 0 && (
                            <FontAwesomeIcon
                              onClick={() => this.removeLocation(index)}
                              icon="times-circle"
                            />
                          )}
                        </div>
                      );
                    })}
                    {this.state.companyType === "b" && (
                      <div
                        onClick={() => this.addLocationField()}
                        className="add-location"
                      >
                        <FontAwesomeIcon icon="plus-circle" />
                        <span>{this.props.t("add more locations")}</span>
                      </div>
                    )}
                  </div>
                  <div className="contact-info">
                    <span className="subtitle">
                      {this.props.t("CONTACT INFO")}
                    </span>
                    <input
                      disabled={!this.state.companyType}
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange.bind(this)}
                      type="text"
                      placeholder={this.props.t("First Name")}
                    />
                    <input
                      disabled={!this.state.companyType}
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange.bind(this)}
                      type="text"
                      placeholder={this.props.t("Last Name")}
                    />
                    <input
                      disabled={!this.state.companyType}
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleChange.bind(this)}
                      type="text"
                      placeholder={this.props.t("Phone")}
                    />
                    <input
                      disabled={!this.state.companyType}
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange.bind(this)}
                      type="email"
                      placeholder={this.props.t("Email")}
                    />
                    {this.state.eventSegments &&
                      this.state.eventSegments.length > 0 &&
                      !this.state.loading && (
                        <span className="subtitle">
                          {this.props.t("ATTENDING AN EVENT")}?
                        </span>
                      )}
                    {this.state.eventSegments &&
                      this.state.eventSegments.length > 0 &&
                      !this.state.loading && (
                        <Select
                          options={this.selectOptions(this.state.eventSegments)}
                          isMulti
                          placeholder={this.props.t("Select an Event")}
                          styles={{
                            control: (control) => ({
                              ...control,
                              minHeight: "32px",
                              fontSize: "14px",
                              borderSize: "2px",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#dddddd",
                              primary: "#c0c0c0",
                            },
                          })}
                          classNamePrefix="react-select"
                          value={this.state.defaultOption || undefined}
                          isDisabled={this.state.defaultOption ? true : false}
                          onChange={(value) =>
                            this.handleChangeSelectEvent(value)
                          }
                        />
                      )}
                  </div>
                  <div className="checkbox-group">
                    <label className="receive-news-checkbox">
                      {this.props.t("Receive news and updates from us")}
                      <input
                        type="checkbox"
                        name="addEmailToList"
                        onClick={() =>
                          this.setState({
                            available_for_mailing:
                              !this.state.available_for_mailing,
                          })
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <button
                    disabled={!this.state.companyType}
                    className="register-button"
                    type="submit"
                  >
                    {this.state.isLoading ? (
                      <SpinnerComponent />
                    ) : (
                      this.props.t("SEND")
                    )}
                  </button>

                  <div id="error-box" className="email-box">
                    {this.state.emailAlreadyInPreOrder && (
                      <p className="fieldError">
                        {this.props.t("emailAlreadyInPreOrder")}
                      </p>
                    )}

                    {this.state.emailAlreadyInUser && (
                      <p className="fieldError">
                        {this.props.t("emailAlreadyInUser")}
                      </p>
                    )}

                    {this.state.companyWebSiteUrl && (
                      <p className="fieldError">
                        {this.props.t("COMPANY WEBSITE INVALID")}
                      </p>
                    )}

                    {this.state.fieldError && (
                      <p className="fieldError">
                        Error: {this.state.fieldError}
                      </p>
                    )}
                  </div>
                </React.Fragment>
              )}
              {this.state.registerSuccess && (
                <div className="success-text">
                  <span>
                    {this.props.t("Check your email for more information")}
                  </span>
                  <button
                    className="register-button"
                    onClick={() => this.props.close()}
                  >
                    {this.props.t("GO BACK")}
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </LightboxHalfScreenComponent>
    );
  }
}

export default withTranslation()(withRouter(RegisterModalComponent));
