import React, {Component} from 'react';
import './style/HighlightA.scss';
import { withTranslation } from 'react-i18next';

class HighlightA extends Component {

    componentDidMount() {
        window.addEventListener("scroll", function(e) {
            let element = e.target.scrollingElement;
            let tree = document.querySelector('.leftTreeContainer');

            let blanc = document.querySelector(".logoAndText img");
            let text = document.querySelector(".logoAndText p");
            let button = document.querySelector(".buttons");

            let minTop = 120;

            if(tree){
              // FOLHA
              tree.style.bottom = (-870 + element.scrollTop - minTop)+"px";
              if (element.scrollTop < minTop ) tree.style.bottom = "-870px";
              if (element.scrollTop > 600) tree.style.bottom = "-397px";

              // BLANC
              blanc.style.opacity = (100 - ((element.scrollTop - 10) / 2)) / 100;
              if (element.scrollTop < 10) blanc.style.opacity = 1;
              if (element.scrollTop > 210) blanc.style.opacity = 0;

              // TEXT
              text.style.opacity = (100 - ((element.scrollTop - 150) / 2)) / 100;
              if (element.scrollTop < 150) text.style.opacity = 1;
              if (element.scrollTop > 350) text.style.opacity = 0;

              // BUTTON
              button.style.opacity = (100 - ((element.scrollTop - 250) / 2)) / 100;
              if (element.scrollTop < 250) button.style.opacity = 1;
              if (element.scrollTop > 450) button.style.opacity = 0;
            }


        });
    }

    render() {
        return (
            <section id="HeaderHighLightA">
                <div className="texture"
                     style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bg-texture-01.png)`}}/>

                <div className="logoAndText">
                    <img src={this.props.data.logo} alt=""/>
                    <p>{this.props.data.text}</p>
                    <div className="buttons">
                        <button onClick={() => this.props.openRegister('', this.props.slug)} className="link register">{this.props.t('Register')}
                        </button>
                        <button className="link login" onClick={() => this.props.open(this.props.slug)}> {this.props.t('Login')}</button>
                    </div>
                </div>

                <div className="headerImages">
                    <div className="girl">
                        <img src={this.props.data.image} alt="Girl"/>
                    </div>

                    <div className="trees">
                        <div className="tree-one"
                             style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bananeira-big.png)`}}/>
                        <div className="tree-two"
                             style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bananeira-small-1.png)`}}/>
                    </div>
                </div>

                <div className="leftTreeContainer" onScroll={this.handleScroll}>
                    <div className="leftTreeBox">
                        <div className="leftTree"
                             style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bananeira-small-2.png)`}}/>
                    </div>
                </div>

            </section>
        );
    }
}

export default withTranslation()(HighlightA);
