import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from "./data/store"
import RouterSwitchComponent from "./presentation/views/components/RouterSwitchComponent";
import Analytics from "./presentation/analytics/Analytics";
import i18n from "./i18n";
import { Helmet } from "react-helmet";
import mixpanel from "mixpanel-browser";

if (process.env.REACT_APP_LANGUAGE) {
    i18n.changeLanguage(process.env.REACT_APP_LANGUAGE);
}

window.mixpanel = mixpanel;
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Analytics />
            <Helmet>
                {window.localStorage.getItem("user") && (
                    <script>
                        {`let USER_ID = "${JSON.parse(window.localStorage.getItem("userData")).id
                            }";
                    mixpanel.identify(USER_ID);`}
                    </script>
                )}
            </Helmet>
            <Route component={RouterSwitchComponent} />
        </BrowserRouter>
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();
