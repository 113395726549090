import React, {Component} from 'react';
import "./style/HighlightB.scss";
// import {Parallax, Background} from 'react-parallax';
import { withTranslation } from 'react-i18next';


class HighlightB extends Component {

    constructor(props) {
      super(props);
      this.state = {
        openMenu : false,
        showRegister: false
      };
    }

    showRegister() {
        this.setState({
            showRegister: true
        })
    }

    render() {
        return (

            <div>
                {/*<section id="SecondHeader">
                <div className="texture2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bg-texture-02.png)`}} />

                <Parallax
                    blur={-10}
                    bgImage={require(`./girl.jpg`)}
                    bgImageAlt="teste"
                    strength={500}
                >
                    <div style={{height: '700px'}} className="mask-img object-mask" />
                </Parallax>

                <Parallax
                    blur={-10}
                    bgImage={require(`./palmeiras.svg`)}
                    bgImageAlt="teste"
                    strength={500}
                >
                    <div style={{height: '700px'}} className="mask-img object-mask" />
                </Parallax>
            </section>*/}

                <section id="SecondHeader">
                    <div className="texture2"
                         style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bg-texture-02.png)`}}/>

                    <div className="logoAndText">
                        <div>
                            <img src={this.props.data.logo} alt=""/>
                            <p>{this.props.data.text}</p>
                            <div className="buttons">
                                <button onClick={() => this.showRegister()}
                                        className="link register">{this.props.t('Register')}
                                </button>
                                <button className="link login" onClick={() => this.props.open(this.props.slug)}> {this.props.t('Login')}</button>
                            </div>
                        </div>
                    </div>

                    <div className="secondHeaderImages">
                        <div className="girl2 mask-img object-mask"
                             style={{backgroundImage: this.props.data.image}}/>
                    </div>

                </section>


            </div>
        );
    }
}

export default withTranslation()(HighlightB);
