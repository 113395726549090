import React, { Component } from "react";
import moment from "moment";
import "../../styles/MessagesView/MessageChatBlockComponent.scss";
import SpinnerComponent from "../../../shared/spinner/index";
import axios from "axios";

class MessageChatBlockComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: props.message,
      messageFlagLoading: false,
    };
  }

  formatTime() {
    const { message } = this.props;
    return moment(message.created_at).format("hh:mm A");
  }

  renderFile() {
    const { file } = this.props.message;

    if (this.isImage(file)) {
      return <img src={file} className="image-file" alt="attachment" />;
    }

    return (
      <button className="button button--anexo">Download attachment</button>
    );
  }

  isImage(file) {
    return file.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  replaceNl2Br(str, is_xhtml = false) {
    var breakTag =
      is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + breakTag + "$2"
    );
  }

  handleChangeFlagMessage(message) {
    this.setState({
      messageFlagLoading: true,
    });

    axios
      .post(
        `${process.env.REACT_APP_NEW_API_URL}/v1/inbox-messages/message/${message.id}/flag`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        const messageFlag = { ...message };
        messageFlag.flagged = !messageFlag.flagged;

        this.setState({
          message: messageFlag,
          messageFlagLoading: false,
        });
      })
      .catch(() => {
        alert("An error occured!");
        this.setState({
          messageFlagLoading: false,
        });
      });
  }

  render() {
    const userStype = localStorage.getItem("user_stype");
    const { message } = this.state;

    return (
      <div
        className={`messages-chat-block-component ${
          message.user_stype_from === userStype
            ? "messages-chat-block-component--right"
            : "messages-chat-block-component--left"
        }`}
        id={`message-${message.id}`}
      >
        <div className="time">{this.formatTime()}</div>
        <div
          className={`message ${message.flagged ? "message--flagged" : ""} `}
          onClick={() => this.handleChangeFlagMessage(message)}
        >
          {this.state.messageFlagLoading ? (
            <SpinnerComponent />
          ) : (
            <img src="/images/messages/flag.svg" className="flag" alt="flag" />
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: this.replaceNl2Br(message.message),
            }}
          />
          {message.file && (
            <div className="anexo" onClick={() => window.open(message.file)}>
              {this.renderFile()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MessageChatBlockComponent;
