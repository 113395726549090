import React, { Component } from "react";
import TermsAndPrivacyPolicyComponent from "../../termsandprivacypolicy/components/TermsAndPrivacyPolicyComponent";
import MenuFooterComponent from "../../menufooter/components/MenuFooterComponent";
import "../style/FooterStyle.scss";
import { withRouter } from "react-router";

class FooterComponent extends Component {
  constructor(props) {
    super(props);
    this.firstMenu = [
      {
        title: "Our Platform",
        isAnchor: true,
        link: "/#ourplatform",
      },
      {
        title: "Our Brands",
        isAnchor: true,
        link: "/#ourbrands",
      },
      {
        title: "Our Retailers",
        isAnchor: true,
        link: "/#ourretailers",
      },
      {
        title: "About BLANC*",
        isAction: window.location.pathname === "/whoweare",
        action: "#",
        link: "/whoweare",
      },
      {
        title: "Register",
        link: "/#register",
        isOpen: true,
        onClick: (e) => {
          e.preventDefault();
          return this.props.openRegister();
        },
      },
    ];
    this.secondMenu = [
      { title: "Get in touch", email: "hello@blancfashion.com", isEmail: true },
      {
        title: "Facebook",
        blank: true,
        link: "https://www.facebook.com/blancfashionbr/",
      },
      {
        title: "Instagram",
        blank: true,
        link: "https://www.instagram.com/blancfashionbr/",
      },
      {
        title: "Linkedin",
        blank: true,
        link: "https://www.linkedin.com/company/blanc-fashion/",
      },
    ];
    this.lastMenu = [
      { title: "Who We Are", link: "/whoweare" },
      { title: "Our Team", link: "/whoweare#ourteam" },
    ];

    this.state = {
      TermsAndConditions: "",
      privacyPolicy: "",
    };
  }

  render() {
    return (
      <footer>
        <div className="centerComponent contentFooter">
          {(this.props.hideInfos || window.localStorage.getItem("user")) && <TermsAndPrivacyPolicyComponent float={true} />}
          <div className="infos">
            {!this.props.hideInfos && !window.localStorage.getItem("user") && (
              <div className="menuFooter">
                <MenuFooterComponent
                  header={"COMPANY"}
                  navItens={this.firstMenu}
                />
                <MenuFooterComponent
                  header={"CONNECT"}
                  navItens={this.secondMenu}
                />
                <div id="MenuFooterComponent"></div>
              </div>
            )}
            <div className="logo">
              <img
                src={`${process.env.PUBLIC_URL}/images/BLANC_Symbol_WHITE_RGB.svg`}
                alt="logo"
              />
            </div>
          </div>
          {!this.props.hideInfos && !window.localStorage.getItem("user") && <TermsAndPrivacyPolicyComponent />}
          <div className="copyright">
            ©2021 BLANC* Fashion. All Rights Reserved
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(FooterComponent);
