import React from 'react';
import ReactTooltip from 'react-tooltip'

const PricesProductComponent = (props) => {

    const { prices } = props;

    return (
        <div className="pricesProducts">
            <p>
                <span data-tip={prices.wholesale_price_tip} data-for={`wholesale_price_${prices.id}`} >WHOLESALE | </span>
                <ReactTooltip className="variation-tooltip" id={`wholesale_price_${prices.id}`} place="top" type="light" effect="solid"/>
                {prices.wholesale_price.currency} {prices.wholesale_price.amount.replace(/^([0-9]+)\.([0-9]{2}).*/, '$1.$2')}
            </p>

            <p>
                <span data-tip={prices.retailer_price_tip} data-for={`retailer_price_${prices.id}`} >SUGGESTED RETAIL | </span>
                <ReactTooltip className="variation-tooltip" id={`retailer_price_${prices.id}`} place="top" type="light" effect="solid"/>
                {prices.retailer_price.currency} {prices.retailer_price.amount.replace(/^([0-9]+)\.([0-9]{2}).*/, '$1.$2')}
            </p>

            <p>
                <span>EXPECTED TO SHIP : </span> { prices.ship_msg }
            </p>
        </div>
    );
};

export default PricesProductComponent;
