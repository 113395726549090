import React, {Component} from 'react';
import "../styles/FeedBackMsgComponents.scss"
import {Link} from "react-router-dom";

class FeedBackMsgComponents extends Component {


    render() {
      let link;
      if (this.props.link) {
        link = <Link to={this.props.link} className="button">GOT IT</Link>;
      } else if(this.props.close) {
        link = <button onClick={this.props.close} className="button">GOT IT</button>;
      }else{
        link = <Link to="/cart" className="button">GOT IT</Link>;
      }
        return (
            <div>
              <div className="containerFeedBack">
                <div class="text">
                  {this.props.children &&
                    this.props.children
                  }
                  <div dangerouslySetInnerHTML={{ __html: this.props.msg }} />
                </div>
                {link}
              </div>
            </div>
        );
    }
}

export default FeedBackMsgComponents;
