import React from "react";
import Button from '../../button/components/ButtonComponent';
import axios from "axios";
import { withTranslation } from 'react-i18next';

import "../style/Subscribe.scss";

class Subscribe extends React.Component {
  constructor(props) {
     super(props);
     this.state = {
        email: '',
        success: false,
        error: false,
      };

     this.handleChange = this.handleChange.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
  }

 handleChange(event) {
     this.setState({email: event.target.value});
   }
  handleSubmit(){

    axios.post(`api/maillist/`, {
      email: this.state.email,
    })
    .then((response) => {
      this.setState({success: true, error: false});
    })
    .catch((error) => {
      this.setState({error: true});
    });
  }

  render() {
    return (
      <section id="subscribe">
      {!this.state.success ? (
        <>
              <p className="description">
                  {this.props.text}
              </p>

              <div className="form">
                  <input className="subscribeInput" value={this.state.email} onChange={this.handleChange} placeholder={this.props.t('ENTER YOUR EMAIL ADDRESS')} />
                  {this.state.error &&
                    <span className="error-message">{this.props.t('Email required')}</span>
                  }
                  <Button onClick={this.handleSubmit} size="large" text={this.props.t('SUBMIT')} />
              </div>
            </>
             ):(
              <div className="success-message">
                {this.props.t('THANK YOU FOR SUBSCRIBING')}!
                <div className="small-message">{this.props.t('We will be in touch')}.</div>
              </div>
            )
          }
      </section>
    )
  }
}

export default withTranslation()(Subscribe);