import React, {Component} from 'react';
import "../style/FilterCollectionsStyle.scss"

import NavCategoryComponent from "../../shared/navcategory/components/NavCategoryComponent";
import NavSegmentComponent from "../../shared/navsegment/components/NavSegmentComponent";
import NavBrandsComponent from "../../shared/navBrands/components/NavBrandsComponent";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import { isMobile } from 'is-mobile';


class FilterCollectionsComponent extends Component {

    clearFilter(endpoint) {
        this.props.clearAllFilter();
        this.props.getProducts(endpoint);
    }

    render() {
        const {meta, isBrand, collections} = this.props;
        const filters = this.props.getFilters();

        return (
            <div className="filterCollection">
            {!isMobile() &&
              <>
              {meta.selected_segment.name && !meta.selected_collection.seller  &&
                <div className="nameAndBrands">
                    Products
                    | {meta.selected_segment.name}
                </div>
              }
              {meta.selected_seller.name && !meta.selected_collection.seller &&
                <div className="nameAndBrands">
                  {meta.selected_seller.name}
                </div>
              }
              {meta.selected_collection.seller &&
                <>
                  <div className="nameAndBrands">
                      {`${meta.selected_collection.seller.name}
                      | ${meta.selected_collection.name}`}
                  </div>
                  <div className="order styleBorder">
                  { (meta.selected_collection.order_window_end) &&
                    <div><span className="emph">Order window ending on:</span> { meta.selected_collection.order_window_end}</div>
                  }
                  { (meta.selected_collection.production_window_start) && meta.selected_collection.production_window_end &&
                    <div><span className="emph">Expected to ship:</span> { meta.selected_collection.production_window_start} to { meta.selected_collection.production_window_end}</div>
                  }
                  <div>{meta.selected_collection.delivery_msg}</div>
                  </div>
                  </>
                }
              </>
            }

                {this.props.isSegment &&
                <NavSegmentComponent
                        clearFilters={this.clearFilter.bind(this)}
                        segments={meta.segments}
                        setCurrentPageOne={this.props.setCurrentPageOne.bind(this)}
                        endPoint={this.props.endPoint}
                />
                }

                <NavCategoryComponent
                    categories={meta.categories}
                    setFilters={this.props.setFilters}
                    getFilters={this.props.getFilters}
                    setCurrentPageOne={this.props.setCurrentPageOne.bind(this)}
                />

                { isBrand &&
                    <NavBrandsComponent
                        brands={meta.sellers}
                        setFilters={this.props.setFilters}
                        getFilters={this.props.getFilters}
                        setCurrentPageOne={this.props.setCurrentPageOne.bind(this)}
                    />
                }
                {(meta.selected_seller.slug && filters.sellers && collections.length > 0) &&
                  <div className="contentFilter">
                      <p>Linesheets</p>
                      <ul>

                        {collections.map( (item, index) => {
                            return (
                                  <li key={index} onClick={(ev) => this.clearFilter(`?collections=${item.id}&sellers=${item.seller.slug}`)}>
                                      {item.name}
                                  </li>
                            )
                        })}
                      </ul>
                  </div>
                }
                <div className="clearAllFilter" onClick={() => this.clearFilter()}>
                    Clear all filters
                </div>
                {(meta.selected_seller.slug && filters.sellers) &&
                  <div id="brandPage">
                      <Link to={`/${meta.selected_seller.slug}`}>BRAND PAGE</Link>
                  </div>
                }
            </div>
        );
    }
}
FilterCollectionsComponent.propTypes = {
  getFilters: PropTypes.func.isRequired
}

export default FilterCollectionsComponent;
