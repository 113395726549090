import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import "../style/ProductStyle.scss";
import { Link } from "react-router-dom";
import LoadingComponent from "../../loading/components/LoadingComponent";
import CartInterector from "../../../../domain/pages/cart/CartInterector";
import CartService from "../../../../data/pages/cart/CartService";
import { connect } from "react-redux";
import FeedBackMsgComponents from "../../feedbackmsg/components/FeedBackMsgComponents";
import { GetVariationColorStyle } from "../../../../utils/productHelper.js";
import store from "../../../../data/store";
import { save } from "../../../../data/pages/products/showNotificationItemAddedActions";
import { isMobile } from "is-mobile";

class ProductComponent extends Component {
  myOwnList = [];
  listProduct = [];

  constructor(props) {
    super(props);

    this.state = {
      showFeedback: false,
      msg: false,
      selectedProduct: "",
      overlay: false,
      isProductInCart: false,
    };

    this.timer = null;
    this.closeFeedback = this.closeFeedback.bind(this);
  }

  addToCart(id, selectedProduct) {
    this.setState({
      overlay: true,
    });
    new CartInterector(
      new CartService()
        .addCart(id)
        .then((res) => {
          if (res === 409) {
            this.setState({
              isProductInCart: true,
              msg: false,
            });
            this.showFeedback();
          } else {
            this.setState({
              msg: true,
              selectedProduct: selectedProduct,
            });
            this.showFeedback();
          }
        })
        .catch((reason) => {
          console.log(reason, 123);
        })
    );
  }

  makeCreateMyOwnList(ev, idProduct) {
    if (!this.listProduct.includes(idProduct)) {
      this.listProduct.push(idProduct);
    }
    if (ev.target.checked) {
      this.myOwnList.push(parseInt(ev.target.value));
    } else {
      if (this.listProduct.includes(idProduct)) {
        this.listProduct = this.listProduct.filter(
          (id) => id !== parseInt(idProduct)
        );
      }
      this.myOwnList = this.myOwnList.filter(
        (id) => id !== parseInt(ev.target.value)
      );
    }
    this.props.makeCreateMyOwnList(this.myOwnList, this.listProduct);
  }
  showFeedback() {
    if (isMobile()) {
      this.setState({
        showFeedback: true,
      });
    } else if (this.state.isProductInCart) {
      store.dispatch(save('isProductInCart'));
    } else {
      store.dispatch(save(true));
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }
  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
      msg: false,
      overlay: false,
      isProductInCart: false,
    });
    store.dispatch(save(false));
  }

  render() {
    const { products } = this.props;

    return (
      <div className="products">
        {this.state.showFeedback && this.state.msg && (
          <FeedBackMsgComponents close={this.closeFeedback}>
            GREAT! THE ITEM{" "}
            <span className="product-name">
              {" "}
              {this.state.selectedProduct.name}{" "}
            </span>{" "}
            HAS BEEN ADDED TO YOUR CART. CLICK{" "}
            <span className="here">
              <Link
                to={{
                  pathname: "/cart",
                  state: { sellerId: this.state.selectedProduct.seller_id },
                }}
              >
                HERE
              </Link>
            </span>{" "}
            TO CHECK IT OUT.
          </FeedBackMsgComponents>
        )}

        {this.state.overlay && <div className="overlayProduct" />}

        {this.state.showFeedback && this.state.isProductInCart && (
          <FeedBackMsgComponents
            msg="THIS PRODUCT IS ALREADY IN YOUR CART."
            close={this.closeFeedback}
          />
        )}

        {this.props.loading ? (
          <LoadingComponent />
        ) : (
          <div className="containerProducts">
            {products.map((product, index) => {
              return (
                <label
                  className="blocksProducts"
                  key={index}
                  htmlFor={`checkBox_${index}`}
                >
                  <div className="checkBoxProduct">
                    {this.props.isCheckBox && (
                      <input
                        type="checkbox"
                        value={product.variations[0].id}
                        onChange={(ev) =>
                          this.makeCreateMyOwnList(ev, product.id)
                        }
                        id={`checkBox_${index}`}
                      />
                    )}
                  </div>
                  <div className="topBlockProducts">
                    {this.props.isCheckBox ? (
                      <>
                        {product.images.length > 0 && (
                          <div className="first-image-wrapper">
                            <img
                              className="first-image"
                              src={`${product.images[0].picture_thumbnail}`}
                              alt="Products"
                            />
                          </div>
                        )}
                        {product.images.length > 1 && (
                          <div className="hover-image-wrapper">
                            <img
                              className="hover-image"
                              src={`${product.images[1].picture_thumbnail}`}
                              alt="Products"
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <Link to={`/product/${product.id}`}>
                        <>
                          {product.images.length > 0 && (
                            <div className="first-image-wrapper">
                              <img
                                className="first-image"
                                src={`${product.images[0].picture_thumbnail}`}
                                alt="Products"
                              />
                            </div>
                          )}
                          {product.images.length > 1 && (
                            <div className="hover-image-wrapper">
                              <img
                                className="hover-image"
                                src={`${product.images[1].picture_thumbnail}`}
                                alt="Products"
                              />
                            </div>
                          )}
                        </>
                      </Link>
                    )}
                  </div>

                  <div className="mainProduct">
                    <div className="nameBrand">{product.seller}</div>

                    <p className="nameBrandCollection">{product.name}</p>

                    <ul className="variations">
                      {product.variations.map((variation, index) => {
                        const variationStyle =
                          GetVariationColorStyle(variation);
                        return (
                          <li key={index} style={variationStyle.li}>
                            <span
                              data-tip={variation.label}
                              data-for={variation.label}
                              className="colorListNoTexture"
                              style={variationStyle.span}
                            ></span>
                            <ReactTooltip
                              className="variation-tooltip"
                              id={variation.label}
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </li>
                        );
                      })}
                    </ul>

                    <div className="prices">
                      <p>
                        <span
                          className="bold"
                          data-tip={product.wholesale_price_tip}
                          data-for={`wholesale_price_${product.id}`}
                        >
                          W&nbsp;
                        </span>
                        <ReactTooltip
                          className="variation-tooltip"
                          id={`wholesale_price_${product.id}`}
                          place="top"
                          type="light"
                          effect="solid"
                        />
                        {" | "}{product.wholesale_price.currency}{" "}
                        {parseFloat(product.wholesale_price.amount).toFixed(2)}
                      </p>
                      <p>
                        {" "}
                        <span
                          className="bold"
                          data-tip={product.retailer_price_tip}
                          data-for={`retailer_price_${product.id}`}
                        >
                          R&nbsp;
                        </span>
                        <ReactTooltip
                          className="variation-tooltip"
                          id={`retailer_price_${product.id}`}
                          place="top"
                          type="light"
                          effect="solid"
                        />
                        {" | "}{product.retailer_price.currency}{" "}
                        {parseFloat(product.retailer_price.amount).toFixed(2)}
                      </p>
                    </div>

                    {window.localStorage.getItem("user_type") === "buyer" && (
                      <button
                        onClick={() => this.addToCart(product.id, product)}
                        className="buttonProduct add-to-cart-btn"
                      >
                        ADD TO CART
                      </button>
                    )}
                  </div>
                </label>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartData: state.cartEntity,
  };
}

export default connect(mapStateToProps)(ProductComponent);
