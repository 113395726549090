import React, { Component } from "react";
import "./style/HighlightD.scss";
// import {Parallax, Background} from 'react-parallax';
import { withTranslation } from "react-i18next";
import { isMobile } from "is-mobile";

class HighlightD extends Component {
  render() {
    return (
      <div>
        <section id="SecondHeader">
          <div
            className="texture2"
            style={{
              backgroundImage: `url(${
                isMobile() && this.props.data.image_mobile
                  ? this.props.data.image_mobile
                  : this.props.data.image
              })`,
            }}
          />

          <div className="logoAndText">
            <div>
              <img src={this.props.data.logo} alt="" />
              <p>{this.props.data.text}</p>
              <div className="buttons">
                <button
                  style={{ backgroundColor: this.props.data.btn_color }}
                  onClick={() => this.props.openRegister("", this.props.slug)}
                  className="link register"
                >
                  {this.props.t("Register")}
                </button>
                <button
                  style={{ color: this.props.data.btn_color }}
                  className="link login"
                  onClick={() => this.props.open(this.props.slug)}
                >
                  {" "}
                  {this.props.t("Login")}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(HighlightD);
