import React, { Component } from "react";
import "../../styles/LocationsComponent.scss";

class LocationsComponent extends Component {
  renderLocations() {
    if (this.props.locations.length <= 1) {
      return this.props.locations[0];
    }

    let locations = this.props.locations.join(", ");
    const lastIndexOfComma = locations.lastIndexOf(",");
    locations =
      locations.substring(0, lastIndexOfComma) +
      " and " +
      locations.substring(lastIndexOfComma + 1);
    return locations;
  }

  render() {
    return (
      <div className="LocationsComponent">
        <div className="title">LOCATION</div>
        <div className="locations-wrapper">{this.renderLocations()}</div>
      </div>
    );
  }
}

export default LocationsComponent;
