 import React, {Component} from 'react';
import Grid3Component from "../../../shared/grids/grid3/Grid3Component";
import Grid2Component from "../../../shared/grids/grid2/components/Grid2Component";
import BrandStyleComponent from "../../../brandstyle/components/BrandStyleComponent";
import SliderComponent from "../../../shared/Slider/components/SliderComponent";
import BoxComponent from "../../../shared/box/components/BoxComponent";
import "../../../views/styles/global.scss"
import Grid4Component from "../../../shared/grids/grid4/component/Grid4Component";
import BackToBrands from "../../../shared/backtobrands/components/BackToBrands";
import ReadMoreComponent from "../../../shared/readmore/components/ReadMoreComponent";
import LookBookLightBoxComponent from "../../../shared/lookbooklightbox/components/LookBookLightBoxComponent";
import TermsAndAboutRatingComponent from "../../../shared/termsandaboutrating/components/TermsAndAboutRatingComponent";
import isMobile from 'is-mobile';

class HistoryComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            key: "",
        }
    }


    openLightBox(key) {

        this.setState({
            open: true,
            key: key
        })
    }

    closedLightBox() {
        this.setState({
            open: false
        })
    }
    getSplitedByFive(items){
      let items_by_5 = []
      let i, j, temparray;
      for (i=0, j=items.length; i<j; i+=5) {
          temparray = items.slice(i, i+5);
          items_by_5.push(temparray)
      }
      return items_by_5
    }

    render() {

        return (
            <div>
                <section className="centerComponent contentGrid3">
                    <Grid3Component image={this.props.history.history_image}
                                    details={this.props.history.details}
                                    instagram={this.props.history.instagram_url}
                                    facebook={this.props.history.facebook_url}
                                    site={this.props.history.site_url}
                    />
                </section>
                {this.props.history.info_block.map( (block, index) => {

                    return (
                        block.image_position === "right" ? (
                            <section className="centerComponent margin shadowMob" key={index}>

                                <Grid2Component info={false}
                                                title={block.title} link="/"
                                                images={block.images}
                                                text={block.summary}
                                                label={block.button_label}
                                                linkTo={block.link}
                                                fullText={block.text}
                                                imageOrientation={block.image_orientation}
                                />

                            </section>
                        ) : (
                            <section className="centerComponent mgBottom zero lastGrid" key={index}>

                                <Grid4Component info={false}
                                                title={block.title} link="/"
                                                images={block.images}
                                                text={block.summary}
                                                label={block.button_label}
                                                linkTo={block.link}
                                                fullText={block.text}
                                                imageOrientation={block.image_orientation}
                                />

                            </section>
                        )
                    )
                })}



                <section id="brandStyleFilters" className="centerComponent">
                    <div className="containerBrandFilter">
                        <div className="left">
                            <BrandStyleComponent>
                                <h2>Lookbooks</h2>

                                <p>
                                    {this.props.history.lookbook_description}
                                </p>
                            </BrandStyleComponent>
                        </div>
                    </div>
                </section>

                <section id="slider">
                    <div className="centerComponent slider-history">
                        <SliderComponent
                            dots={false}
                            infinite={false}
                            speed="500"
                            arrow={true}
                            slidetoshow={ isMobile() ? 2 : 4 }
                            slideScroll={ isMobile() ? 2 : 4 }
                            slidesPerRow={2}
                            templateType={this.props.history.lookbook_template}
                        >

                            { !isMobile() && this.props.history.lookbook_template === 2 ? (
                                this.getSplitedByFive(this.props.products[0]).map((itens, i) => {
                                  let boxes = []
                                  let firstItem = itens.shift();
                                  boxes.push(
                                    <div className="box-big">
                                    <BoxComponent
                                        type="button"
                                        buttonLabel={firstItem.button_label}
                                        link={firstItem.link}
                                        openLightbox={this.openLightBox.bind(this)}
                                        title={firstItem.title}
                                        image={firstItem.image_580_600}
                                        key={i}
                                        index={i}
                                     />
                                    </div>
                                  )

                                  let boxes_2 = itens.map((item, i) =>{
                                    return (
                                      <BoxComponent
                                          type="button"
                                          buttonLabel={item.button_label}
                                          link={item.link}
                                          newClass={"box-small "}
                                          openLightbox={this.openLightBox.bind(this)}
                                          title={item.title}
                                          image={item.image_285}
                                          key={i}
                                          index={i}
                                       />
                                    )
                                  })
                                  boxes.push(<div className="boxes-small">{ boxes_2 }</div>)
                                  return boxes
                                })
                              ) : (
                                this.props.products[0].map((item, i) => {
                                    return (
                                      <BoxComponent
                                          type="button"
                                          buttonLabel={item.button_label}
                                          link={item.link}
                                          newClass={"box-small "}
                                          openLightbox={this.openLightBox.bind(this)}
                                          title={item.title}
                                          image={item.image_285}
                                          key={i}
                                          index={i}
                                       />
                                    );
                                })
                              )
                            }
                        </SliderComponent>

                        <div className="spaceElement">&nbsp;</div>

                    </div>
                </section>
                            
                <section className="centerComponent">
                    <TermsAndAboutRatingComponent
                    aboutRatingText={this.props.aboutRating}
                    termsAndConditionsText={this.props.termsConditions}
                    />
                </section>
                {this.state.open &&
                    <LookBookLightBoxComponent handleClick={this.closedLightBox.bind(this)}>
                        <SliderComponent
                            dots={false}
                            infinite={false}
                            speed="500"
                            slidetoshow={1}
                            slideScroll={1}
                            slidesPerRow={1}
                            slidesToShow={1}
                            initialSlide={this.state.key}
                        >
                            {
                                this.props.history.lookbook_images.map((image, index) => {
                                    return (
                                        <div className="contentBox" key={index}>
                                            <div className="boxLookBook">
                                                <img src={image.image_580_600} alt={image.name} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </SliderComponent>
                    </LookBookLightBoxComponent>
                }

                <section id="readMore">
                    <div className="centerComponent">
                        <BackToBrands />
                        <ReadMoreComponent tags={this.props.tags} />
                    </div>
                </section>
            </div>
        );
    }
}

export default HistoryComponent;
