import React from 'react';
import LightboxHalfScreenComponent from "../../lightboxHalfScreen/components/LightboxHalfScreenComponent";
import { withTranslation } from 'react-i18next';

function TermsOfUser(props) {


  return (
    <div className="termsOfUse">
      <LightboxHalfScreenComponent
      close={props.close}>

        <h1>{props.t('TERMS OF USE')}</h1>
        <div dangerouslySetInnerHTML={{__html: props.text}} />
      </LightboxHalfScreenComponent>
    </div>
  )
};

export default withTranslation()(TermsOfUser);