import React, { Component } from "react";

import MenuBrandsComponent from "../../../menubrands/components/MenuBrandsComponent";
import HistoryComponent from "../../../thebrand/history/components/HistoryComponent";
import TheBrandComponent from "../../../thebrand/brand/components/TheBrandComponent";

import "../../styles/global.scss";
import "../../../shared/style/home/HomeStyle.scss";
import MenuComponent from "../../../menu/components/MenuComponent";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import SelectCollectionComponent from "../../../thebrand/collection/components/SelectCollectionComponent";
import CollectionViewComponent from "../../../thebrand/collection/components/CollectionViewComponent";
import CallToAction from "../../../shared/calltoaction/components/CallToAction";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import axios from "axios";

import { Route } from "react-router-dom";
import Breadcomb from "../../../menu/components/Breadcomb";

const CollectionViewRender = ({ match, brandData }) => {
  return (
    <CollectionViewComponent
      sellerSlug={match.params.slug}
      collectionId={match.params.collectionId}
      brandData={brandData}
    />
  );
};

class BrandPageViewComponent extends Component {
  state = {
    loading: true,
  };

  readMore(isReadMore) {
    this.setState({
      readMore: isReadMore,
    });
  }

  getBrandData() {
    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/sellers/${this.props.match.params.slug}/brand-page`,
        this.headers
      )
      .then((response) => {
        this.setState({
          brandData: response.data,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.getBrandData();
  }

  render() {
    const brandDataState = this.state.brandData;
    const sellerSlug = this.props.match.params.slug;
    let categoriesFilter = window.localStorage.getItem('brands_last_filter_category_url');
  
    let brandCategory = false;
    let brandCategoryFilter = false;

    if (window.localStorage.getItem('brands_last_filter_name')) {
      brandCategory = {
        text: window.localStorage.getItem('brands_last_filter_name'),
        link: true,
        to: '/' + window.localStorage.getItem('brands_last_filter_pathname')
      };
    }

    if (categoriesFilter) {
      brandCategoryFilter = {
        text: window.localStorage.getItem('brands_last_filter_category_name'),
        link: true,
        to: '/' + window.localStorage.getItem('brands_last_filter_pathname') + categoriesFilter
      }
    }

    return (
      <div>
        <div className="header-menu-wrapper">
          <MenuComponent />
          <Breadcomb
            links={[
              {
                text: "Our Brands",
                link: true,
                to: "/brands",
              },
              brandCategory,
              brandCategoryFilter,
              {
                text: this.state.brandData && this.state.brandData.name,
                link: false,
              },
            ]}
          />
        </div>
        {this.state.loading && !this.state.brandData ? (
          <LoadingComponent />
        ) : (
          <div>
            <div className="theBrandHeaderWrapper">
              <div
                className="theBrandHeader"
                style={{
                  backgroundImage: `url(${this.state.brandData.cover_image_thumbnail})`,
                }}
              >
                <span className="brand-title">
                  {this.state.brandData.name}
                </span>
              </div>
            </div>
            <main id="main">
              <section className="centerComponent">
                <MenuBrandsComponent brandSlug={sellerSlug} />
              </section>
              <section className="routerBrands">
                <div>
                  <Route
                    exact
                    path={`/${sellerSlug}`}
                    render={() => (
                      <TheBrandComponent
                        tags={this.state.brandData.tags}
                        info={true}
                        brandStyleImages={
                          this.state.brandData.the_brand.brand_style_images
                        }
                        theBrand={this.state.brandData.the_brand}
                        isReadMore={this.readMore.bind(this)}
                        readMore={this.state.readMore}
                        seller={sellerSlug}
                        brandData={this.state.brandData}
                      />
                    )}
                  />

                  <Route
                    exact
                    path={`/${sellerSlug}/collections`}
                    render={() => (
                      <SelectCollectionComponent seller={sellerSlug} />
                    )}
                  />
                  <Route
                    exact
                    path={`/:slug/collections/:collectionId`}
                    sellerSlug={sellerSlug}
                    render={(location) => {
                      return (
                        <CollectionViewRender
                          match={location.match}
                          brandData={brandDataState}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path={`/${sellerSlug}/history`}
                    render={() => (
                      <HistoryComponent
                        products={[
                          this.state.brandData.history.lookbook_images,
                        ]}
                        history={this.state.brandData.history}
                        tags={this.state.brandData.tags}
                        termsConditions={this.state.brandData.the_brand.terms_conditions}
                        aboutRating={this.state.brandData.the_brand.about_rating}
                      />
                    )}
                  />
                </div>
              </section>

              {this.state.brandData.calls_to_action[0] && (
                <section>
                  <CallToAction
                    callToAction={this.state.brandData.calls_to_action[0]}
                  />
                </section>
              )}
              <FooterComponent />
            </main>
          </div>
        )}
      </div>
    );
  }
}

export default BrandPageViewComponent;
