import React, {Component} from 'react';
import './style/HighlightF.scss';
import { withTranslation } from 'react-i18next';

class HighlightF extends Component {

    componentDidMount() {
      
    }

    render() {
        return (
            <section id="HeaderHighLightF">
                <div className="logoAndText">
                    <h1>{this.props.data.title || 'THE DIGITAL PLATFORM BUILT FOR THE FUTURE OF FASHION'}</h1>
                    <p>{this.props.data.text}</p>
                    <div className="buttons">
                        <button onClick={() => this.props.openRegister('', this.props.slug)} className="link register">{this.props.t('Register')}
                        </button>
                        <button className="link login" onClick={() => this.props.open(this.props.slug)}> {this.props.t('Login')}</button>
                    </div>
                </div>
                <div className="headerImages" style={{backgroundImage: `url(${this.props.data.image})`}}></div>

            </section>
        );
    }
}

export default withTranslation()(HighlightF);
