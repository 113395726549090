import React, { Component } from "react";
import ButtonComponent from "../../../shared/button/components/ButtonComponent";
import MessageBlockComponent from "./MessageBlockComponent";
import "../../styles/MessagesView/MessagesSidebarComponent.scss";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import axios from "axios";
import SpinnerComponent from "../../../shared/spinner";

class MessagesSidebarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      page: 1,
      messages: [],
      metaMessages: null,
      currentFilter: null,
    };
    this.messagesContainer = React.createRef();
  }

  componentDidMount() {
    this.fetchLastMessages();
  }

  componentDidUpdate() {
    if (this.state.currentFilter !== this.props.filter) {
      this.fetchLastMessages();
    }
  }

  fetchLastMessages(page = 1) {
    const { filter } = this.props;

    this.setState({
      loading: true,
      currentFilter: filter,
    });

    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/inbox-messages?filter=${filter}&page=${page}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            messages: response.data.data,
            metaMessages: response.data.meta,
            loading: false,
          });
        }
      });
  }

  fetchLastMessagesMore(page = 1) {
    const { filter } = this.props;
    this.setState({
      loadingMore: true,
    });

    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/inbox-messages?filter=${filter}&page=${page}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState(
            (prevState) => {
              const { messages } = prevState;
              messages.push(...response.data.data);
              return {
                ...prevState,
                messages: messages,
                metaMessages: response.data.meta,
              };
            },
            () => {
              this.setState({
                loadingMore: false,
              });
              this.moveScroll();
            }
          );
        }
      });
  }

  moveScroll() {
    const node = this.messagesContainer.current;
    if (node) {
      node.scroll({
        top: node.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  handleButtonClick(all = true) {
    if (all === this.state.all) {
      return;
    }

    this.setState(
      {
        all: all,
        page: 1,
      },
      () => {
        this.fetchLastMessages();
      }
    );
  }

  handleSeeMore() {
    if (!this.state.metaMessages) {
      return;
    }

    const nextPage = this.state.page + 1;

    if (nextPage > this.state.metaMessages.last_page) {
      return;
    }

    this.fetchLastMessagesMore(nextPage);

    this.setState({
      page: nextPage,
    });
  }

  renderMessages() {
    return this.state.messages.map((message) => (
      <MessageBlockComponent message={message} />
    ));
  }

  render() {
    return (
      <div id="messages-sidebar">
        <div id="messages-container" ref={this.messagesContainer}>
          {this.state.loading ? <LoadingComponent /> : this.renderMessages()}
        </div>
        {this.state.metaMessages &&
          this.state.metaMessages.last_page > this.state.page &&
          !this.state.loadingMore && (
            <div id="see-more">
              <ButtonComponent action={() => this.handleSeeMore()}>
                {this.state.loadingMore ? <SpinnerComponent /> : "LOAD MORE"}
              </ButtonComponent>
            </div>
          )}
        {this.state.loadingMore && (
          <div id="see-more">
            <ButtonComponent>
              <SpinnerComponent />
            </ButtonComponent>
          </div>
        )}
      </div>
    );
  }
}

export default MessagesSidebarComponent;
