import React from "react";
import "../style/BrandBoxComponentStyle.scss";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

const BrandBoxComponent = (props) => {
  const { brand } = props;
  const logo = brand.list_image_thumbnail || brand.list_image;

  return (
    <div className="brand-box brand-box-component">
      <div
        className="containerImage"
        key={brand.id}
        style={{
          backgroundImage: logo
            ? `url(${logo})`
            : "none",
        }}
        onClick={() => props.history.push(`/${brand.slug}`)}
      >
        <div
          className={`blockBuyer ${brand.logo ? "blockBuyer--bg" : ""}`}
        >
          <Link to={`/${brand.slug}`}>{brand.name}</Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BrandBoxComponent);
