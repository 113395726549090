import React from 'react';
import CollectionComponent from "./CollectionComponent";
import BaseComponent from "../../../shared/BaseComponent";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";

class CollectionViewComponent extends BaseComponent {
    headers = {
        headers: {Authorization: `Bearer ${window.localStorage.getItem('access_token')}`},
    };

    url = `${process.env.REACT_APP_API_URL}/api/products/`;

    endPoint = `?sellers=${this.props.sellerSlug}&collections=${this.props.collectionId}&`;

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            render: false,
            loading: true,
            loadingProduct: true,
            param: ""
        };
    }

    setCurrentBrand(key) {
        this.setState({
            stateBrand: key
        });
    }

    componentDidMount() {
        this.getProducts(this.endPoint);
    }

    render() {
        if(this.state.loading) {
            return (<LoadingComponent />)
        }

        return (
            <div>
                <CollectionComponent
                    loading={this.state.loadingProduct}
                    results={this.state.products}
                    isCollection={true}
                    fullCollection={this.props.fullCollection}
                    isBrand={true}
                    isBrandPage={true}
                    isSegment={true}
                    isMenuCollection={true}
                    getFilters={this.getFilters.bind(this)}
                    endPoint={this.endPoint}
                    setFilters={this.setFilters.bind(this)}
                    getProducts={this.getProducts.bind(this)}
                    handleClick={this.clearAllFilter.bind(this)}
                    clearAllFilter={this.clearAllFilter.bind(this)}
                    brandData={this.props.brandData}
                />
            </div>
        );
    }
}

export default CollectionViewComponent;
