import React, { Component } from "react";
import axios from "axios";
import "./productSizeChart.scss";
import SpinnerComponent from "../spinner";
import ButtonComponent from "../button/components/ButtonComponent";
import LightBoxFullScreenComponent from "../lightboxFullScreen/components/LightBoxFullScreenComponent";
import SizeChartComponent from "../sizechart/components/SizeChartComponent";

class ProductSizeChart extends Component {
  constructor(props) {
    super(props);
    let unitMultiplier = 1;
    let isLoading = props.sizeChart.image_thumbnail ? false : true;

    this.state = {
      sizeChartValues: [],
      sizes: [],
      measurements: [],
      unitMultiplier: unitMultiplier,
      activeUnit: "cm",
      isLoading: isLoading,
    };
    if (!props.sizeChart.image_thumbnail) {
      this.getSizeChart();
    }
  }

  getSizeChart() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/product/${this.props.productId}/sizechart/`,
        this.headers
      )
      .then((response) => {
        this.setState({
          isLoading: false,
          sizeChartValues: response.data.values,
          sizes: response.data.meta.sizes,
          measurements: response.data.meta.measurements,
        });
      });
  }
  getValue(measurement, size) {
    return (
      this.state.sizeChartValues[measurement.id][size.id] /
      this.state.unitMultiplier
    ).toFixed(2);
  }

  render() {
    const { show, sizeChart, productName, productReferenceCode } = this.props;

    return (
      <>
        {show && (
          <LightBoxFullScreenComponent
            show={show}
            handleClick={this.props.handleClick}
            title={productName}
            subTitle={productReferenceCode}
          >
            {!sizeChart.image_thumbnail && (
              <div className="__unit">
                <span
                  className={this.state.activeUnit === "cm" ? "active" : ""}
                  onClick={() =>
                    this.setState({ unitMultiplier: 1, activeUnit: "cm" })
                  }
                >
                  cm
                </span>
                /
                <span
                  className={this.state.activeUnit === "inches" ? "active" : ""}
                  onClick={() =>
                    this.setState({
                      unitMultiplier: 2.54,
                      activeUnit: "inches",
                    })
                  }
                >
                  inches
                </span>
              </div>
            )}

            <div className="containerImageLightBox">
              <div className="imageLightBox">
                {sizeChart.image_thumbnail ? (
                  <div className="view-size-chart-image">
                    <img src={sizeChart.image_thumbnail} alt="" />
                    {this.props.addToCart && (
                      <ButtonComponent onAction={() => this.props.addToCart()}>
                        Perfect fit? Add to cart
                      </ButtonComponent>
                    )}
                  </div>
                ) : (
                  <div>
                    {this.state.isLoading ? (
                      <SpinnerComponent />
                    ) : (
                      <div className="sizeChart-table-wrapper">
                        <table className="sizeChart-table">
                          <thead>
                            <tr className="__sizes">
                              <td>Size chart</td>
                              {this.state.sizes.map((size) => (
                                <td>{size.name}</td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.measurements.map((measurement) => (
                              <tr>
                                <td className="__measurements">
                                  {measurement.name}
                                </td>
                                {this.state.sizes.map((size) => (
                                  <td className="__values">
                                    {this.getValue(measurement, size)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {this.props.addToCart && (
                          <ButtonComponent
                            action={() => this.props.addToCart()}
                            className="btn-perfect-fit"
                          >
                            Perfect fit? Add to cart
                          </ButtonComponent>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <SizeChartComponent />
            </div>
          </LightBoxFullScreenComponent>
        )}
      </>
    );
  }
}

export default ProductSizeChart;
