import React, {Component} from 'react';
import "../style/SortByFilterStyle.scss"

class SortByFilter extends Component {

    handleChange(ev) {
        this.props.setFilters("ordering", ev.target.value);
    }

    render() {
        return (
            <div className="SortByFilter">
                <div className="titleSortBy">
                    sort by:
                </div>

                <select name="selectFilter" className="selectFilter" onChange={(ev) => this.handleChange(ev)}>
                    <option value=""> Select ordering</option>
                    <option value="collection_order">Relevance</option>
                    <option value="-created">Newest</option>
                    <option value="wholesale_price">Wholesale Price: Low to High</option>
                    <option value="-wholesale_price">Wholesale Price: High to Low</option>
                    <option value="retailer_price">Retail Price: Low to High</option>
                    <option value="-retailer_price">Retail Price: High to Low</option>
                    <option value="order_delivery_days">Shipping days: Low to High</option>
                    <option value="-order_delivery_days">Shipping days: High to Low</option>
                </select>
            </div>
        );
    }
}

export default SortByFilter;