import React, { Component } from "react";
import "../styles/BrandsViewFilter.scss";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import SegmentService from "../../../data/pages/segments/SegmentService";

class BrandsViewFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentsOpened: false,
      segments: [],
      events: [],
    };
  }

  async componentDidMount() {
    let segmentService = new SegmentService();
    let segments = await segmentService.get();
    let events = await segmentService.getEvents();

    events.results = events.results.filter((item) => item.is_active);

    this.setState({
      segments: segments.results,
      events: events.results,
      loading: false,
    });
  }

  handleClickAZ() {
    this.removeFiltersBrandPage();
    this.props.history.push("/brands");
  }

  removeFiltersBrandPage() {
    window.localStorage.removeItem('brands_last_filter_name');
    window.localStorage.removeItem('brands_last_filter_pathname');
    window.localStorage.removeItem('brands_last_filter_category_name');
    window.localStorage.removeItem('brands_last_filter_category_url');
  }

  handleClickAll() {
    const pending = 0;
    this.props.onChange(pending);
  }

  openSegments() {
    this.setState({ segmentsOpened: !this.state.segmentsOpened });
  }

  openProjects() {
    this.setState({ projectsOpened: !this.state.projectsOpened });
  }

  handleClick(item) {
    window.localStorage.setItem('brands_last_filter_name', item.name);
    window.localStorage.setItem('brands_last_filter_pathname', item.slug);
    this.props.history.push(`/${item.slug}`)
  }

  render() {
    const { pending } = this.props;
    return (
      <div className="brands-view-filter">
        <ButtonComponent
          action={() => this.handleClickAZ()}
          className={pending === 1 ? "button--active" : ""}
        >
          A-Z
        </ButtonComponent>
        {this.state.segments
          .filter((item) => !item.event)
          .map((item, index) => {
            return (
              <ButtonComponent
                key={item.id}
                action={() => this.handleClick(item)}
                className={pending === 1 ? "button--active" : ""}
              >
                {item.name}
              </ButtonComponent>
            );
          })}
        {this.state.events.length > 0 &&
          this.state.events
            .sort((item, itemB) => item.order - itemB.order)
            .map((item, index) => {
              return (
                this.state.segments.filter((itemSegment) => {
                  if (itemSegment.event && itemSegment.is_active_event) {
                    return item.id === itemSegment.event;
                  }
                  return false;
                }).length > 0 && (
                  <div className="button dropdown-link" key={index}>
                    {item.name}
                    <span
                      onClick={() => this.openProjects()}
                      id={`dropdown-wrapper-${item.name}`}
                    >
                      <div
                        className={`dropdown-wrapper ${
                          this.state.projectsOpened ? "openedDropdown" : ""
                        }`}
                      >
                        <div className="dropdown">
                          <div className="separator"></div>
                          <ul className="dropdownSettings-menu">
                            {this.state.segments
                              .filter((itemSegment) => {
                                if (
                                  itemSegment.event &&
                                  itemSegment.is_active_event
                                ) {
                                  return item.id === itemSegment.event;
                                }
                                return false;
                              })
                              .map((item, index) => {
                                return this.props.handleClick === undefined ? (
                                  <li key={index} className="dropdown-subitem">
                                    <Link to={`/${item.slug}`}>
                                      {item.name}{" "}
                                    </Link>
                                  </li>
                                ) : (
                                  <li
                                    onClick={(ev) =>
                                      this.handleClick(`/${item.slug}`, ev)
                                    }
                                    className="dropdown-subitem"
                                    key={index}
                                  >
                                    <Link to={`/${item.slug}`}>
                                      {item.name}{" "}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </span>
                  </div>
                )
              );
            })}
      </div>
    );
  }
}

export default withRouter(BrandsViewFilter);
