import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

function Analitycs(props) {
    
    useEffect(()=>{
        ReactGA.initialize('UA-135879016-1');
    }, [])

    useEffect(()=>{
        // console.log('ga:', props.location.pathname+props.location.search)
        if(window.location.origin !== 'https://blancfashion.com') return;
        ReactGA.pageview(props.location.pathname+props.location.search);
    }, [props.location])

  return (
    <div />
  );
}

export default withRouter(Analitycs);
