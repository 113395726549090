import React, {Component} from 'react';
import "./style/HighlightC.scss";
// import {Parallax, Background} from 'react-parallax';
import { withTranslation } from 'react-i18next';


class HighlightC extends Component {

    render() {
        return (

            <div>
                <section id="SecondHeader">
                    <div className="texture2"
                         style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bg-texture-02.png)`}}/>

                    <div className="logoAndText">
                        <div>
                            <img src={this.props.data.logo} alt=""/>
                            <p>{this.props.data.text}</p>
                            <div className="buttons">
                                <button style={{backgroundColor: this.props.data.btn_color}} onClick={() => this.props.openRegister('', this.props.slug)}
                                        className="link register">{this.props.t('Register')}
                                </button>
                                <button style={{color: this.props.data.btn_color}} className="link login" onClick={() => this.props.open(this.props.slug)}> {this.props.t('Login')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="headerImages">
                        <div className="image">
                            <img src={this.props.data.image} alt=""/>
                        </div>                        
                    </div>

                </section>


            </div>
        );
    }
}

export default withTranslation()(HighlightC);
