import React from "react";
import "../style/brand.scss";
import Button from "../../button/components/ButtonComponent";
import isMobile from "is-mobile";

const Brand = (props) => {
  const openModalMobile = () => {
    if (isMobile()) {
      props.openModal();
    }
  };

  const renderBrandTagEvents = () => {
    if (props.eventSegment) {
      const currentPageCustomEventBrandPage =
        props.eventSegment.image_tag_event;

      if (currentPageCustomEventBrandPage) {
        return false;
      }
    }

    const brandEventos = props.brand.event_segments;

    const imageTags = [];

    for (let index = 0; index < brandEventos.length; index++) {
      const brandEvento = brandEventos[index];
      const hasEventAndImageTag =
        brandEvento.image_tag_event && brandEvento.event && brandEvento.is_active_event;

      if (hasEventAndImageTag) {
        imageTags.push(
          <img
            className={`brand-image-tag`}
            src={brandEvento.image_tag_event}
            alt="Tag Event"
          />
        );
      }
    }

    return imageTags;
  };

  return (
    <div className="brand swiper-slide" onClick={openModalMobile.bind(this)}>
      <div className="brand-image-wrapper">
        <div className="brand-tag-container">
          {renderBrandTagEvents()}
        </div>
        <img src={props.brand.list_image_thumbnail} alt={props.brand.name} />
        <div className="brand-footer">
          <h2>{props.brand.name}</h2>
          <div className="text-hover">{props.brand.landing_description}</div>
          <div className="brand-tags">
            {props.brand.tags.map((tag) => (
              <span key={tag.name}>#{tag.name}</span>
            ))}
          </div>
          <Button onClick={props.openModal} text="SEE MORE" />
        </div>
      </div>
    </div>
  );
};
export default Brand;
