import React, { useEffect } from "react";
import "../style/BrandBoxStyle.scss";
import mixpanel from "mixpanel-browser";
import BrandBoxComponent from "./BrandBoxComponent";

const BrandsBoxComponent = (props) => {
  const { brands, brandsSmall } = props;

  useEffect(() => {
    mixpanel.identify(
      `${JSON.parse(window.localStorage.getItem("userData")).id}`
    );
    mixpanel.track("Seller List");
  }, []);

  return (
    <div className={`boxBrands ${brandsSmall ? "boxBrands--small" : ""}`}>
      {brands.map((item, index) => {
        return <BrandBoxComponent key={item.id} brand={item} />;
      })}
    </div>
  );
};

export default BrandsBoxComponent;
