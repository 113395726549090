import { save } from "./CartActions";
import store from "../../store"
import CartEntity from "../../../domain/pages/cart/CartEntity";
import axios from "axios";
import OrderInterector from "../../../domain/pages/order/OrderInterector";
import OrderService from "../order/OrderService";
import mixpanel from "mixpanel-browser";

export default class CartService {

    headers = { Authorization: `Bearer ${window.localStorage.getItem("access_token")}` };
    HEADERS = { headers: this.headers };

    dispatch = store.dispatch;

    async addCart(id) {
        try {
            const res = await axios.post("api/cart/add/", {id});

            this.getData();

            const produto = res.data;

            mixpanel.identify(`${JSON.parse(window.localStorage.getItem('userData')).id}`)
            mixpanel.track("Add to Cart", {
                'seller': produto.collection.seller.name,
                'seller_id': produto.collection.seller.id,
                'collection': produto.collection.name,
                'collection_id': produto.collection.id,
                'product_name': produto.name,
                'product_id': produto.id,
                'category_id': produto.categories.map(item => item.id).join(','),
                'category': produto.categories.map(item => item.name).join(','),
                'tags': produto.tags.map(item => item.name).join(','),
                'tags_id': produto.tags.map(item => item.id).join(',')
            });

            return res;
        } catch (e) {
            return e.response.status;
        }
    }

    async removeProductsAll(cartId){
        try {
            const res = await
            axios.delete(`api/cart/${cartId}/`);
            this.getData();
            return res;
        } catch (e) {
            console.log(e)
        }
    }

    async removeCart(cartId, productId) {
        try {
            const res = await
            axios.delete(`api/cart/item/?cart_id=${cartId}&product_id=${productId}`)
            this.getData();
            return res;
        } catch (e) {
            console.log(e)
        }

    }

    async getData() {
        if(localStorage.getItem("user") !== null && localStorage.getItem("user_type") === 'buyer') {
            let obj = [];
            try {
                let result = await fetch(`${process.env.REACT_APP_API_URL}/api/cart/`, this.HEADERS);
                let response = await result.json();
                obj.push(response);
                this.entityMount(obj)

            } catch (err) {
                window.console.log(`[Erro] ${err}`);
                return Promise.reject(400);
            }
        }
    }

    async finalizeOrder(id, message) {
        try {
            const res = await
            axios.post(`api/cart/${id}/finalize/`, { message });
            new OrderInterector(new OrderService().getData())
            return res;
        } catch (e) {
            return e.response;
        }
    }

    entityMount(response) {
        let entityCart = [];

        response.forEach( item => {
            entityCart.push(new CartEntity(item));
        });

        this.dispatchSave(entityCart)

    }

    dispatchSave(entityList) {
       this.dispatch(save(entityList));
    }
}
