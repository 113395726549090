import React, {Component} from 'react';
import './style/HighlightG.scss';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'is-mobile';

class HighlightG extends Component {
    render() {
        return (
            <section id="HeaderHighLightF">
                <div
                    className="texture2"
                    style={{
                        backgroundImage: `url(${
                        isMobile() && this.props.data.image_mobile
                            ? this.props.data.image_mobile
                            : this.props.data.image
                        })`,
                    }}
                    />
                <div className="logoAndText">
                    <h1>{this.props.data.title || 'THE DIGITAL PLATFORM BUILT FOR THE FUTURE OF FASHION'}</h1>
                    <p>{this.props.data.text}</p>
                    <div className="buttons">
                        <button onClick={() => this.props.openRegister('', this.props.slug)} className="link register">{this.props.t('Register')}
                        </button>
                        <button className="link login" onClick={() => this.props.open(this.props.slug)}> {this.props.t('Login')}</button>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(HighlightG);
