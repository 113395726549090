import { save, saveLocations } from "./buyersActions";
import store from "../../store";
import axios from "axios";

export default class BuyersService {
  headers = {
    Authorization: `Bearer ${window.localStorage.getItem("new_access_token")}`,
  };

  dispatch = store.dispatch;

  async getData(page = 1, locations = [], letter = '') {
    try {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set('page', page);
      urlSearchParams.set('locations', locations);

      if (letter) {
        urlSearchParams.set('letter', letter);
      } else {
        urlSearchParams.delete('letter');
      }

      let response = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/buyers?${urlSearchParams.toString()}`
      );

      if (response.status === 200) {
        this.dispatch(save(response.data));
      }
    } catch (err) {
      if (err.status === 401) {
        localStorage.clear();
      }

      window.console.log(`[Erro] ${err}`);
      return Promise.reject(400);
    }
  }

  async getLocations() {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/locations`
      );

      if (response.status === 200) {
        this.dispatch(saveLocations(response.data));
      }
    } catch (err) {
      if (err.status === 401) {
        localStorage.clear();
      }

      window.console.log(`[Erro] ${err}`);
      return Promise.reject(400);
    }
  }
}
