import React from 'react';
import SelectCollectionBox from "./SelectCollectionBox";


const SelectCollectionComponent = ({ seller }) => (
  <div>
      <SelectCollectionBox
          seller={seller}
      />
  </div>
);


export default SelectCollectionComponent;
