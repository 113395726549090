import Axios from "axios";

export default class OrderService {
    getOrders(page = 1) {
        return Axios.get(`/api/order/?page=${page}`);
    }

    sendMessage(id, message) {
        return Axios.post(`api/order/${id}/send_message/`, { message });
    }
    getPastOrder(page = 1) {
        return Axios.get(`api/order/past/?page=${page}`);
    }
}
