import React, { Component } from "react";
import "./style/searchStyle.scss";
import axios from "axios";
import FeedBackMsgComponents from "../../feedbackmsg/components/FeedBackMsgComponents";

class SearchComponent extends Component {
  headers = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
    },
  };

  url = `${process.env.REACT_APP_API_URL}/api/products/?search=`;

  constructor(props) {
    super(props);

    this.state = {
      valueSearch: "",
      searchNotFound: false,
      showFeedback: false,
    };
    this.timer = null;
    this.closeFeedback = this.closeFeedback.bind(this);
  }

  handleChange(ev) {
    this.setState({
      valueSearch: ev.target.value,
    });
  }
  showFeedback() {
    this.setState({
      showFeedback: true,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }
  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
      searchNotFound: false,
    });
  }

  search(event) {
    event.preventDefault();
    axios
      .get(this.url + this.state.valueSearch, this.headers)
      .then((response) => {
        if (this.state.valueSearch !== "") {
          if (response.data.results.length > 0) {
            if (response.data.search_data.seller_slug) {
              window.location.href =
                "/" + response.data.search_data.seller_slug;
            } else if (response.data.search_data.seller_slug) {
              window.location.href =
                "/products/?category=" + response.data.search_data.category_id;
            } else {
              window.location.href =
                "/products/?search=" + this.state.valueSearch;
            }
          } else {
            this.setState({
              searchNotFound: true,
            });
            this.showFeedback();
          }
        }

        this.setState({
          products: response.data,
          loading: false,
          loadingProduct: false,
        });
      });
  }

  render() {
    return (
      <div className="search">
        {this.showFeedback && this.state.searchNotFound && (
          <FeedBackMsgComponents
            msg="OOPS, NO ITEMS TO SHOW!"
            close={this.closeFeedback}
          />
        )}

        <form onSubmit={(event) => this.search(event)}>
          <input
            type="text"
            className="inputSearch"
            placeholder="search"
            value={this.state.valueSearch}
            onChange={(ev) => this.handleChange(ev)}
          />

          <input
            type="image"
            src={`${process.env.PUBLIC_URL}/images/menu/lupa.svg`}
            className="icoSearch"
            alt="submit"
          />
        </form>
      </div>
    );
  }
}

export default SearchComponent;
