import { save } from "./InfoProductActions";
import store from "../../store"
import InfoProductEntity from "../../../domain/pages/infoproduct/infoProductEntity";
import axios from "axios";

export default class InfoProductService {

    headers = { 
        'Authorization': `Bearer ${window.localStorage.getItem("access_token")}` ,
        'Accept-Language' :'en',
        'Content-Language' :'en'    
    };
    HEADERS = { headers: this.headers };

    dispatch = store.dispatch;

    async getData(id) {

        let obj = [];
        try {
            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/product/${id}/`, this.HEADERS);
            let response = await result.json();
            obj.push(response);
            this.entityMount(obj);
            return result
        } catch (err) {
            window.console.log(`[Erro] ${err}`);
            return Promise.reject(400);
        }
    }

    async getInfoProduct(id) {
        try {
            const res = await
            axios.get(`/api/product/${id}/`);
            this.getData(id);
            return res;
        } catch (e) {
            console.log(e)
        }
    }

    entityMount(response) {
        let entityInfoProduct = [];

        response.forEach( item => {
            entityInfoProduct.push(new InfoProductEntity(item));
        });

        this.dispatchSave(entityInfoProduct)

    }

    dispatchSave(entityList) {
       this.dispatch(save(entityList));
    }
}
